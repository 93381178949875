import { Section } from "../section";
import css from "../css/education.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Education");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}Education</h2>
      <p>Lazy isn't in my vocabulary.</p>
      {colaboradorEdit === true &&
        <IconButton color="primary" onClick={() => {
          handleOpen("Clear Education");
          setStepID("ID");
        }}>
          <DeleteIcon />
        </IconButton>
      }
    </>
  );
};

const Content = ({ handleOpen, setStepID, education, colaboradorEdit }) => {
  return (
    <ul className={css.list}>

      {colaboradorEdit === true ? (
        <>
          {education?.map((edu) => (
            <li key={edu.id} className={css.education}>
              <div className={css.marker}></div>
              <div className={css.wrapper}>
                <div className={css.intro}>
                  <p className={css.date}>
                    {edu.startDate} - {edu.endDate}
                  </p>
                  <h3>{edu.faculty}</h3>
                  <p className={css.major}>{edu.course}</p>
                  <p className={css.address}>{edu.country}</p>
                  <IconButton color="primary" onClick={() => {
                    handleOpen("Edit Education");
                    setStepID(edu.id);
                  }}>
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => {
                    handleOpen("Delete Education");
                    setStepID(edu.id);
                  }}>
                    <ClearOutlinedIcon />
                  </IconButton>
                </div>
                <p className={css.description}>{edu.description}</p>
              </div>
            </li>
          ))}
        </>
      ) : (
        <>
          {education?.map((edu) => (
            <li key={edu.id} className={css.education}>
              <div className={css.marker}></div>
              <div className={css.wrapper}>
                <div className={css.intro}>
                  <p className={css.date}>
                    {edu.startDate} - {edu.endDate}
                  </p>
                  <h3>{edu.faculty}</h3>
                  <p className={css.major}>{edu.course}</p>
                  <p className={css.address}>{edu.country}</p>
                </div>
                <p className={css.description}>{edu.description}</p>
              </div>
            </li>
          ))}
        </>
      )}


    </ul>
  );
};

export const Education = ({ handleOpen, setStepID, education, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} education={education} />} />;
};
