import css from "../css/portfolio.module.css";
import { Section } from "../section";
import { PopupGallery } from "../popup.gallery";
import { useState } from "react";

const Header = () => {
  return (
    <>
      <h2>Portfolio</h2>
      <p>Here it gets interesting.</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      title: "Musée du Louvre",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
    {
      id: 2,
      title: "Tunnel Effect",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
    {
      id: 3,
      title: "New York, New York",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
    {
      id: 4,
      title: "Sky High",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
    {
      id: 5,
      title: "The Eiffel Tower",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
    {
      id: 6,
      title: "Reaching the Clouds",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    },
  ];

  const photoList = data.map((project) => ({
    src: `/portfolio/${project.id}_full.jpg`,
    caption: project.title,
  }));

  const [index, setIndex] = useState(0);
  const [popup, setPopup] = useState(false);

  const showPopup = (i) => {
    setIndex(i);
    setPopup(true);
  };

  const setPosition = (i) => {
    if (i < 0 || i > photoList.length - 1) return;
    setIndex(i);
  };

  return (
    <div>
      <ul className={css.list}>
        {data.map((project) => (
          <li
            key={project.id}
            className={css.project}
            style={{
              backgroundImage: `url(/portfolio/${project.id}_thumb.jpg)`,
            }}
            role="button"
            onClick={() => showPopup(project.id - 1)}
          >
            <div className={css.wrapper}>
              <div className={css.overlay}></div>
              <h3 className={css.title}>{project.title}</h3>
              <hr className={css.separator} />
              <p className={css.description}>{project.description}</p>
            </div>
          </li>
        ))}
      </ul>
      <PopupGallery
        photoList={photoList}
        index={index}
        setIndex={setPosition}
        visible={popup}
        setVisibility={setPopup}
      />
    </div>
  );
};

export const Portfolio = () => {
  return <Section header={<Header />} content={<Content />} />;
};
