import React from 'react';
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { useSnackbar } from '../../contexts/snackbar-context';
import i18n from "../../translate/i18n";
import { useContext } from 'react';
import { LanguageContext } from '../../translate/languages/LanguageContext';

const SnackbarComponent = () => {
  const { openAlertOK, severity, successMessage, handleCloseAlert } = useSnackbar();  
  const { selectedLanguage } = useContext(LanguageContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={openAlertOK}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
    >
      <Alert severity={severity} onClose={handleCloseAlert}>
        <AlertTitle>
          {severity === 'success'
            ? i18n.t('alert.success', { lng: selectedLanguage })
            : severity === 'warning'
            ? i18n.t('alert.warning', { lng: selectedLanguage })
            : severity === 'info'
            ? i18n.t('alert.info', { lng: selectedLanguage })
            : i18n.t('alert.error', { lng: selectedLanguage })}
        </AlertTitle>
        {successMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
