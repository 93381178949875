import { useLazyQuery } from "@apollo/client";
import { Button, Grid, Stack } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HistoryChart from "../../components/Chart/area-chart.component";
import DripChart from "../../components/Chart/drip-chart.component";
import MainCard from "../../components/MainCard/main-card.component";
import { setTitle } from "../../helpers";
import Analytics from "./analytics.component";
import { GET_EMPLOYEER_DATA } from "./query.gql";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import "./style.css";
import { EVAL_PERIODS_BY_ID, GET_COLLABORATORS } from "../Ratings/query";
import { GET_COMPANY_BY_ID } from "../CompanyPage/Company_View_Page/query.gql";
import { AuthContext } from "../../contexts/auth-context";
import { GET_COLLABORATORS_BY_COMPANY_ID } from "../Collaborator/Collaborator_View_Page/query.gql";

const ProfileMenu = () => {

  let navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2}>
      {/* <Button
        color="warning"
        variant="contained"
        size="small"
        style={{ borderRadius: "15px" }}
        onClick={() => navigate("profile")}
      >
        Ver Perfil
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={() => navigate("password/change")}
      >
        Alterar Senha
      </Button> */}
    </Stack>
  );
};

const HomePage = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const jwt = localStorage.getItem("jwtToken");
  const role = localStorage.getItem('userRole')
  const { id: userID } = jwtDecode(jwt);
  const [employeerData, setEmployeerData] = useState({
    name: '',
    postRole: ''
  })

  const [getEmployeerData] = useLazyQuery(GET_EMPLOYEER_DATA)
 
 
  const [getEvals_] = useLazyQuery(GET_COLLABORATORS_BY_COMPANY_ID)

  const [eval_period, setEval_period] = useState([])
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const [getCompany, { data, loading, error }] = useLazyQuery(GET_COMPANY_BY_ID);
  const { user } = useContext(AuthContext);



    useEffect(() => {
   if(role == 'administrator'){
    get_All_COllaborators()
   }
      
      if (user?.id) {
        get_Company();
      }
    }, [user?.id]);
    
    const get_Company = async () => {
      try {
       await getCompany({
          variables: {
            userId: user?.id,
          },
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
        });

      } catch (error) {
        console.error("Erro ao buscar dados da empresa:", error);
      }
    };
    
    

  useEffect(() => {
  
    if (data) {
console.log("tem data");

      data.companies?.data?.map((tx) => {
        if (user?.id === Number(tx?.attributes.users_permissions_user.data.id)) {
             const companyId = tx?.attributes.users_permissions_user.data.id;
            get_Eval(tx.id);

        }
        
      }
      )

    }


    //get_Eval_()


  }, [data, loading, error])

  const get_All_COllaborators = async (companyId) => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {

      const evalData = data?.data?.collaborators.data
      console.log("evalData", evalData);
      const transformedData = evalData.flatMap((collaborator) => {
        // Obter nome do colaborador
        const collaboratorName = collaborator?.attributes?.name || "N/A";

        // Iterar pelo array de avaliações dentro de cada colaborador
        return collaborator?.attributes?.evaluations?.data.map((evaluation) => {

          const companyData = evaluation?.attributes?.companies?.data?.[0]; // Obtém o primeiro objeto do array de empresas, se existir
          const companyName = companyData?.attributes?.name || "N/A"; 
          return {
        
            name: collaboratorName,
            company: companyName, // Assume o ID da primeira empresa, se houver
            evaluationId: evaluation?.id || "N/A",
            star: evaluation?.attributes?.star || "N/A",
            comment: evaluation?.attributes?.Comment || "N/A",
          };
        }) || [];
      });
      
      console.log("Transformed Data", transformedData);
      
      
      setEval_period(transformedData)

    


    }).catch((error) => {
      console.log("erro", error);

    })
  }

  const get_Eval = async (companyId) => {

    if (!companyId) {
    return
    }else{

      try {

        await getEvals_({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            companyId: companyId,
            filters: {
              isActive: {
                eq: true,
              },
              companies: {
                id: {
                  eq: companyId,
                },
              },
            },
          },
    
        }).then((data) => {
    
          //  const duplicatCollabs =item?.attributes?.collaborators?.data.filter((item, index) => eval_item?.indexOf(item.map((xx)=> xx.id )) === index)
          const evalData = data?.data?.collaborators.data
          console.log("evalData", evalData);
          const transformedData = evalData.flatMap((collaborator) => {
            // Obter nome do colaborador
            const collaboratorName = collaborator?.attributes?.name || "N/A";

            // Iterar pelo array de avaliações dentro de cada colaborador
            return collaborator?.attributes?.evaluations?.data.map((evaluation) => {

              const companyData = evaluation?.attributes?.companies?.data?.[0]; // Obtém o primeiro objeto do array de empresas, se existir
              const companyName = companyData?.attributes?.name || "N/A"; 
              return {
            
                name: collaboratorName,
                company: companyName, // Assume o ID da primeira empresa, se houver
                evaluationId: evaluation?.id || "N/A",
                star: evaluation?.attributes?.star || "N/A",
                comment: evaluation?.attributes?.Comment || "N/A",
              };
            }) || [];
          });
          
          console.log("Transformed Data", transformedData);
          
          
          setEval_period(transformedData)
    
        });
      } catch (error) {
        console.error("Erro ao buscar períodos de avaliação:", error);
      }
    }
  
   

  };

  

  useEffect(() => {
    getEmployeerData({
      variables: {

        "usersPermissionsUserId": userID

      },
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {

      let employeerData = data.data.usersPermissionsUser.data;
      setEmployeerData({
        name: employeerData.attributes.name,
        postRole: employeerData?.attributes?.postRole?.data?.attributes?.description
      })
    }).catch(e => {
      console.log(e)
    })
  }, [])



  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MainCard
              title={i18n.t('dashboard.welcome', { name: employeerData.name }) + "!!"}
              subtitle={employeerData.postRole}
              action={<ProfileMenu />}
            />
          </Grid>
          <Grid item xs={12}>
            <MainCard title={i18n.t('dashboard.graph', { lng: selectedLanguage })}>
              <DripChart />
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MainCard title={i18n.t('dashboard.review', { lng: selectedLanguage })}>
              <Analytics />
            </MainCard>
          </Grid>
          <Grid item xs={12}>
          {eval_period && eval_period.length > 0 && (
            <MainCard
              title={i18n.t('dashboard.history', { lng: selectedLanguage })}
              subtitle={i18n.t('dashboard.last', { lng: selectedLanguage })}
            >
<table className="w-full table-auto border-collapse">
  <thead>
    <tr>
      <th className="px-8 py-2 border-b text-left">ID</th>
      <th className="px-8 py-2 border-b text-left">Description</th>
      <th className="px-8 py-2 border-b text-left">Collaborator</th>
    </tr>
  </thead>
  <tbody>
    {eval_period.slice(-3).reverse().map((period) => {
      return (
        <tr key={period?.id}>
          <td className="px-8 py-2 border-b">{period?.evaluationId || 'N/A'}</td>
          <td className="px-8 py-2 border-b">{period?.comment || 'N/A'}</td>
          <td className="px-8 py-2 border-b">{period?.name || 'N/A'}</td>
          
        </tr>
      );
    })}
  </tbody>
</table>


            </MainCard>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HistoryChart />
      </Grid>
    </Grid>
  );
};

export default HomePage;
