import { Section } from "../section";
import css from "../css/skills.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const Header = ({  handleOpen, setStepID, colaboradorEdit  }) => {
  return (
    <>
    <h2>{colaboradorEdit === true && <>
      <IconButton color="primary" onClick={() => {
        handleOpen("Add Skill");
        setStepID("ID");
      }}>
        <PlusOneIcon />
      </IconButton>
    </>}Skills</h2>
    <p>Progress bars, anyone?</p>
    {colaboradorEdit === true &&
      <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Clear Skill");
          setStepID("ID");
        }}>
          <DeleteIcon />
        </IconButton>
      </>}
  </>
  );
};

const Content = ({ handleOpen, setStepID, skills, colaboradorEdit }) => {


  return (
    <>{colaboradorEdit === true ? (
      <>
        <ul className={css.skills}>
          {skills.map((skill) => (
            <li key={skill.id}>
              <div className={css.describe}>
                <span className={css.percentage}>
                  {skill.percentual}
                  <small>%</small>
                </span>
                <span className={css.label}>{skill.name}</span>
              </div>
              <div
                className={css.bar}
                style={{ "--percent": `${skill.percentual}%` }}
              ></div>
              <IconButton color="primary" onClick={() => {
                handleOpen("Edit Skill");
                setStepID(skill.id);
              }}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => {
                handleOpen("Delete Skill");
                setStepID(skill.id);
              }}>
                <ClearOutlinedIcon />
              </IconButton>

            </li>
          ))}
        </ul>
      </>
    ) : (
      <>
        <ul className={css.skills}>
          {skills.map((skill) => (
            <li key={skill.id}>
              <div className={css.describe}>
                <span className={css.percentage}>
                  {skill.percentual}
                  <small>%</small>
                </span>
                <span className={css.label}>{skill.name}</span>
              </div>
              <div
                className={css.bar}
                style={{ "--percent": `${skill.percentual}%` }}
              ></div>
            </li>
          ))}
        </ul>
      </>
    )}</>

  );
};

export const Skills = ({ handleOpen, setStepID, skills, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID}  colaboradorEdit={colaboradorEdit} skills={skills} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} skills={skills} />} />;
};
