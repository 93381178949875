import { useMutation } from '@apollo/client';
import {
    DELETE_AWARD, DELETE_COLLABORATORS,
    DELETE_EDUCATION, DELETE_EXPERIENCE,
    DELETE_EXPERTISE_CATEGORY, DELETE_MEDIA_FILE,
    DELETE_PORTFOLIO, DELETE_PROFILE,
    DELETE_SKILLS, DELETE_USERS,
    DISABLE_USERS, UPDATE_COLLABORATORS
} from './mutation.gql';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';

const ColaboradorDelete = ({ collaboratorID, collaboratorUser, collaboratorUserID, collaborators, setCollaborators, handleClose, collaboratorStep }) => {
 

    const deleteColab = collaboratorStep === false
    const disableColab = collaboratorStep === true
    const booleanStatus = collaboratorUser.isActive
    const [deleteCollaborator] = useMutation(DELETE_COLLABORATORS)
    const [disableCollaborator] = useMutation(UPDATE_COLLABORATORS)
    const [deleteUser] = useMutation(DELETE_USERS)
    const [disableUser] = useMutation(DISABLE_USERS)
    const [deleteAvatar] = useMutation(DELETE_MEDIA_FILE);
    const [deleteExpertise] = useMutation(DELETE_EXPERTISE_CATEGORY)
    const expertises = collaboratorUser.expertise
    const [deleteSkill] = useMutation(DELETE_SKILLS)
    const skills = collaboratorUser.skills
    const portfolio = collaboratorUser.portfolios
    const awards = collaboratorUser.awards
    const profiles = collaboratorUser.profiles
    const educations = collaboratorUser.educations
    const experiences = collaboratorUser.experiences
    const [deleteExperience] = useMutation(DELETE_EXPERIENCE)
    const [deleteEducation] = useMutation(DELETE_EDUCATION)
    const [deleteProfile] = useMutation(DELETE_PROFILE)
    const [deleteAward] = useMutation(DELETE_AWARD)
    const [deletePortfolio] = useMutation(DELETE_PORTFOLIO)
    const jwt = localStorage.getItem("jwtToken")

    const handleDisable = async () => {
        try {
            const data = await disableCollaborator({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: collaboratorID,
                    data: {
                        isActive: !collaboratorUser.isActive,
                    },
                },
            });

            const isActiveStatus = data.data?.updateCollaborator?.data?.attributes?.isActive;

            await disableUser({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: collaboratorUserID,
                    disable: collaboratorUser.isActive,
                },
            });

            toast.success("Collaborator Updated!!");

            const foundExpertise = {
                isActive: isActiveStatus,
            };

            const foundExpertiseIndex = collaborators.findIndex((expertise) => expertise.id === collaboratorID);

            if (foundExpertiseIndex !== -1) {
                Object.keys(foundExpertise).forEach((key) => {
                    collaborators[foundExpertiseIndex][key] = foundExpertise[key];
                });
            }

            handleClose();
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong!");
        }
    };

    const handleDelete = async () => {
        try {
            await deleteCollaborator({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: collaboratorID,
                },
            }).then((data) => {
                deleteAvatar({
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    //fetchPolicy: "network-only",
                    variables: {
                        id: collaboratorUser.avatarID,

                    },
                })
            });

            await deleteUser({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: collaboratorUserID,
                },
            });



            if (expertises.length > 0) {
                for (const expertise of expertises) {
                    try {
                        await deleteExpertise({
                            context: {
                                headers: {
                                    authorization: `Bearer ${jwt}`,
                                },
                            },
                            variables: {
                                id: expertise.id,
                            },
                        });
                    } catch (error) {
                        console.log(error);
                        toast.error("Error Deleting Expertises!");
                    }
                }
            }
            if (skills.length > 0) {
                skills.forEach((skill, index) => {
                    deleteSkill({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: skill.id,
                        },
                    })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting Skills!");
                        });
                });
            }
            if (portfolio.length > 0) {
                portfolio.forEach((port, index) => {
                    deletePortfolio({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: port.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Portfolio Deleted!");
                            deleteAvatar({
                                context: {
                                    headers: {
                                        authorization: `Bearer ${jwt}`,
                                    },
                                },
                                //fetchPolicy: "network-only",
                                variables: {
                                    id: port.imageID,

                                },
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting");
                        });
                });
            }
            if (awards.length > 0) {
                awards.forEach((award, index) => {
                    deleteAward({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: award.id,
                        },
                    })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting Awards!");
                        });
                });
            }
            if (profiles.length > 0) {
                profiles.forEach((profile, index) => {
                    deleteProfile({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: profile.id,
                        },
                    })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting Profiles!");
                        });
                });
            }
            if (educations.length > 0) {
                educations.forEach((education, index) => {
                    deleteEducation({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: education.id,
                        },
                    })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting Educations!");
                        });
                });
            }
            if (experiences.length > 0) {
                experiences.forEach((experience, index) => {
                    deleteExperience({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: experience.id,
                        },
                    })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Error Deleting Experiences!");
                        });
                });
            }

            toast.success("Collaborator Eliminated!!");
            
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong!");
        }
        const updatedData = collaborators.filter((obj) => obj.id !== collaboratorID);
        await setCollaborators(updatedData);
        handleClose();
    };



    return (
        <form onSubmit={handleDelete}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        {deleteColab && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDelete
                            }
                        >Delete</Button>}
                        {disableColab && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDisable
                            }
                        >{booleanStatus ? 'Disable' : 'Enable'}</Button>}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

};

export default ColaboradorDelete;
