import { Alert, AlertTitle, Button, FormControl, FormControlLabel, Grid, InputLabel, Snackbar, Switch, TextField, Typography } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_POST_ROLES } from './query.gql';
import { CREATE_DIMENSION, UPDATE_DIMENSION } from './mutation.gql';
import { React, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';
import { useSnackbar } from '../../contexts/snackbar-context';

const DimensionFormTest = ({ dimension, titleAction, handleClose, setData, list, setList }) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const { setOpenAlertOK, setSuccessMessage, setSeverity } = useSnackbar()

  const viewData = titleAction === i18n.t('title.viewDimension', { lng: selectedLanguage });
  const editData = titleAction === i18n.t('title.editDimension', { lng: selectedLanguage });
  const createData = titleAction === i18n.t('title.dimension', { lng: selectedLanguage });

  const jwt = localStorage.getItem("jwtToken");
  const [title] = useState(i18n.t('button.save', { lng: selectedLanguage }));

  const [formData, setFormData] = useState({
    dimensions: dimension.dimension,
    postRole: dimension.postRole,
    isActive: dimension.isActive,
  });

  const [postRoleError, setPostRoleError] = useState("");
  const [dimensions, setDimensions] = useState([]);
  const [postRoles, setPostRoles] = useState([]);
  const [getPostRoles] = useLazyQuery(GET_POST_ROLES);
  const [valueData, setValueData] = useState([]);

  useEffect(() => {
    getPostRoles({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: 'cache-and-network'
    }).then(data => {
      setPostRoles(data.data.postRoles.data.map(pr => ({
        id: pr.id,
        postRole: pr.attributes.postRole,
        description: pr.attributes.description
      })));
    }).catch(error => {
      console.log(error);
    });

    if (viewData) {
      setValueData(dimension.postRole);
    } else if (editData) {
      setValueData(dimension.postRole);
    }
  }, []);



  const [checked, setChecked] = useState(formData.isActive);
  const handleChangeState = (e) => {
    setChecked(e.target.checked)
    setFormData({ ...formData, isActive: e.target.checked })
  };

  const [newDimension] = useMutation(CREATE_DIMENSION);
  const [updateDimension] = useMutation(UPDATE_DIMENSION);
  const dimensionToCheck = formData?.dimensions;
  console.log("list", list)
  const handleSubmit = (e) => {
    e.preventDefault();

    let postArray = formData.postRole.map(obj => parseInt(obj.id));
    if (dimension.id === undefined) {
      let sameDimension = false;
      for (const item of list) {
        if (item.attributes.dimension === dimensionToCheck) {
          sameDimension = true;
          break;
        }
      }
      if (sameDimension === true) {
        setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
        setOpenAlertOK(true)
        setSuccessMessage(`${dimensionToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
        setSeverity("warning")
      } else {
        newDimension({
          variables: {
            data: {
              dimension: formData.dimensions,
              postRoles: postArray,
            },
          },
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
        }).then((response) => {
          const newDimension = response.data.createDimension.data;
          setData((prevDimension) => [newDimension, ...prevDimension]);
          setOpenAlertOK(true)
          setSuccessMessage(i18n.t('success.createDimension', { lng: selectedLanguage }));
          setSeverity("success")
          handleClose()
        })
          .catch(error => {
            //toast.error("Ocorreu um erro ao adicionar dimensão");
            console.log(error, "Error Create")
            setOpenAlertOK(true)
            setSuccessMessage(i18n.t('error.createPeriod', { lng: selectedLanguage }));
            setSeverity("error")
          });

      }
    } else {
      let sameDimension = false;
      for (const item of list) {
        if (item.attributes.dimension === dimensionToCheck) {
          sameDimension = true;
          break;
        }
      }
      if (dimensionToCheck !== dimension.dimension && sameDimension === true) {
        setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
        setOpenAlertOK(true)
        setSuccessMessage(`${dimensionToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
        setSeverity("warning")
      } else {
        if (formData.dimensions === dimension.dimension &&
          formData.postRole === dimension.postRole &&
          formData.isActive === dimension.isActive) { handleClose() } else {
          let postArray = formData.postRole.map(obj => parseInt(obj.id));
          updateDimension(

            {
              variables: {
                updateDimensionId: dimension.id,
                data: {
                  dimension: formData.dimensions,
                  postRoles: postArray,
                  isActive: formData.isActive
                }
              },
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
            }).then(data => {
              if (formData.dimensions !== dimension.dimension ||
                formData.postRole !== dimension.postRole ||
                formData.isActive !== dimension.state) {
                const updatedList = list.map(item => {
                  if (item.id === dimension.id) {
                    return data.data.updateDimension.data;
                  } else {
                    return item;
                  }
                });
                setList(updatedList)
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('success.updateDimension', { lng: selectedLanguage }));
                setSeverity("success")
                handleClose()
              }
            }).catch(error2 => {
              //toast.error("Ocorreu um erro ao atualizar dimensão!!");
              console.log(error2, "Error Update")
              setOpenAlertOK(true)
              setSuccessMessage(i18n.t('error.updatePeriod', { lng: selectedLanguage }));
              setSeverity("error")
            });

        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} padding={3}>
        <Grid container spacing={2} margin={1}>
          <Grid item xs={6}>
            <InputLabel>{i18n.t('table.name', { lng: selectedLanguage })}</InputLabel>
            <TextField
              InputProps={{
                readOnly: viewData ? true : false,
              }}
              variant="outlined"
              fullWidth required
              rows={1}
              defaultValue={viewData ? dimension.dimension :
                editData ? dimension.dimension : formData.dimensions}
              onChange={(e) =>
                setFormData({ ...formData, dimensions: e.target.value })
              }
            />
            {postRoleError && <Typography
              variant="body2"
              color="red"
              style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
              {postRoleError}
            </Typography>}
          </Grid>
          <Grid item xs={6}>
            <InputLabel  >{i18n.t('table.role', { lng: selectedLanguage })}</InputLabel>
            <FormControl fullWidth size='small'>
              <Autocomplete
                multiple
                readOnly={viewData ? true : false}
                value={valueData}
                onChange={(e, value) => {
                  setValueData(value)
                  setFormData({ ...formData, postRole: value })
                }
                }
                options={postRoles}
                getOptionLabel={(option) => option.postRole}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          {!createData && (
            <Grid item xs={12}>
              <FormControlLabel
                disabled={!editData ? true : false}
                sx={{ marginLeft: 0 }}
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={checked}
                    onChange={handleChangeState}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={i18n.t('table.state', { lng: selectedLanguage })}
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} item xs={12} display="flex" justifyContent="center" alignItems="center" marginTop={15}>
          <Grid item xs={3}>
            <Button variant="outlined" size="large" fullWidth onClick={handleClose}>{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={viewData ? true : false}
              type="submit"
              variant="contained"
              size="large"
              fullWidth

            >
              {title}
            </Button>

          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default DimensionFormTest;
