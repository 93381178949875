import { useState,useEffect } from "react";
import MainCard from "../../../components/MainCard/main-card.component";
import { Hero } from "../sections/hero";
import { Indicate } from "../sections/indicate"; 

function Avaliar({
viewCollab, 
indicators,
company_id,
setCompany_id,
btnState,
setBtnState,
showEvals,
setShowEvals,
companyEditEval,
setCompanyEditEval,
answer_Id,
score_Id,
set_Score_Id,
question_Id,
step,
setStep,
set_Answer_Id,
answer_, 
set_answer_ ,
scoreFilter,
setScoreFilter,
i_,
setI_ ,
eval_period
}) { 
  const jwt = localStorage.getItem("jwtToken");
  const [open, setOpen] = useState(false); 
  

/*   const indicate = indicators.map((ind)=>
  ind?.attributes?.questions?.data ) */

  function handleOpen(step) {
    setOpen(true);
    
  };
 

  return ( 
    < >  
    <MainCard title="Collaborators Evaluation">
        <Hero handleOpen={handleOpen} collaborator={viewCollab}/>
        <Indicate eval_period={eval_period} company_id={company_id} step={step} setStep={setStep}btnState={btnState}
setBtnState={setBtnState} handleOpen={handleOpen} collaborator={viewCollab} indicators={indicators} companyEditEval={companyEditEval} setCompanyEditEval={setCompanyEditEval} showEvals={showEvals}setShowEvals={setShowEvals} setCompany_id={setCompany_id}answer_Id={answer_Id}score_Id={score_Id}set_Score_Id={set_Score_Id} question_Id={question_Id}set_Answer_Id={set_Answer_Id} answer_={answer_} set_answer_={set_answer_} scoreFilter={scoreFilter}setScoreFilter={setScoreFilter} i_={i_}setI_={setI_} />
 
</MainCard>
      
    </ >
  )
}

export default Avaliar