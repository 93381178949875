import css from "../css/awards.module.css";
import { Section } from "../section";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Award");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}Awards</h2>
      <p>Happy times!</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Award");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ setStepID, handleOpen, awards, colaboradorEdit }) => {


  return (
    <>
      {colaboradorEdit === true ? (
        <>
          <ul className={css.list}>
            {awards.map((award) => (
              <li key={award.id} className={css.award}>
                <h3 className={css.title}>{award.name}</h3>
                <p className={css.description}>{award.description}</p>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Edit Award");
                  setStepID(award.id);
                }}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Delete Award");
                  setStepID(award.id);
                }}>
                  <ClearOutlinedIcon />
                </IconButton>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <ul className={css.list}>
            {awards.map((award) => (
              <li key={award.id} className={css.award}>
                <h3 className={css.title}>{award.name}</h3>
                <p className={css.description}>{award.description}</p>
              </li>
            ))}
          </ul>
        </>
      )
      }
    </>


  );
};

export const Awards = ({ setStepID, handleOpen, awards, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content setStepID={setStepID} handleOpen={handleOpen} awards={awards} colaboradorEdit={colaboradorEdit} />} />;
};
