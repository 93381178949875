import { gql } from "@apollo/client";

export const CREATE_EVALUATION_PERIOD = gql`
mutation EvaluationPeriod($data: EvaluationPeriodInput!) {
    createEvaluationPeriod(data: $data) {
      data {
        id
        attributes {
          description
          start
          end
          company {
            data {
              id              
              attributes { name branchOfActivity email phone status}
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
                email
                phone
                postRole{data{id attributes{postRole description}}}
              }
            }
          }
          isActive
        }
      }
    }
  }
  `;

export const UPDATE_EVALUATION_PERIOD = gql`
mutation UpdateEvaluationPeriod($id: ID!, $data: EvaluationPeriodInput!) {
    updateEvaluationPeriod(id: $id, data: $data) {
     data {
        id
        attributes {
          description
          start
          end
          company {
            data {
              id              
              attributes { name branchOfActivity email phone status}
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
                email
                phone
                postRole{data{id attributes{postRole description}}}
              }
            }
          }
          isActive
        }
      }
    }
  }
  
  `;
export const DELETE_PERIOD = gql`
mutation DeleteEvaluationPeriod($id: ID!) {
    deleteEvaluationPeriod(id: $id) {
     data {
            id
            attributes {
                description
                start
                end
                isActive
                  company{data{id attributes{name email phone } } }
                 collaborators{data{id attributes{name email phone } } }
            }
        }
    }
} 
`;