import css from "../css/awards.module.css";
import { Section } from "../section";

const Header = () => {
  return (
    <>
      <h2>Awards</h2>
      <p>Happy times!</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      title: "UNICORN DEVELOPER AWARD 2016",
      description:
        "Lorem ipsum dolor sit amet, justo eget porttitor mauris sit amet felis. Neque id cursus faucibus.",
    },
    {
      id: 2,
      title: "WEBSITE OF THE YEAR AWARD 2015",
      description:
        "Praesent dapibus dolor sit amet, justo eget porttitor mauris sit amet. Neque id cursus faucibus.",
    },
    {
      id: 3,
      title: "1ST PLACE AT CSSHACKER CONFERENCE",
      description:
        "Lorem ipsum dolor sit amet, justo eget porttitor mauris sit amet felis. Neque id cursus faucibus.",
    },
  ];

  return (
    <ul className={css.list}>
      {data.map((award) => (
        <li key={award.id} className={css.award}>
          <h3 className={css.title}>{award.title}</h3>
          <p className={css.description}>{award.description}</p>
        </li>
      ))}
    </ul>
  );
};

export const Awards = () => {
  return <Section header={<Header />} content={<Content />} />;
};
