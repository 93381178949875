import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [openAlertOK, setOpenAlertOK] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [severity, setSeverity] = useState('info');

  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        openAlertOK,
        setOpenAlertOK,
        successMessage,
        setSuccessMessage,
        severity,
        setSeverity,
        handleCloseAlert
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
