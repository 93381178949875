import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "./styles.css";

export default function LoginCarousel() {

    const Arrow = (props) => {
        const { className, style, onClick, dir} = props;
        return <div style={{
            right:20,
            position:"absolute",
            bottom: 10,
        }}>
        {dir == "left" || dir == "l"?
        <img onClick={onClick} style={{zIndex:999,position:"relative",transform: 'rotate(180deg)', right: 40}} src="./arrow.svg" />
        :<img onClick={onClick} src="./arrow.svg" />
        }
        </div>;
      }

  var settings = {
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>),
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow dir="left"/>,
  };

  const Info = ({title,desc}) => <Box sx={{p:5,pl:10}}>
    <Typography children={title} variant="h1" color="#fff"/>
    <Typography children={desc} variant="h2" color="#fff" />
  </Box>;

  
  return (
    <Slider {...settings}>
        <Info title="Registar" desc="Make your registration on our site!"/>
      <div>
        <Info title="Choose your Dev"  desc="Get access to all developers and choose the best solution for you!"/>
      </div>
      <div>
        <Info title="Expression of Interest" desc="Fill out the fields below with the desired profile and we will soon schedule with you!"/>
      </div>
      <div>
        <Info title="Schedule" desc="African coders will contact you!"/>
      </div>
    </Slider>
  );
}