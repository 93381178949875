import { Section } from "../section";
import css from "../css/expertise.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Expertise");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}EXPERTISE</h2>
      <p>Batman would be jealous.</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Expertise");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ handleOpen, setStepID, expertise, colaboradorEdit }) => {
  //console.log("expertiseContent", expertise)
  return (
    <>{colaboradorEdit === true ? (
      <>
        <div className={css.wrapper}>
          <ol className={css.expertise}>
            {expertise?.map((expertise) => (
              <li key={expertise.id}>
                <h3>{expertise.expertise}</h3>
                <p>
                  {expertise.description}
                </p>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Edit Expertise");
                  setStepID(expertise.id);
                }}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Delete Expertise");
                  setStepID(expertise.id);
                }}>
                  <ClearOutlinedIcon />
                </IconButton>
              </li>

            ))}
          </ol>
        </div>
      </>
    ) : (
      <>
        <div className={css.wrapper}>
          <ol className={css.expertise}>
            {expertise?.map((expertise) => (
              <li key={expertise.id}>
                <h3>{expertise.expertise}</h3>
                <p>
                  {expertise.description}
                </p>
              </li>
            ))}
          </ol>
        </div>
      </>
    )}</>

  );
};

export const Expertise = ({ handleOpen, setStepID, expertise, colaboradorEdit }) => {
  //console.log("expertise", expertise)
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} expertise={expertise} />} />;
};
