import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Chip,
  Grid,
  InputLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/login-context";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN } from "./mutation.gql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/auth-context";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import i18n from "../../translate/i18n";
import CloseIcon from '@mui/icons-material/Close';
import { GET_COMPANY_BY_ID } from "../CompanyPage/Company_View_Page/query.gql";

const validationEmailForm = yup
  .object({
    password: yup
      .string()
      .min(3, "A senha precisa ter mais de 3 caracteres")
      .required("A senha é obrigatória"),
  })
  .required();




const LoginPassword = () => {
  let navigate = useNavigate();

  const { loginData, setLoginData } = useContext(LoginContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const context = useContext(AuthContext);
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [logindate, setLoginDate] = useState(null)
  const [login] = useMutation(LOGIN);

  useEffect(() => {
    if (loginData.email === "")
      navigate("/login");
  }, [loginData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });
  const [getCompany, { data, loading, error }] = useLazyQuery(GET_COMPANY_BY_ID);

  const toRegister = () => navigate("/register");
  const handleClickDelete = () => {
    setLoginData((ld) => {
      return {
        ...ld,
        email: "",
      };
    });
  };
  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };

  const loginForm = (dataForm) => {
    // JJSJS
    let date = new Date();
    login({
      variables: {
        input: {
          identifier: loginData.email,
          password: dataForm.password,
        },
      },
    })
      .then((datas) => {
        setLoginDate(datas.data.login)
        const userId = datas.data.login.user.id;
        const jwt = datas.data.login.jwt;

        getCompany({
          variables: {
            userId: userId,
          },
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
        });






        /*    getCompany({
             variables: {
               userId: datas.data.login.user.id, // Passando o ID do usuário para buscar a empresa associada
             },
             context: {
               headers: {
                 authorization: `Bearer ${datas.data.login.jwt}`,
               },
             },
           });
           console.log("id", datas.data.login.user.id);
           console.log("jwt", datas.data.login.jwt);
           //parece que será aqui
           console.log("companyData", data);
           debugger
           if (data && data.status === "Pending") {
   
             setOpenAlertOK(true)
             setSuccessMessage("A sua Empresa encontra-se com o estado pendente! Por favor contacte o administrador.");
             navigate("/login");
           } else {
             // aqui
             context.login(datas.data.login);
   
           } */
      })
      .catch((error) => {
        alert("Senha incorrecta!!");
      });
  };


  useEffect(() => {
    if (data) {

      // Verificar se a empresa tem o status "Pending"
      if (data.companies.data.length > 0 && data.companies.data[0].attributes.status === "Pending") {
        setOpenAlertOK(true);
        setSuccessMessage("A sua Empresa encontra-se com o estado pendente! Por favor contacte o administrador.");
        setTimeout(() => {
          navigate("/login");
        }, 5000);
        
      } else {
        localStorage.setItem("userId", data?.companies?.data[0]?.id);
        // A empresa está ativa, pode continuar o fluxo de login
        context.login(logindate);
      }
    }
  }, [data, loading, error]);

  return (
    <form onSubmit={handleSubmit(loginForm)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Avatar sx={{ marginBottom: "10px" }} color="primary">
              <LockOpenIcon />
            </Avatar>
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              color="primary"
            >
              Acesso
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          <Typography style={{ marginBottom: "10px", opacity: 0.8 }}>
            {i18n.t('login.login.welcome', { lng: selectedLanguage })}
          </Typography>
          <Chip
            label={loginData.email}
            variant="outlined"
            onClick={() => { }}
            onDelete={handleClickDelete}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            {i18n.t('login.register.password', { lng: selectedLanguage })}
          </InputLabel>
          <TextField
            required
            autoFocus
            type="password"
            defaultValue=""
            fullWidth
            name="password"
            id="password"
            {...register("password")}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" size="medium" variant="contained" fullWidth>
            {i18n.t('login.button.access', { lng: selectedLanguage })}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button type="button" size="small" variant="text" fullWidth onClick={toRegister}>
            {i18n.t('login.button.toRegister', { lng: selectedLanguage })}
          </Button>
        </Grid>
        <Snackbar
          style={{ backgroundColor: 'green', color: 'coral' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openAlertOK}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
        >
          <Alert severity="warning" sx={{ border: 1 }}>
            <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}> Aviso <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} /> </AlertTitle>
            {successMessage}

          </Alert></Snackbar>

      </Grid>
    </form>
  );
};

export default LoginPassword;
