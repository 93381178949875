import { useMutation } from "@apollo/client";
import { DELETE_DIMENSION } from "./mutation.gql";
import { Button, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import i18n from "../../translate/i18n";
import { useSnackbar } from "../../contexts/snackbar-context";


const DimensionDelete = ({ dimension, handleClose, setList, list }) => {
    const { setOpenAlertOK, setSuccessMessage, setSeverity } = useSnackbar()
    const { selectedLanguage } = useContext(LanguageContext);
    const [deleteDimension] = useMutation(DELETE_DIMENSION);
    const jwt = localStorage.getItem("jwtToken")

    const handleDelete = (event) => {
        event.preventDefault()
        deleteDimension({
            variables: { id: dimension.id },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
            .then((response) => {
                console.log(response.data.deleteDimension.data);
                const updatedData = list.filter(obj => obj.id !== dimension.id);
                setList(updatedData);
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('success.deleteDimension', { lng: selectedLanguage }));
                setSeverity("success")
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('error.deletePeriod', { lng: selectedLanguage }));
                setSeverity("error")
            });
    };
    return (
        <form onSubmit={handleDelete} >
            <Grid container width={400}>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography>{i18n.t('title.delete', { lng: selectedLanguage })}</Typography>
                </Grid>
                <Grid container spacing={2} paddingTop={3} display="flex" justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" size="small" onClick={handleDelete}>{i18n.t('button.confirm', { lng: selectedLanguage })}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
export default DimensionDelete;