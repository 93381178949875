import { gql } from "@apollo/client";

export const GET_COMPANY_QUESTIONS = gql`
query CompanyQuestions{
    companyQuestions(pagination:{pageSize:1000}) {
      data {
        id
        attributes {
          name
          isActive
        }
      }
    }
}
`;