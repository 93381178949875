import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Grid, StepIcon, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useContext } from "react";
import i18n from "../../../translate/i18n";

const CustomStepIcon = ({ active, completed }) => {
  let iconImage;
  let iconSize = active ? '1.5rem' : '0.8rem';
  let marginLeft = active ? '0.05rem' : '0.35rem';

  if (active) {
    iconImage = '/africanStepperPeople.svg';
  } else if (completed) {
    iconImage = '/africanStepperOrange.svg';
  } else {
    iconImage = '/africanStepperGray.svg';
  }

  return (
    <div style={{ position: 'relative' }}>
      <img src={iconImage} alt="Step Icon" style={{ width: iconSize, height: iconSize, marginLeft: marginLeft }} />
      <StepIcon icon={null} /> {/* Hide the default icon */}
    </div>
  );
};



export default function VerticalLinearStepper({
  CollaboratorCreator,
  collaboratorID,
  receivedStep,
  receivedSetStep,
  collaborator,
  setIsGridVisible,
  isGridVisible,
  setSuccessMessage,
  openAlertOK,
  setSeverity
}) {
  const { selectedLanguage } = useContext(LanguageContext);
  const steps = [
    {
      label: i18n.t('collaborator.stepper.collaborator', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.experience', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.education', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.expertise', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.skill', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.profile', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.award', { lng: selectedLanguage }),
    },
    {
      label: i18n.t('collaborator.stepper.portfolio', { lng: selectedLanguage }),
    },
  ];

  const [activeStep, setActiveStep] = React.useState(receivedStep);
  const [isGridVis, setIsGridVis] = [isGridVisible, setIsGridVisible];
  const receivedSteps = receivedStep;
  const totales = receivedSteps - activeStep;

  React.useEffect(() => {
    setActiveStep(receivedStep);
  }, [receivedSteps, receivedStep]);

  const handleStepClick = (stepIndex) => {
    if (collaborator.length === 0) {
      setSuccessMessage(i18n.t('collaboratorStep.error.collaborator', { lng: selectedLanguage }));
      setSeverity("error")
      openAlertOK(true)
      setIsGridVisible(true);
    } else if (collaborator.length === 1 && collaboratorID === null) {
      CollaboratorCreator();
      receivedSetStep(stepIndex);
    } else {
      receivedSetStep(stepIndex);
    }
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Grid item container>
        <Grid item style={{ marginBottom: '1rem', marginTop: '1.4rem', marginLeft: '2rem' }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} onClick={() => handleStepClick(index)}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                  optional={
                    index === 7 && !isSmallScreen ? (
                      <Typography variant="caption">Final step</Typography>
                    ) : null
                  }
                >
                  {!isSmallScreen && step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}
