import React, { useEffect, useState} from "react";
import { Button, Box, IconButton, Grid,Paper ,Typography,CardActions,Dialog, DialogContent } from "@mui/material";
import { toast } from 'react-toastify';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BusinessIcon from '@mui/icons-material/Business';  
import { UPDATE_COMPANY, DELETE_COMPANY } from "./mutation.gql";
import { useMutation} from "@apollo/client";
import { styled } from '@mui/material/styles';
import { Hero } from "./sections/hero";
import { Trans } from 'react-i18next'
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import i18n from '../../../translate/i18n';

/* import {

  makeStyles,
  Modal,
  Backdrop,
  Fade
} from "@material-ui/core"; */
 
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));
const { REACT_APP_API_URL } = process.env;
 
const CompanyView = ({
  companies,
  setCompanies,
  setCompany_, 
  company_, 
  setOpenAlertOK,
  setSuccessMessage,
   }) => {


  const [open, setOpen] = useState(false);
  const jwt = localStorage.getItem("jwtToken");  
  const [updateCompany] = useMutation(UPDATE_COMPANY); 
  const [active, setActive] = useState(false); 
  const [deleteCompany] = useMutation(DELETE_COMPANY)
  const [activeStatus, setActiveStatus] = useState(company_.status); 
 const { selectedLanguage } = React.useContext(LanguageContext); 

  const imageUrl = company_?.image ? `${REACT_APP_API_URL}${company_?.image}`: '/avatar.jpg'
const handleClose = () => {
    setOpen(false);
   
};
const handleOpen = () => {
  setOpen(true);
 
};

 const handleReturn = () => {
    setCompany_(null)
   // setCompany_Edit(false)
  }
  
const updateStatus=()=>{
 
  if(activeStatus !== 'Active'){
 
    updateCompany({
      context: {
          headers: {
              authorization: `Bearer ${jwt}`,
          },
      },
      //fetchPolicy: "network-only",
      variables: {
           id:company_.id,
          data: {           
           status: 'Active',
           
          },
      },
   
  }).then((data) => {
  
    setOpenAlertOK(true)
    setSuccessMessage("Empresa Atuazido");
  })

  setActiveStatus(company_.status = 'Active')
  }

}


 
const toggleEdit = () => {
     
      setActive(false);
  };
  const toggleQA = () => {
  setActive(true);
 
    }
 
  const removeRequest=()=>{
    deleteCompany({
        context: {
            headers: {
                authorization: `Bearer ${jwt}`,
            },
        },
        //fetchPolicy: "network-only",
        variables: {
            id: company_.id
          },
    }).then((data) => {
        handleClose()
        // console.log( company_.id)
        setOpenAlertOK(true)
        setSuccessMessage("Empresa Removido");
        const updatedData = companies.filter(obj => obj.id !== company_.id);
        setCompanies(updatedData);
        handleReturn()
     
    }).catch((error) => {
        console.log(error)
        toast.error("Something went wrong!");
    });


  }
 
 

  return (
    <>
    
    <Button sx={{p:2}} color="primary" onClick={handleReturn} id='return'>
        Return 
      </Button> 
     <Paper sx={{ flexGrow: 1, }}> 

      <Grid container spacing={0}>
      <Grid item xs={2.5} sx={{ backgroundColor: "#FAFAFA",padding: 2 }}>
       <Button variant={ !active?"contained":"outlined"} onClick={toggleEdit} sx={{ margin: 1,minWidth: 190,minHeight:65}} >
       
     <BusinessIcon/> {i18n.t('button.companyProfile', { lng: selectedLanguage })}</Button>  

       <Button variant={ active? "contained": "outlined"} onClick={toggleQA} autoFocus sx={{ margin: 1,minWidth: 190,minHeight:65}}>
      <LibraryBooksIcon/>{i18n.t('button.questionsandanswers', { lng: selectedLanguage })}
      </Button> 
      </Grid>

      <Paper sx={{ flexGrow: 1 ,p:3}}> 
     
      <Box component="div" sx={{display: "flex" ,justifyContent:'space-between' , flexGrow: 1 }}  >
       
{!active
       ? <>
    <div onClick={handleOpen} style={{height: 50}}> 
   <Hero company_={company_ }/> 
   </div>
<Box sx={{ml:-15}}>

<Typography variant="h2" component="h1" sx={{mt:7}}> {company_.name}</Typography>
<Grid container spacing={2} marginTop={1} >
      
            <Grid item > 
<Typography variant="h3" component="h3" sx={{pt:2.2}}> {i18n.t('table.name', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3"sx={{mt:3}} > {i18n.t('table.email', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}> {i18n.t('table.location', { lng: selectedLanguage })}</Typography>
<Typography variant="h3" component="h3"sx={{mt:3}}>{i18n.t('table.state', { lng: selectedLanguage })}   </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}>{i18n.t('table.branch', { lng: selectedLanguage })}  </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}> {i18n.t('table.phone', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}>Status </Typography>
           </Grid> 
           <Grid item sx={{mt:1.5,  ml: 5}}>
    
             <Typography paragraph={true}sx={{pt:1,  ml:4}} >{company_.name} </Typography>
             <Typography paragraph={true}sx={{pt:1, ml: 4}}>{company_.email} </Typography>
           <Typography paragraph={true} sx={{pt:1,ml: 4}}>{company_.country} </Typography>
               <Typography paragraph={true} sx={{pt:1, ml: 4}}>{company_.city} </Typography> 
             <Typography paragraph={true} sx={{pt:1, ml:4}}>{company_.branchOfActivity} </Typography> 
    <Typography paragraph={true} sx={{pt:1,  ml: 4}}>{company_.phone} </Typography> 
    <Typography paragraph={true} sx={{pt:1, ml: 4}}>{company_.status} </Typography>
 </Grid>
  
</Grid>
</Box>

       </>:
       <>
 
 <Grid>
   {company_?.findDevs?.map((itx,index)=>
            <Typography size="small" sx={{pt: 3}} key={index}> {itx.attributes?.description}</Typography> 
            )}   
   <Box> 
   { company_?.findDevs?.map(itx => 
  itx.attributes?.company_answers).map((ito,i)=> 
    <div key={i}>
    { company_?.findDevs?.map(itx => 
   itx.attributes?.company_answers?.data.map(itl=> 
   itl.attributes.company_question).map(itz => 
   itz.data).map((item,index)=> 
   <Typography key={index}variant="h3" component="h4" sx={{pt: 3}}> {item?.attributes?.name}
   {company_?.findDevs?.map(itx =>itx.attributes.company_answers.data.map((ix,idx) =>
   idx=== index?
   <Typography  key={idx}paragraph={true} sx={{pt: 2 }}> {ix.attributes.name} </Typography  >:''
   ))} 
   </Typography> ))}     
    
    
    </div>)}  
 
  
   </Box> 
     </Grid>
       </>
       
       }

 <Box component="div" sx={{
        display: "flex", 
       
       }}> 
   
          <CardActions 
         sx={{display:"block"}} >
          { active?
           <Box sx={{display:"flex"}} > 
            <Button size="small"variant={ company_.status === 'Active'? "contained": "outlined"} sx={{ m:0.5}}onClick={removeRequest}> 
           <Trans i18nKey="en.reject">Reject</Trans>
            </Button> 
             
           <Button size="small"variant={company_.status !== 'Active'? "contained": "outlined" }autoFocus sx={{ m:0.5}} onClick={updateStatus} >
           
           <Trans i18nKey="en.accept">Accept</Trans>
            </Button>
            </Box>
        
          :'' }
      
       </CardActions>
     </Box>

</Box>

     </Paper>
      </Grid>

    </Paper>
  <Dialog
    open={open}
    onClose={handleClose}
    hasCloseButton
   
  > 

 <img src={imageUrl} alt="avatar"/>
  </Dialog> 
    </>
  );
};

export default CompanyView;
 