import { Button, FormControlLabel, Grid, InputLabel, Switch, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { CREATE_POST_ROLE, UPDATE_POST_ROLE } from './mutation.gql';
import { toast } from 'react-toastify';
import { useSnackbar } from '../../contexts/snackbar-context';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';


const PostRoleForm = ({ cargo, titleAction, handleClose, setData, list, setList }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const { setOpenAlertOK, setSuccessMessage, setSeverity } = useSnackbar()
    const viewData = titleAction === i18n.t('title.viewRole', { lng: selectedLanguage });
    const editData = titleAction === i18n.t('title.editRole', { lng: selectedLanguage });
    const createData = titleAction === i18n.t('title.role', { lng: selectedLanguage });

    const jwt = localStorage.getItem("jwtToken")
    const [title, setTitle] = useState(i18n.t('button.save', { lng: selectedLanguage }))



    const [formData, setFormData] = useState({
        postRole: cargo.postRole,
        description: cargo.description,
        isActive: cargo.isActive,
    });

    const [postRoleError, setPostRoleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [checked, setChecked] = useState(formData.isActive);
    const handleChangeState = (e) => {
        setChecked(e.target.checked)
        setFormData({ ...formData, isActive: e.target.checked })
    }
    const [createPostRole, { loading, error }] = useMutation(CREATE_POST_ROLE);
    const [updatePostRole, { loading2, error2 }] = useMutation(UPDATE_POST_ROLE);
    const postRoleToCheck = formData?.postRole;
    const descriptionToCheck = formData?.description;


    const handleSubmit = (e) => {
        e.preventDefault();

        if (cargo.id === undefined) {
            let samePostRole = false;
            let sameDescription = false;
            for (const item of list) {
                if (item.attributes.postRole === postRoleToCheck) {
                    samePostRole = true;
                    break;
                }
                if (item.attributes.description === descriptionToCheck) {
                    sameDescription = true;
                    break;
                }
            }
            if (samePostRole === true) {
                setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${postRoleToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
               setSeverity("warning")
            } else if (sameDescription === true) {
                setDescriptionError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${descriptionToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
                setSeverity("warning")
            } else {
                createPostRole({
                    variables: {
                        data: {
                            postRole: formData.postRole,
                            description: formData.description,
                            isActive: true,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then((response) => {
                    const newPostRole = response.data.createPostRole.data;
                    setData((prevPostRoles) => [newPostRole, ...prevPostRoles]);
                    setOpenAlertOK(true)
                    setSuccessMessage(i18n.t('success.createRole', { lng: selectedLanguage }));
                    setSeverity("success")
                    handleClose()
                }).catch(error => {
                    console.log(error)
                    toast.error("Ocorreu um erro ao adicionar cargo")
                })

            }
        } else {
            let samePostRole = false;
            let sameDescription = false;
            for (const item of list) {
                if (item.attributes.postRole.toLowerCase() === formData.postRole.toLowerCase()) {
                    samePostRole = true;
                    break;
                }
                if (item.attributes.description.toLowerCase() === descriptionToCheck.toLowerCase()) {
                    sameDescription = true;
                    break;
                }
            }

            if (postRoleToCheck !== cargo.postRole && samePostRole === true) {
                setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${postRoleToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
                setSeverity("warning")
            } else if (descriptionToCheck !== cargo.description && sameDescription === true) {
                setDescriptionError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${descriptionToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
                setSeverity("warning")
            } else {
                if (formData.postRole === cargo.postRole &&
                    formData.description === cargo.description &&
                    formData.isActive === cargo.isActive) { handleClose() } else {
                    updatePostRole({
                        variables: {
                            updatePostRoleId: cargo.id,
                            data: {
                                postRole: formData.postRole,
                                description: formData.description,
                                isActive: formData.isActive
                            }
                        },
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                    }).then(data => {

                        if (formData.postRole !== cargo.postRole ||
                            formData.description !== cargo.description ||
                            formData.isActive !== cargo.isActive) {
                            const updatedList = list.map(item => {
                                if (item.id === cargo.id) {
                                    return data.data.updatePostRole.data;
                                } else {
                                    return item;
                                }
                            });
                            setList(updatedList)
                            setOpenAlertOK(true)
                            setSuccessMessage(i18n.t('success.updateRole', { lng: selectedLanguage }));
                            setSeverity("success")
                            handleClose()
                        }
                    }).catch(error2 => {
                        toast.error("Ocorreu um erro ao atualizar cargo!!")
                    })

                }
            }
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginTop={1} >
                <Grid container spacing={2} margin={1}>
                    <Grid item xs={6}>
                        <InputLabel id="postRoleName" required>{i18n.t('table.name', { lng: selectedLanguage })}</InputLabel>
                        <TextField
                            InputProps={{
                                readOnly: viewData ? true : false,
                            }}
                            id="postRoleName"
                            variant="outlined"
                            fullWidth required
                            rows={1}
                            defaultValue={viewData ? cargo.postRole :
                                editData ? cargo.postRole : formData.postRole}
                            onChange={(e) => {
                                setPostRoleError("");
                                setFormData({ ...formData, postRole: e.target.value })
                            }
                            }
                        />
                        {postRoleError && <Typography
                            variant="body2"
                            color="red"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {postRoleError}
                        </Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="postRoleDescription" required >{i18n.t('table.description', { lng: selectedLanguage })}</InputLabel>
                        <TextField
                            InputProps={{
                                readOnly: viewData ? true : false,
                            }}
                            id="postRoleDescription"
                            variant="outlined"
                            fullWidth required
                            rows={1}
                            defaultValue={viewData ? cargo.description :
                                editData ? cargo.description : formData.description}
                            onChange={(e) => {
                                setDescriptionError("")
                                setFormData({ ...formData, description: e.target.value })
                            }
                            }
                        />
                        {descriptionError && <Typography
                            variant="body2"
                            color="red"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {descriptionError}
                        </Typography>}
                    </Grid>
                    {!createData && <Grid item xs={12}>
                        <FormControlLabel
                            id='postRoleState'
                            disabled={!editData ? true : false}
                            sx={{ marginLeft: 0 }}
                            value="start"
                            control={
                                <Switch
                                    color="primary"
                                    checked={checked}
                                    onChange={handleChangeState}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={i18n.t('table.state', { lng: selectedLanguage })}
                            labelPlacement="start"
                        />
                    </Grid>}
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="center" alignItems="center" marginTop={15}>
                    <Grid item xs={3}>
                        <Button variant="outlined" size="large" fullWidth onClick={handleClose}>
                            {i18n.t('button.cancel', { lng: selectedLanguage })}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            disabled={viewData ? true : false}
                            type="submit" variant="contained" size="large" fullWidth>
                            {title}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default PostRoleForm;