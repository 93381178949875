import { useState, useRef, useContext, useEffect } from "react";
import { Grid, Typography, Card, CardActions, CardHeader, IconButton, Avatar, Box, Snackbar, Alert, AlertTitle } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../components/Theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../../contexts/auth-context";
const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    backgroundColor: "#f8faf7",
    borderRadius: 5,
    padding: 4
  }
}));

const { REACT_APP_API_URL } = process.env;
function Ratings({


  setViewCollab,
  avaliars,
  eval_period,
  setCollabID,
  setBtnState,
  setShowEvals,
  setI_,
  adminViews,
  viewCollab,
  uRoleContext
}) {
  const classes = useStyles();
  const menuRef = useRef(null);
  const { selectedLanguage } = useContext(LanguageContext);
  const isMobileMode = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumMode = useMediaQuery(theme.breakpoints.down('md'));
  const [display, setDisplay] = useState(-1);
  const [openTarget, setOpenTarget] = useState(false);
  const [infoMessage, setSInfoMessage] = useState("");
  const [evalId__, setEvalId__] = useState([])
  const [clickIndex, setClickIndex] = useState(false)
  const [eval_items, setEvalitems] = useState([])
  const [item_p, setItem_p] = useState([])
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [company_id, setCompany_id] = useState(null)
  const { user } = useContext(AuthContext);



  console.log("us   eval_perioder", eval_period);

  useEffect(() => {
    setEvalId__([]);
    setItem_p([])
    setEvalitems([])
    const uniqueCollaborators = new Set();

    eval_period?.forEach((xx, idx) => {
      xx.collaborators.forEach((vx, i) => {
        setEvalitems(prev => [...prev, xx])
        setItem_p(prev => [...prev, xx.id])
        if (!uniqueCollaborators.has(xx.id)) {
          uniqueCollaborators.add(xx.id)
          setClickIndex(true)
          setEvalId__(prev => [...prev, xx])

        } else {
          setClickIndex(false)
        }
        // vx.attributes.evaluation_scores.data.map((fx)=> {
        //   if(fx.attributes.evaluation_period.data.id === xx.id){

        //   }

        // })
      });
    });



  }, [eval_period])

  function handleClickOpenView(collab_id, i, idx) {
    let data = avaliars.find(d => d.id === collab_id)
    if (eval_period.length > 0) {
      setCollabID(collab_id)
      setBtnState(false)
      setShowEvals(false)
      //setI_("1194")
      setI_(eval_period[0].id)
      setViewCollab(data)

    }
    else {
      console.log(eval_period, collab_id)
      setCollabID(collab_id)
      setViewCollab(data)
    }

  }



  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };


  function handleClickEval(collab_id, i) {


    if ((eval_period && eval_period.length === 0 )| !eval_period | eval_period === undefined) {

      if (uRoleContext === 'administrator') {

        let data = avaliars.find(d => d.id === collab_id)
        const evalPeriod = adminViews?.find(ep =>
          ep.attributes.collaborators.data.some(collab => collab.id === collab_id)
        );

        if (evalPeriod.id) {
        
          setI_(evalPeriod.id)
          setBtnState(true)
          setShowEvals(true)
          setCollabID(collab_id)
          setViewCollab(data)


        } else {
          setOpenAlertOK(true)
          setSInfoMessage("Crie um período de avaliação para este colaborador");
          setI_('')
        }

      } else {

        setOpenAlertOK(true)
        setSInfoMessage("Crie um período de avaliação para este colaborador");
      }


    } else {

      let data = avaliars.find(d => d.id === collab_id)
      const evalPeriod = eval_period?.find(ep =>
        ep.collaborators.some(collab => collab.id === collab_id)
      );
      if (evalPeriod.id) {
        setI_(evalPeriod.id)
      } else {
        setI_('')
      }
      setBtnState(true)
      setShowEvals(true)
      setCollabID(collab_id)
      setViewCollab(data)
    }



  }

  const toggleElement = (currentIndex, idx) => {
    setClickIndex(true)
    if (clickIndex === true) {
      setOpenTarget(!openTarget)
      setDisplay(currentIndex);
    } else {
      setClickIndex(false)
      setOpenTarget(!openTarget)
      setDisplay(idx);

    }
    if (uRoleContext === 'administrator') {
      setClickIndex(true)
      setDisplay(currentIndex);

    }

  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenTarget(false)
        //  setClickIndex(false)
      }

    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const duplicatCollabs = item_p?.filter((item, index) => item_p?.indexOf(item) === index)

  return (

    <Grid container spacing={3}>

      {uRoleContext === 'administrator' ? avaliars && avaliars?.map((tx, index) =>
        <Grid item xs={isMobileMode ? 3.5 : isMediumMode ? 12 : 11} key={index}>

          <Card sx={{ height: 100 }} >
            <CardHeader
              avatar={
                <Avatar aria-label="collaborator">
                  <img
                    style={{ height: 50 }}
                    src={tx?.avatar ? `${REACT_APP_API_URL}${tx?.avatar}` : '/avatar.jpg'}
                    alt={tx.name} />
                </Avatar>
              }
              sx={{
                display: "flex",

                overflow: "hidden",
                "& .MuiCardHeader-content": {
                  overflow: "hidden"
                }
              }}
              title={tx.name}
              titleTypographyProps={{ noWrap: true }}
              subheader={tx.postRoleDescription}
              subheaderTypographyProps={{ noWrap: true }}
              action={
                <IconButton className={classes.customHoverFocus} onClick={() => toggleElement(index)}>
                  <MoreVertIcon />
                </IconButton>
              }

            />

          </Card>

          {openTarget && display === index ?
            <Card ref={menuRef} variant="outlined" sx={{ position: 'relative', top: -55, left: 172, width: 150 }}> <CardActions
              disableSpacing>
              <IconButton aria-label="view"
                onClick={() => handleClickOpenView(tx.id, index)}>
                <VisibilityOutlinedIcon color="primary" />
                <Typography sx={{ paddingLeft: 1 }} id={tx.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
              </IconButton>

            </CardActions>

            {uRoleContext !== 'administrator' && (

               <CardActions
                disableSpacing>
                <IconButton aria-label="evaluate"
                  onClick={() => handleClickEval(tx.id, index)}
                >
                  <VisibilityOutlinedIcon color="primary" />
                  <Typography sx={{ paddingLeft: 1 }} id={tx.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                </IconButton>

              </CardActions>

           ) }
             

            </Card> : <></>}
        </Grid>
      ) :
        evalId__.length === 0 ? (
          <Grid item xs={12}>
            <Typography>Nenhum período de avaliação encontrado.</Typography>
          </Grid>
        ) : (

          <Grid item xs={12}>
            {evalId__.map((vs, i) =>
              <Grid key={i}>
                <Typography component='h3' variant='h3' sx={{ pb: 3, pt: 1, }}>Period {vs.id} </Typography>
                <Grid sx={{ display: 'flex' }}>
                  {vs.collaborators.map((ts, index) =>
                    <Grid key={index} item xs={3} sx={{ pr: 2 }}>
                      <Card sx={{ height: 95 }} >
                        <CardHeader
                          avatar={
                            <Avatar aria-label="collaborator">
                              <img
                                style={{ height: 50 }}
                                src={ts?.attributes?.image?.data?.attributes?.url ? `${REACT_APP_API_URL}${ts?.attributes?.image?.data?.attributes?.url}` : '/avatar.jpg'}
                                alt={ts?.attributes?.name} />
                            </Avatar>
                          }
                          sx={{
                            display: "flex",
                            overflow: "hidden",
                            "& .MuiCardHeader-content": {
                              overflow: "hidden"
                            }
                          }}
                          title={ts?.attributes?.name}
                          titleTypographyProps={{ noWrap: true }}
                          subheader={ts?.attributes.postRole.data.attributes.description}
                          subheaderTypographyProps={{ noWrap: true }}
                          action={
                            <IconButton className={classes.customHoverFocus} onClick={() => toggleElement(i, index)}>
                              <MoreVertIcon />
                            </IconButton>
                          }

                        />

                      </Card>

                      {openTarget && display === i && clickIndex === true ?
                        <Card ref={menuRef} variant="outlined" sx={{ width: 150, position: 'absolute', mt: -6, ml: 22 }}>
                          <CardActions
                            disableSpacing>
                            <IconButton aria-label="view"
                              onClick={() => handleClickOpenView(ts.id, i, index)}
                            >
                              <VisibilityOutlinedIcon color="primary" />
                              <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                            </IconButton>

                          </CardActions>
                          <CardActions
                            disableSpacing>
                            <IconButton aria-label="evaluate"
                              onClick={() => handleClickEval(ts.id, i, index)}
                            >
                              <VisibilityOutlinedIcon color="primary" />
                              <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                            </IconButton>

                          </CardActions>
                        </Card> : openTarget && display === index && clickIndex === false ?
                          <Card ref={menuRef} variant="outlined" sx={{ position: 'relative', top: -50, left: 170, width: 150 }}> <CardActions
                            disableSpacing>
                            <IconButton aria-label="view"
                              onClick={() => handleClickOpenView(ts.id, i, index)}
                            >
                              <VisibilityOutlinedIcon color="primary" />
                              <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                            </IconButton>

                          </CardActions>
                            <CardActions
                              disableSpacing>
                              <IconButton aria-label="evaluate"
                                onClick={() => handleClickEval(ts.id, i, index)}
                              >
                                <VisibilityOutlinedIcon color="primary" />
                                <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                              </IconButton>

                            </CardActions>
                          </Card> : <></>}
                    </Grid>
                  )}
                </Grid>

              </Grid>
            )}

          </Grid>
        )
      }
      <Snackbar
        style={{ backgroundColor: 'teal', color: 'orange' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlertOK}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert severity="warning" sx={{ border: 1 }}>
          <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}>
            Atenção! <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} /> </AlertTitle>
          {infoMessage}

        </Alert>
      </Snackbar>
    </Grid>

  )
}

export default Ratings