import { gql } from "@apollo/client";

export const GET_POST_ROLE = gql`
query PostRoles{
  postRoles(pagination: { pageSize: 1000 },sort:"postRole:asc"){
     data {
      id
      attributes {
        postRole
        isActive
        description
      }
    }
  }
}
`;