import { gql } from "@apollo/client";

export const GET_EMPLOYEER_BY_EMAIL = gql`
  query UsersPermissionsUsers($filters: UsersPermissionsUserFiltersInput) {
    usersPermissionsUsers(filters: $filters) {
      data {
        id
        attributes {
          email
          isResetPassword
        }
      }
    }
  }
`;
export const GET_USER_ROLE = gql`
query getdimension {
  userRoles {
    data {
      id
      attributes {
        role
        description
      }
    }
  }
}

`;

export const GET_COUNTRY_STATE_CITY = gql`
query Data ($pagination: PaginationArg) {
  countries(pagination: $pagination) {
    data {
      id
      attributes {
        name
cities{data{id attributes{name}}}
        states {data {id attributes {name cities { data {id attributes { name}}}}}}}}}}
`;
export const GET_CITIES = gql`
query Data ($pagination: PaginationArg) {
  cities(pagination: $pagination) {
    data {id attributes{name country{data{id attributes{name}}}
    }}}}
`;

export const GET_EDUCATION_FACULTIES = gql`
query EducationFaculties {
  educationFaculties(pagination: { pageSize: 100 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const GET_EDUCATION_COURSES = gql`
query EducationCourses {
  educationCourses(pagination: { pageSize: 100 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}


`;
export const GET_EXP_ENTITIES = gql`
query ExpEntity {
  expEntities(pagination: { pageSize: 300 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const GET_EXPERTISES = gql`
query Expertises {
  expertises(pagination: { pageSize: 300 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const GET_IT_TOOLS = gql`
query itTools {
  itTools(pagination: { pageSize: 300 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const GET_PLATFORMS = gql`
query ProfilePlatforms {
  profilePlatforms(pagination: { pageSize: 300 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

/* 
export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
       name
       rating
email
phone
web
intro
address
postRole{data{id attributes{postRole description}}}
city{data{id attributes{name}}}
country{data{id attributes{name}}}
image{data{id attributes{url}}}
experiences(pagination:{pageSize:20}){data{id attributes{ description startDate endDate postRole{data{id attributes{postRole description}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
educations(pagination:{pageSize:20}){data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
expertise(pagination:{pageSize:20}){data{id attributes{description expertise{data{id attributes{name}}}}}}  
skills(pagination:{pageSize:20}){data{id attributes{percentual itTool{data{id attributes{name}}}}}}
profiles(pagination:{pageSize:20}){data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
awards(pagination:{pageSize:20}){data{id attributes{name description}}} 
portfolios(pagination:{pageSize:20}){data{id attributes{name description image{data{id attributes{url previewUrl}}}}}}
client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
users_permissions_user{data{id attributes{username}}}  
company{data{id attributes{name}}}   
  
      }
    }
  }
}
`;
 */

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      data {
        id
        attributes {
          action
          status
          Company {
            data {
              id
              attributes {
                name
              }
            }
          }
          collaborator {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COLLABORATORS_ALT = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
           speciality
           rating
           
           postRole{data{id attributes{postRole description}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}} 
  experiences(pagination:{pageSize:20}){data{id attributes{ description startDate endDate postRole{data{id attributes{postRole description}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations(pagination:{pageSize:20}){data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise(pagination:{pageSize:20}){data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills(pagination:{pageSize:20}){data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles(pagination:{pageSize:20}){data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards(pagination:{pageSize:20}){data{id attributes{name description}}} 
        portfolios(pagination:{pageSize:20}){data{id attributes{name description image{data{id attributes{url previewUrl}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
        users_permissions_user{data{id attributes{username}}}  
        companies{data{id attributes{name}}}
        employment{data{id attributes{collaborators{data{id attributes{name}}}}}}
        evaluations{
          data{
            id
            attributes{
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }

            }
          }
        }
        isActive 
  }
}
}
}
`;

export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        users_permissions_user{data{id attributes{username confirmed}}} 
        name
        email
        phone
        web
        intro
        address
        rating
        speciality
        evaluations { 
          data {
            id
            attributes {
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }
            }
          }
  }
        postRole { 
          data {
            id
            attributes {
              postRole
              description
            }
          }
        }
        city { 
          data {id  attributes { name } }
        }
        country { 
          data { id attributes { name } }
        }
        image { 
          data { attributes { url } }
        }
        experiences(pagination:{pageSize:20}) { 
          data { 
            attributes { 
              description
              startDate
              endDate
              postRole { 
                data { attributes { postRole description } }
              }
              expEntity { 
                data { attributes { name } }
              }
              country { 
                data { attributes { name } }
              }
 }
          }
        }
        educations(pagination:{pageSize:20}) { 
          data { 
            attributes { 
              description
              startDate
              endDate
              course { 
                data { attributes { name } }
              }
              faculty { 
                data { attributes { name } }
              }
              country { 
                data { attributes { name } }
              }
            }
          }
        }
        expertise { 
          data { 
            attributes { 
              description
              expertise { 
                data { attributes { name } }
              }
            }
          }
        }
  skills { 
          data { 
            attributes { 
              percentual
              itTool { 
                data { attributes { name } }
              }
            }
          }
        }
        profiles { 
          data { 
            attributes { 
              urlProfile
              description
              platform { 
                data { 
                  attributes { 
                    name
                    icon { 
                      data { attributes { url } }
                    }
                  }
                }
              }
            }
          }
        }
        awards { 
          data { attributes { name description } }
        }
  portfolios { 
          data { attributes { name description image { data { attributes { url previewUrl } } } } }
        }
        client { 
          data { attributes { companies { data { id attributes { name image { data { attributes { url } } } } } } } }
        }
        users_permissions_user { 
          data { id attributes { username } }
        }
        companies { 
          data { id attributes { name email   users_permissions_user { 
          data { id attributes { username } }
        }} }
        }
        employment{data{id attributes{collaborators{data{id attributes{name}}}}}}
        isActive 
      }
}
}
}
`;

/* 
export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        speciality
        rating
        evaluation{data{id attributes{star Comment}}}
        employment{data{id attributes{startDate endDate}}}
        evaluation_periods{data{id attributes{startDate endDate}}}
        postRole{data{id attributes{postRole description}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
        experiences(pagination:{pageSize:20}){data{id attributes{ description startDate endDate postRole{data{id attributes{postRole description}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations(pagination:{pageSize:20}){data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise(pagination:{pageSize:20}){data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills(pagination:{pageSize:20}){data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles(pagination:{pageSize:20}){data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards(pagination:{pageSize:20}){data{id attributes{name description}}} 
        portfolios(pagination:{pageSize:20}){data{id attributes{name description image{data{id attributes{url previewUrl}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
        users_permissions_user{data{id attributes{username}}}  
        companies{data{id attributes{name}}}
        isActive   
      }
    }
  }
}
`;  */
export const GET_COMPANIES = gql`
query Companies($pagination: PaginationArg) {
  companies(pagination: $pagination)  {
    data {
      id
      attributes {
        name
        image{data{id attributes{url}}}
        
      }
    }
  }
}
`;
export const GET_POSTROLE = gql`
query Data ($pagination: PaginationArg) {
  postRoles(pagination: $pagination) {
    data {id attributes{postRole description}}}}
`;

export const GET_COLLABORATOR_COMPANIES = gql`
  query GetCollaboratorCompanies($id: ID!) {
    collaborator(id: $id) {
      data {
        id
        attributes {
          companies {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_COMP_GET = gql`
  query GetCompany($userId: ID!) { 
    companies(filters: { users_permissions_user: { id: { eq: $userId } } }) { data { id attributes { status name evaluation_periods { data { id attributes { start end isActive collaborators { data { id attributes { name } } } } } } users_permissions_user { data { id attributes { username confirmed } } } city { data { id attributes { name } } } country { data { id attributes { name } } } email phone branchOfActivity image { data { id attributes { url } } } collaborators { data { id attributes { name } } } findDevs { data { id attributes { description company_answers { data { id attributes { name company_question { data { id attributes { name } } } } } } } } } } } } }
`

// 

export const GET_EMPLOYMENT_COLLAB = gql`
  query GetEmploymentCollaborators(
    $filters: EmploymentFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    employments(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
      data {
        id
        attributes {
          position
          startDate
          endDate
          status
          Salary
          companies {
            data {
              id
              attributes {
                name
              }
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
