import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CompanyQuestion from './company-questions.component';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import SideBar from './side-bar.component';
import i18n from '../../translate/i18n';
import { useContext } from 'react';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import UserProfileCard from '../../pages/ProfileCard/user_profile_card';

const drawerWidth = 240;

function TopBar2(props) {
    const { user, logout } = React.useContext(AuthContext);
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar />
            <SideBar />
        </div>
    );

    const container = typeof window !== 'undefined' ? () => window.document.body : undefined;

    const { selectedLanguage } = useContext(LanguageContext);
    const currentLocation = useLocation();
    const menuItems = [
        { pathname: "/question", message: i18n.t('tittles.questao', { lng: selectedLanguage }) },
        { pathname: "/quiz", message: i18n.t('tittles.quiz', { lng: selectedLanguage }) },
        { pathname: "/collaborator/post-role", message: i18n.t('tittles.role', { lng: selectedLanguage }) },
        { pathname: "/dimension", message: i18n.t('tittles.dimension', { lng: selectedLanguage }) },
        { pathname: "/period", message: i18n.t('tittles.priod', { lng: selectedLanguage }) },
        { pathname: "/collaborator", message: i18n.t('tittles.collaborator', { lng: selectedLanguage }) },
        { pathname: "/indicator", message: i18n.t('tittles.indicator', { lng: selectedLanguage }) },
        { pathname: "/companies", message: i18n.t('tittles.company', { lng: selectedLanguage }) },
        { pathname: "/evaluation", message: i18n.t('tittles.evaluation', { lng: selectedLanguage }) },
        { pathname: "/", message: i18n.t('tittles.dashboard', { lng: selectedLanguage }) }, // Adding the "Dashboard" item
    ];
    const menuItem = menuItems.find((item) => item.pathname === currentLocation.pathname);
    const displayMessage = menuItem ? menuItem.message : i18n.t('tittles.dashboard', { lng: selectedLanguage });

    if (user.id)
    return (
        <Box sx={{ display: 'flex', fontSize: "0.625rem" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    bgcolor: "background.paper"
                }}
            >
                <Toolbar>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="black" noWrap component="div" sx={{ flexGrow: 1, fontSize: "1.125rem" }}>
                        {displayMessage}
                    </Typography>
                    <UserProfileCard />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <CompanyQuestion user={user} logout={logout}>
                    <Outlet />
                </CompanyQuestion>
            </Box>
        </Box>
    );
};

export default TopBar2;
