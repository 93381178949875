import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        
      }
    }
  }
`;
export const REGISTER = gql`
mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      email
    }
  }
}
`;

export const ADD_PROSPECT = gql`
mutation CreateProspect($data: ProspectInput!) {
  createProspect(data: $data) {
    data {
      id
      attributes {
        name
        email
      }
    }
  }
}
`;
export const CREATE_COMPANY = gql`
mutation CreateCompany($data: CompanyInput!) {
  createCompany(data: $data) {
    data {
      id
      attributes {
        name
        email
      }
    }
  }
}

`;
export const CREATE_USER = gql`
mutation CreateUser($data: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $data){data{id attributes{username email confirmed }}}
}
`;


export const UPDATE_USER_PASSWORD = gql`
  mutation Mutation(
    $updateUsersPermissionsUserId: ID!
    $data: UsersPermissionsUserInput!
  ) {
    updateUsersPermissionsUser(id: $updateUsersPermissionsUserId, data: $data) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;

export const CREATE_FIND_DEV = gql`
mutation FindDev($data: FindDevInput!) {
  createFindDev(data: $data) {
    data {
      id
      attributes {
        description
        company_answers{data{id attributes{name}}}
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;
export const CREATE_CITY = gql`
mutation createCity($data:CityInput!) {
  createCity(data:$data) {
    data {id attributes {name}}
  }
}

`;
