import { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";

// 1. Definição do Estado Inicial
const initialState = {
  user: null,
  id: null,
};

// 2. Verificação do Token JWT no Local Storage
if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));
  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.user = decodedToken;
    initialState.id = decodedToken.id;
  }
}

// 3. Criação do Contexto de Autenticação
const AuthContext = createContext({
  user: null,
  id: null,
  login: (userData) => {},
  logout: () => {},
});

// 4. Redutor de Autenticação
function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        id: action.payload.id,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        id: null,
      };
    default:
      return state;
  }
}

// 5. Provedor de Autenticação
function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // Função de Login
  function login(userData) {
    console.log("userData aqui", userData);

   
    
    localStorage.setItem("jwtToken", userData.jwt);
    dispatch({
      type: "LOGIN",
      payload: userData, // ou `decodedToken` se preferir
    });
  }

  // Função de Logout
  function logout() {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT" });
  }

  return (
    <AuthContext.Provider
      value={{ user: state.user, id: state.id, login, logout }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
