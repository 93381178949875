import { gql } from "@apollo/client";

export const CREATE_POST_ROLE = gql`
mutation Mutation($data: PostRoleInput!) {
    createPostRole(data: $data) {
      data {
        id
        attributes {
          postRole
          description
          isActive
        }
      }
    }
  }
`;

export const UPDATE_POST_ROLE = gql`
mutation UpdatePostRole($updatePostRoleId: ID!, $data: PostRoleInput!) {
  updatePostRole(id: $updatePostRoleId, data: $data) {
    data {
      id
      attributes {
        postRole
        description
        isActive
      }
    }
  }
}
`;

export const DELETE_POST_ROLE= gql`
mutation deletePostRole($id: ID!) {
  deletePostRole(id: $id) {
    data {
      id
      attributes {
        postRole
      }
    }
  }
}
`;
