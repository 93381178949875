import EnergySavingsLeaf from "@mui/icons-material/EnergySavingsLeaf";
import GitHub from "@mui/icons-material/GitHub";
import DensityMedium from "@mui/icons-material/DensityMedium";
import DesignServices from "@mui/icons-material/DesignServices";
import Photo from "@mui/icons-material/Photo";
import ArtTrack from "@mui/icons-material/ArtTrack";
import css from "../css/profiles.module.css";
import { Section } from "../section";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
const iconAdd = <IconButton color="primary"><PlusOneIcon /></IconButton>
const iconDel = <IconButton color="primary"><ClearOutlinedIcon /></IconButton>
const iconEdit = <IconButton color="primary"><EditOutlinedIcon /></IconButton>
const { REACT_APP_API_URL } = process.env;
const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>   
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Profile");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}PROFILES</h2>
      <p>Busy as usual.</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Profile");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ handleOpen, setStepID, profiles, colaboradorEdit }) => {


  return (
    <>{colaboradorEdit === true ? (
      <>
        <ul className={css.list}>
          {profiles.map((profile) => (
            <li key={profile.id}>
              <a href={profile.urlProfile} className={css.profile}>
                <span className={css.icon}>
                  {profile.icon ? (
                    <img
                      src={`${REACT_APP_API_URL}${profile.icon}`}
                      alt="avatar"
                      style={{ width: '24px', height: '24px' }}
                    />
                  ) : (
                    <ArtTrack style={{ width: '24px', height: '24px' }} />
                  )}
                </span>
                <h3 className={css.name}>{profile.platform}</h3>
                <p className={css.description}>{profile.description}</p>
              </a>
              <IconButton color="primary" onClick={() => {
                  handleOpen("Edit Profile");
                  setStepID(profile.id);
                }}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Delete Profile");
                  setStepID(profile.id);
                }}>
                  <ClearOutlinedIcon />
                </IconButton>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <>
        <ul className={css.list}>
          {profiles.map((profile) => (
            <li key={profile.id}>
              <a href={profile.urlProfile} className={css.profile}>
                <span className={css.icon}>
                  {profile.icon ? (
                    <img
                      src={`${REACT_APP_API_URL}${profile.icon}`}
                      alt="avatar"
                      style={{ width: '24px', height: '24px' }}
                    />
                  ) : (
                    <ArtTrack style={{ width: '24px', height: '24px' }} />
                  )}
                </span>
                <h3 className={css.name}>{profile.platform}</h3>
                <p className={css.description}>{profile.description}</p>
              </a>
            </li>
          ))}
        </ul>
      </>
    )}</>


  );
};

export const Profiles = ({ handleOpen, setStepID, profiles, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit}  />} content={<Content handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} profiles={profiles} />} />;
};
