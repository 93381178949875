import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Pagination } from '@mui/material';
import QuizFormTeste from './quiz-form.component';
import { GET_COMPANY_QUESTIONS } from './query.gql';
import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import QuizDelete from './delete-quiz.component';
import { useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPagination from '../../components/TableComponents/CustomPagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

export default function QuizList({ quizzes, setQuizzes }) {


    const [currentPage, setCurrentPage] = useState(1);
    const [sortedPage, setSortedPage] = useState(quizzes);
    const itemsPerPage = 10;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = sortedPage.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(sortedPage.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        setCurrentPage(1);
        setSortedPage(quizzes)
    }, [quizzes]);


    const [open, setOpen] = useState(false);

    const [quiz, setQuiz] = useState({
        id: '',
        quiz: '',
        isActive: '',
    })

    const currentLocation = useLocation()

    const [title, setTitle] = useState("Nova Pergunta")

    function LongMenu({ actionID }) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        function handleClickOpenView(quizID) {
            setOpen(true);
            setTitle("Ver Pergunta")
            let data = quizzes.find(d => d.id === quizID)
            setQuiz(i => {
                return {
                    id: data.id,
                    quiz: data.attributes.name,
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickOpenEdit(quizID) {
            setOpen(true);
            setTitle("Editar Pergunta")
            let data = quizzes.find(d => d.id === quizID)
            setQuiz(i => {
                return {
                    id: data.id,
                    quiz: data.attributes.name,
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickDelete(quizID) {
            setTitle("Confirmar operação")
            setOpen(true)
            let data = quizzes.find(d => d.id === quizID)
            setQuiz(i => {
                return {
                    id: data.id,
                    quiz: data.attributes.name,
                    isActive: data.attributes.isActive
                }
            })
        }

        return (
            <>
                <IconButton aria-label="more" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickOpenView(actionID)}>
                        <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> View
                    </MenuItem>
                    <MenuItem onClick={() => handleClickOpenEdit(actionID)}>
                        <EditOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleClickDelete(actionID)}>
                        <ClearOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> Delete
                    </MenuItem>

                </Menu>
            </>
        );
    }

    function handleClose() {
        setOpen(false);
    };

    return (
        <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Perguntas</StyledTableCell>
                            <StyledTableCell>Estado</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.attributes.name}
                                </StyledTableCell>
                                <StyledTableCell>{row.attributes.isActive === true ? "Activo" : "Inativo"}</StyledTableCell>
                                <StyledTableCell>
                                    <div style={{ marginRight: "35px" }}>
                                        <LongMenu actionID={row.id} />
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />

            <Grid /* fullWidth */>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='md'
                    sx={{ border: 2, borderColor: "#F7931E" }}
                >
                    <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                        >
                            {title === "Confirmar operação" ? <QuizDelete handleClose={handleClose} quiz={quiz} setList={setQuizzes} list={quizzes} /> :
                                currentLocation.pathname === '/quiz' ? <QuizFormTeste quiz={quiz} handleClose={handleClose} titleAction={title} /> : ''}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Paper>
    );
}
