import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
mutation CreateCompany($data: CompanyInput!) {
  createCompany(data: $data) {
    data {
      id
      attributes {
        name
        email
        image{data{id attributes{url}}}
           }
    }
  }
}

`;
export const UPDATE_COMPANY = gql`
mutation updateCompany($id: ID!, $data: CompanyInput!) {
 updateCompany(id: $id, data: $data) {
   data {
     id
     attributes {
       name
       status
       image{data{id attributes{url}}}
     }
   }
 }
}

`;
export const DELETE_COMPANY = gql`
mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    data {
      id
      attributes {
        name
        status
      }
    }
  }
}
`;

export const CREATE_CITY=gql`
mutation createCity($data: CityInput!) {
  createCity(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`
  
 
export const FIND_DEVS=gql`
mutation FindDev($data: FindDevInput!) {
  createFindDev(data: $data) {
    data {
      id
      attributes {
        description
        company_answers{data{id attributes{name}}}
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}`

export const CREATE_MEDIA_FILE = gql`
mutation uploadFile(
  $file: Upload!
  $info: FileInfoInput!
  $refId: ID!
  $field: String!
  $ref: String!
) {
  upload(file: $file, refId: $refId, field: $field, ref: $ref, info: $info) {
    data {
      id
      attributes {
        name
        url
      }
    }
  }
}

`; 
 