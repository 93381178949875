import { Alert, AlertTitle, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react'
import { CREATE_ANSWER, UPDATE_QUESTION_CLOSE, UPDATE_QUESTION_OPEN, CREATE_QUESTION_CLOSE, CREATE_QUESTION_OPEN, UPDATE_ANSWER_CLOSED, DELETE_ANSWER_CLOSE } from './mutation.gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_INDICATORS } from '../IndicatorPage/query.gql';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

 
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


const QuestionFormTeste = ({ question, titleAction, handleClose, setData }) => {

    const jwt = localStorage.getItem("jwtToken")
    const [questions, setQuestions] = useState([])
    const [title, setTitle] = useState("Guardar")
    const [openAlertOK, setOpenAlertOK] = useState(false)

    const [formData, setFormData] = useState({
        question: question.question,
        type: question.type,
        indicator: question.indicator,
        answer: question.answer,
        rate: question.rate
    });

    const [createQuestionOpen] = useMutation(CREATE_QUESTION_OPEN);
    const [createQuestionClose] = useMutation(CREATE_QUESTION_CLOSE);
    const [createAnswerClose] = useMutation(CREATE_ANSWER);
    const [updateQuestionClose] = useMutation(UPDATE_QUESTION_CLOSE);
    const [updateQuestionOpen] = useMutation(UPDATE_QUESTION_OPEN);
    const [updateAnswerClose] = useMutation(UPDATE_ANSWER_CLOSED);
    const [deleteEvaluationAnswerClosed] = useMutation(DELETE_ANSWER_CLOSE);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.type === 'Open') {
            if (question.id === undefined) {
                createQuestionOpen({
                    variables: {
                        data: {
                            question: formData.question,
                            type: formData.type,
                            indicator: formData.indicator
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then((response) => {
                    console.log(response.data.createEvaluationQuestionOpen.data);
                    setOpenAlertOK(true);
                    const newQuestion = response.data.createEvaluationQuestionOpen.data;
                    setData((prevQuestions) => [newQuestion, ...prevQuestions]);
                    handleClose();
                }).catch(error => {
                    console.log(error)
                })
            } else {
                updateQuestionOpen({
                    variables: {
                        id: question.id,
                        data: {
                            question: formData.question,
                            indicator: formData.indicator,
                        }
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then(data => {

                    if (formData.question !== question.question ||
                        formData.type !== question.type ||
                        formData.indicator !== question.indicator) {
                        let obj = questions.filter(i => i.id === question.id)
                        obj.push(data.data.updateEvaluationQuestionOpen.data)
                        setQuestions(obj);
                        setOpenAlertOK(true)
                        handleClose();
                    }
                }).catch(error2 => {
                    console.log(error2)
                })
            }
        }
        else
            if (question.id === undefined) {
                createQuestionClose({
                    variables: {
                        data: {
                            question: formData.question,
                            type: formData.type,
                            indicator: formData.indicator,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then((response) => {
                    console.log(response.data.createEvaluationQuestionClosed.data);
                    setOpenAlertOK(true);
                    const newQuestion = response.data.createEvaluationQuestionClosed.data;
                    setData((prevQuestions) => [newQuestion, ...prevQuestions]);
                    handleClose();
                }).catch(error => {
                    console.log(error)
                })
            } else {
                updateQuestionClose({
                    variables: {
                        id: question.id,
                        data: {
                            question: formData.question,
                            indicator: formData.indicator,
                        }
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then(data => {

                    if (formData.question !== question.question ||
                        formData.type !== question.type ||
                        formData.indicator !== question.indicator ||
                        formData.rate !== question.rate ||
                        formData.answer !== question.answer) {
                        let obj = questions.filter(i => i.id === question.id)
                        obj.push(data.data.updateEvaluationQuestionClosed.data)
                        setQuestions(obj);
                        setOpenAlertOK(true)
                        handleClose();
                    }
                }).catch(error2 => {
                    console.log(error2)
                })
            }

    };

    const [answerArray, setAnswerArray] = useState(question?.answer?.data || []);

    const addAnswer = (newAnswer) => {
        setAnswerArray(prevArray => [...prevArray, newAnswer]);
    };

    const [indicators, setIndicators] = useState([])
    useEffect(() => {
        getIndicators({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            fetchPolicy: 'network-only'
        }).then(data => {

            setIndicators(data.data.indicators.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const [getIndicators, { loading3, data3 }] = useLazyQuery(GET_INDICATORS, {
        context: {
            headers: {
                authorization: `Bearer ${jwt}`,
            },
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        getIndicators();
    }, []);

    useEffect(() => {
        if (data3) {
            const { indicators } = data3;
            setIndicators(indicators.data);
        }
    }, [data3]);

    const handleClickAlert = () => {
        setOpenAlertOK(true);
    };

    const handleCloseAlert = () => {
        setOpenAlertOK(false);
    };

    const handleCreateAnswer = (e) => {
        e.preventDefault();
        const rateValue = Number(formData.rate);
        const isValidRate = Number.isInteger(rateValue) || Number.isNaN(rateValue);

        if (!isValidRate) {
            toast.error("A taxa de resposta deve ser um número inteiro.");
            return;
        }

        createAnswerClose({
            variables: {
                data: {
                    answer: formData.answer,
                    rate: Math.floor(rateValue),
                    evaluation_question_closed: question.id
                },
            },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
        .then((response) => {
            console.log(response.data.createEvaluationAnswerClosed.data);
            setOpenAlertOK(true);
            const newAnswer = response.data.createEvaluationAnswerClosed.data;
            setData((prevAnswer) => [newAnswer, ...prevAnswer]);
            setAnswerArray((prevArray) => [...prevArray, newAnswer]);
            handleClose();
        })
        .catch(error => {
            console.log(error);
        });
    };

    const handleAnswerChange = (index, e) => {
        const { value } = e.target;
        const updatedAnswers = [...formData.answer];
        updatedAnswers[index] = value;
        setFormData((prevState) => ({
            ...prevState,
            answer: updatedAnswers,
        }));
    };

    function ChipsArray() {
        const [chipData, setChipData] = React.useState([answerArray]);

        const handleDelete = (chipToDelete) => () => {
            const updatedAnswers = answerArray.filter((answer) => answer.key !== chipToDelete.key);
            setAnswerArray(updatedAnswers);
        };

        const handleDeleteAnswer = (event, chipData) => {
            event.preventDefault()
            deleteEvaluationAnswerClosed({
                variables: { id: chipData.id },
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
            })
                .then((response) => {
                    console.log(response.data.deleteEvaluationAnswerClosed.data);
                    const updatedAnswers = answerArray.filter((answer) => answer.id !== chipData.id);
                    setAnswerArray(updatedAnswers);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        return (
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 1.5,
                    m: 0,
                }}
                component="ul"
            >
                {answerArray && answerArray.map((data) => {

                    return (
                        <ListItem key={data.key}>
                            <Chip
                                disabled={titleAction === "Ver Questão"}
                                label={titleAction === "Ver Questão" ? `${data?.attributes?.answer} ------------------ ${data?.attributes?.rate}` :
                                    titleAction === "Editar Questão" ? `${data?.attributes?.answer} ------------------ ${data?.attributes?.rate}` : ''}
                                onDelete={(event) => handleDeleteAnswer(event, data)}
                            />
                        </ListItem>
                    );
                })}
            </Grid>
        );
    }
  
    
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={2} margin={1} marginLeft={3}>
                    <InputLabel required id="demo-simple-select-label">Perguntas</InputLabel>
                    <TextField required id="filled-basic" variant="outlined" fullWidth
                        disabled={titleAction === "Ver Questão"}
                        defaultValue={titleAction === "Ver Questão" ? question.question :
                            titleAction === "Editar Questão" ? question.question : formData.question}
                        onChange={(e) =>
                            setFormData({ ...formData, question: e.target.value })
                        } />
                </Grid>
                <Grid container spacing={2} margin={1}>
                    <Grid item xs={6}>
                        <InputLabel required id="demo-simple-select-label">Tipo de Pergunta</InputLabel>
                        <FormControl required fullWidth size='small'>
                            <Select
                                disabled={titleAction === "Ver Questão"}
                                value={formData.type}
                                onChange={(e) =>
                                    setFormData({ ...formData, type: e.target.value })
                                }
                            >
                                <MenuItem value={"Open"}>Aberta</MenuItem>
                                <MenuItem value={"Closed"}>Fechada</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel required id="demo-simple-select-label">Indicador</InputLabel>
                        <FormControl required fullWidth size='small'>
                            <Select
                                disabled={titleAction === "Ver Questão"}
                                defaultValue={titleAction === "Ver Questão" ? question?.indicator || "Não Definido" :
                                    titleAction === "Editar Questão" ? question?.indicator || "Não Definido" : formData.indicator}
                                onChange={(e) =>
                                    setFormData({ ...formData, indicator: e.target.value })
                                }
                            >
                                {
                                    indicators.map(indicator => (
                                        <MenuItem key={indicator.id} value={indicator.id}>
                                            {indicator.attributes.indicator}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} margin={1}>   
                { formData.type !== undefined && formData.type !== "Open" && (
        <Grid item xs={6}>
            <InputLabel id="demo-simple-select-label">Respostas</InputLabel>

            <TextField

                id="outlined-basic"
                variant="outlined"
                fullWidth
                disabled={titleAction === "Ver Questão"}
                defaultValue={titleAction === "Ver Questão" ? question?.answer?.data?.attributes?.answer :
                    titleAction === "Editar Questão" ? question?.answer?.data?.attributes?.answer : formData.answer}
                onChange={(e) =>
                    setFormData({ ...formData, answer: e.target.value })
                }
            />
        </Grid>
    )}
    { formData.type !== undefined && formData.type !== "Open" && (
        <Grid item xs={6} container spacing={2} marginLeft={-4} justifyContent="center" alignItems="center">
            <Grid item xs={4}>
                <InputLabel id="demo-simple-select-label">Pontuação</InputLabel>

                <TextField

                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: '7rem' }}
                    fullWidth
                    disabled={titleAction === "Ver Questão"}
                    defaultValue={titleAction === "Ver Questão" ? question?.answer?.data?.attributes?.rate :
                        titleAction === "Editar Questão" ? question?.answer?.data?.attributes?.rate : formData.rate}
                    onChange={(e) =>
                        setFormData({ ...formData, rate: e.target.value })
                    }
                />
            </Grid>
            <Grid item xs={8} marginTop={2.8} marginLeft={-5} display="flex" justifyContent="left">
                <Button
                    variant="text"
                    sx={{ color: "grey" }}
                    size="large"
                    disabled={titleAction === "Ver Questão" || formData.type === "Open"}
                    onClick={handleCreateAnswer}
                >
                    <AddIcon color="primary" />
                </Button>
            </Grid>
        </Grid>
    )}
  {formData.type !== undefined &&formData.type !== "Open" && (
        <div>
            <ChipsArray />
        </div>
    )}  

   </Grid> 

                <Grid container spacing={2} margin={1}>

                    {titleAction === "Editar Questão" && formData.type !== "Open" && (
                        <Grid item xs={6}>
                            <InputLabel id="demo-simple-select-label">Respostas</InputLabel>

                            <TextField

                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                disabled={titleAction === "Ver Questão"}
                                defaultValue={titleAction === "Ver Questão" ? question?.answer?.data?.attributes?.answer :
                                    titleAction === "Editar Questão" ? question?.answer?.data?.attributes?.answer : formData.answer}
                                onChange={(e) =>
                                    setFormData({ ...formData, answer: e.target.value })
                                }
                            />
                        </Grid>
                    )}
                    {titleAction === "Editar Questão" && formData.type !== "Open" && (
                        <Grid item xs={6} container spacing={2} marginLeft={-4} justifyContent="center" alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Pontuação</InputLabel>

                                <TextField

                                    id="outlined-basic"
                                    variant="outlined"
                                    sx={{ width: '7rem' }}
                                    fullWidth
                                    disabled={titleAction === "Ver Questão"}
                                    defaultValue={titleAction === "Ver Questão" ? question?.answer?.data?.attributes?.rate :
                                        titleAction === "Editar Questão" ? question?.answer?.data?.attributes?.rate : formData.rate}
                                    onChange={(e) =>
                                        setFormData({ ...formData, rate: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={8} marginTop={2.8} marginLeft={-5} display="flex" justifyContent="left">
                                <Button
                                    variant="text"
                                    sx={{ color: "grey" }}
                                    size="large"
                                    disabled={titleAction === "Ver Questão" || formData.type === "Open"}
                                    onClick={handleCreateAnswer}
                                >
                                    <AddIcon color="primary" />
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {formData.type !== "Open" && (
                        <div>
                            <ChipsArray />
                        </div>
                    )}
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="right" alignItems="center" marginTop={10}>
                    <Grid item xs={2} marginRight={-2}>
                        <Button variant="outlined" size="medium" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            disabled={titleAction === "Ver Questão"}
                            type="submit"
                            variant="contained"
                            size="medium"
                            onClick={handleClickAlert}
                        >
                            {title}
                        </Button>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={openAlertOK}
                            autoHideDuration={5000}
                            onClose={handleCloseAlert}
                        >
                            <Alert severity="success">
                                <AlertTitle>Successo</AlertTitle>
                                {titleAction === "Nova Questão" ? "Questão adicionado" : "Questão atualizado"}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default QuestionFormTeste;