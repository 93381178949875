import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SearchAddBar from "../../components/TopBar/search-add-bar.component";
import QuestionListTest from "./question-list-teste.component";
import { useLazyQuery } from "@apollo/client";
import { GET_EvaluationQuestion_Closed, GET_EvaluationQuestion_Opened } from "./query.gql";
import Pathway from "../../components/TopBar/pathway";

const QuestionPage = () => {
  const jwt = localStorage.getItem("jwtToken");
  const [typeOpenedData, setTypeOpenedData] = useState([]);
  const [typeClosedData, setTypeClosedData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [filteredList, setFilteredList] = useState("");

  const [getTypeOpen, { loading: loading1, data: data1 }] = useLazyQuery(
    GET_EvaluationQuestion_Opened,
    {
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getTypeOpen();
  }, []);

  useEffect(() => {
    if (data1) {
      const { evaluationQuestionOpens } = data1;
      setTypeOpenedData(evaluationQuestionOpens.data);
      setQuestions((prevQuestions) => {
        const mergedQuestions = [...evaluationQuestionOpens.data, ...prevQuestions];
        return Array.from(new Set(mergedQuestions.map(q => q.id)))
          .map(id => mergedQuestions.find(q => q.id === id));
      });
    }
  }, [data1]);

  const [getTypeClose, { loading: loading2, data: data2 }] = useLazyQuery(
    GET_EvaluationQuestion_Closed,
    {
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getTypeClose();
  }, []);

  useEffect(() => {
    if (data2) {
      const { evaluationQuestionCloseds } = data2;
      setTypeClosedData(evaluationQuestionCloseds.data);
      setQuestions((prevQuestions) => {
        const mergedQuestions = [...evaluationQuestionCloseds.data, ...prevQuestions];
        return Array.from(new Set(mergedQuestions.map(q => q.id)))
          .map(id => mergedQuestions.find(q => q.id === id));
      });
    }
  }, [data2]);

  const filteredSearched = questions.filter((item) =>
    item.attributes.question.toLowerCase().includes(filteredList.toLowerCase())
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Pathway/>
        <SearchAddBar
          searched={filteredList}
          setSearched={setFilteredList}
          setData={setQuestions}
        />
        <QuestionListTest
          questions={filteredSearched}
          setQuestions={setQuestions}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionPage;
