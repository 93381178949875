import {
  Avatar,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { LoginContext } from "../../contexts/login-context";
import { useMutation } from "@apollo/client";
import { ADD_PROSPECT } from "./mutation.gql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/auth-context";
import { ToastContainer, toast } from "react-toastify";

const validationEmailForm = yup
  .object({
    name: yup
      .string()
      .min(3, "The name need to have more than 3 caracters")
      .required("The Name is required"),
    branchOfActivity: yup
      .string()
      .min(2, "There's no activity named with1 caracter")
      .required("The branch of activity is required"),
    email: yup
      .string()
      .email("Insert a valid email")
      .required("The email is required"),
    phone: yup
      .string()
      .min(7, "The Phone need to have more than 7 numbers")
      .required("The Phone is required"),
  })
  .required();

const Register = () => {
  let navigate = useNavigate(); 
  const { loginData, setLoginData } = useContext(LoginContext);
  const context = useContext(AuthContext);

  const [register_prospect] = useMutation(ADD_PROSPECT);

  /*
  useEffect(() => {
    if (loginData.email === "" || loginData.isResetPassword === true)
      navigate("/login");
  }, [loginData]);
* */

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });

  const toHome = () => navigate("/");
  const registerForm = (dataForm) => {
    register_prospect({
      variables: {
        data: {
          name: dataForm.name,
          email: dataForm.email,
          //identifier: loginData.email,
          branchOfActivity: dataForm.branchOfActivity,
          phone:dataForm.phone
        },
      },
    })
      .then((data) => {
        if(data){
          toast.success("Registered!!");
          toHome();}
      })
      .catch((error) => {
        toast.error("Not Registered!!");
      });
  };

  return (
    <form onSubmit={handleSubmit(registerForm)} noValidate>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{ marginBottom: "10px" }}
        >
          <Typography
              variant="h3"
            >
              Create an account as company
            </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoFocus
            label="Nome"
            type="name"
            defaultValue=""
            fullWidth
            name="name"
            id="name"
            {...register("name")}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoFocus
            label="Branch Of Activity"
            type="text"
            defaultValue=""
            fullWidth
            name="branchOfActivity"
            id="branchOfActivity"
            {...register("branchOfActivity")}
            helperText={errors.branchOfActivity?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoFocus
            label="Email"
            type="email"
            defaultValue=""
            fullWidth
            name="email"
            id="email"
            {...register("email")}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoFocus
            label="Phone"
            type="number"
            defaultValue=""
            fullWidth
            name="phone"
            id="phone"
            {...register("phone")}
            helperText={errors.phone?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" size="medium" variant="contained" fullWidth>
            Registrar
          </Button>
          <ToastContainer />
        </Grid>
        <Grid item xs={12} sx={{display:"flex"}}>
        <Typography sx={{p:2}} variant="body2" children="Already have an account?" />
          <Button type="button" size="small" variant="text" onClick={toHome}>
            Login.
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Register;
