import { IconButton, Rating } from "@mui/material";
import css from "../css/clients.module.css";
import { Section } from "../section";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useState } from "react";
const { REACT_APP_API_URL } = process.env;
const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Client");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}Clients</h2>
      <p>Happy people.</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Client");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ handleOpen, setStepID, clients, colaboradorEdit }) => {
  const ClientScore = ({ value,size }) => {
    if (value === 0) {
      return null; // If the value is 0, don't render anything
    }
  
    return (
      <Rating
        value={value}
        precision={0.5}
        readOnly
        size={size}
      />
    );
  };
  return (
    <ul className={css.list}>
      
        <>
          {clients?.map((client) => (
            <li key={client.id} className={css.client}>
              <a className={css.link}>
                <img
                  src={`${REACT_APP_API_URL}${client.image}`}
                  alt={client.name}
                  style={{ width: '80px', height: '80px' }}
                  className={css.logo}
                  title={client.name}
                />
              </a>

              <br />
              <ClientScore value={client.rateTotal / client.rateLength || 0} size={"small"}/><br/>
              {colaboradorEdit === true && <IconButton color="primary" onClick={() => {
                handleOpen("Delete Client");
                setStepID(client.id);
              }}>
                <ClearOutlinedIcon />
              </IconButton>}

            </li>
          ))}
        </>
     

    </ul>
  );
};

export const Clients = ({ handleOpen, setStepID, clients, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} clients={clients} colaboradorEdit={colaboradorEdit} />} />;
};
