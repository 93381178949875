import css from "../css/clients.module.css";
import { Section } from "../section";

const Header = () => {
  return (
    <>
      <h2>Clients</h2>
      <p>Happy people.</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      name: "bbc",
      url: "example.com",
    },
    {
      id: 2,
      name: "disney",
      url: "example.com",
    },
    {
      id: 3,
      name: "github",
      url: "example.com",
    },
    {
      id: 4,
      name: "google",
      url: "example.com",
    },
    {
      id: 5,
      name: "html5",
      url: "example.com",
    },
    {
      id: 6,
      name: "kickstarter",
      url: "example.com",
    },
    {
      id: 7,
      name: "youtube",
      url: "example.com",
    },
    {
      id: 8,
      name: "vimeo",
      url: "example.com",
    },
  ];

  return (
    <ul className={css.list}>
      {data.map((client) => (
        <li key={client.id} className={css.client}>
          <a href={`https://${client.url}`} className={css.link}>
            <img
              src={`/clients/${client.name}.png`}
              alt={client.name}
              className={css.logo}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export const Clients = () => {
  return <Section header={<Header />} content={<Content />} />;
};
