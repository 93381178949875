import { Section } from "../section";
import css from "../css/hero.module.css";
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
const { REACT_APP_API_URL } = process.env;

const Header = ({ hero }) => {
  const imageUrl = hero?.avatar ? `${REACT_APP_API_URL}${hero?.avatar}` : '/avatar.jpg'

  return (
    <div className={css.hero}>
      <div className={css.avatar}>
        <img src={imageUrl} alt="avatar" />
      </div>
    </div>
  );
};

const Content = ({ handleOpen, setStepID, hero, }) => {
  const scores = hero?.evaluations; // Aqui você já tem o array de avaliações

  const [pile, setPile] = useState(0);
  
  useEffect(() => {
    if (!scores || scores.length === 0) {
      setPile(0); // Se não houver avaliações, define como 0
      return;
    }
  
    // Calcula a soma das avaliações
    const totalScore = scores.reduce((sum, evaluation) => sum + evaluation.star, 0); // Soma de todas as classificações
    const totalRatings = scores.length; // Número total de avaliações
  
    // Calcula a média das avaliações
    const averageRating = totalRatings > 0 ? totalScore / totalRatings : 0;
  
    setPile(averageRating); // Define a média no estado
  
  }, [scores]); // O efeito depende de 'scores', assim ele será recalculado quando as avaliações mudarem
  


  const ClientScore = ({ value, size }) => {
    return (
      <Rating
        value={value}
        precision={0.5}
        readOnly
        size={size}
    
      />
    );
  };

  const theHero = hero
  const heroName = hero.name;
  let firstName = heroName?.split(" ")[0]

  return (
    <div className={css.content}>
      <div className={css.name}>
        <span className={css.firstName}>{firstName} </span>

      </div>
      <div className={css.socials}>
        <span className={css.subtitle}>
          <BusinessCenterOutlinedIcon /> {theHero?.postRoleDescription}<br />
          {theHero?.companies?.map((historyRow, index) =>
            <div key={historyRow.companyID}>
              <span>
                {historyRow?.company || 'N/A'}
              </span>
            </div>
          )}
          <BusinessRoundedIcon /> {theHero?.postRoleDescription} <br />
          <ClientScore value={Number(pile) || 0} size={"large"} />

        {/*   <br /> */}

        </span>


      </div>

      <ul className={css.contacts}>
        <li>
          <h4>Location</h4>
          <span> {theHero?.address} <br /> {theHero?.city} , {theHero?.country}</span>
        </li>
        <li>
          <h4>Phone</h4>
          <span>{theHero?.phone}</span>
        </li>
        <li>
          <a href={theHero?.web}>
            <h4>Web</h4>
            <span>{theHero?.web || "N/A"}</span>
          </a>
        </li>
        <li>
          <a href={`mailto:${theHero?.email}`}>
            <h4>Email</h4>
            <span>{theHero?.email}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Hero = ({ handleOpen, setStepID, collaborator, profiles, colaboradorEdit }) => {
  return <Section
    header={<Header hero={collaborator} colaboradorEdit={colaboradorEdit} />}
    content={<Content handleOpen={handleOpen} setStepID={setStepID} profiles={profiles} hero={collaborator} colaboradorEdit={colaboradorEdit} />} hero={true} />;
};
