import css from "./css/section.module.css";

export const Section = ({ header, content, hero = false }) => {
  return (
    <div className={css.section}>
      <div className={css.header} hero={hero ? "true" : null}>
      {/*   {header} */}
      </div>
      <div className={css.content}>{content}</div>
    </div>
  );
};
