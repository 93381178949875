import css from "./item.module.css";

export const ListItem = ({ item, key }) => {
  return (
    <li key={key}>
      <a href="/curriculum/details" className={css.item}>
        <div className={css.picture}>
          <img src={item.picture} alt={item.name} />
        </div>
        <div className={css.info}>
          <h3>{item.name}</h3>
          <p>{item.title}</p>
          <span>{item.address}</span>
          <div className={css.separator}>
            <hr />
          </div>
        </div>
      </a>
    </li>
  );
};
