import { Section } from "../section";
import css from "../css/experience.module.css";

const Header = () => {
  return (
    <>
      <h2>Experience</h2>
      <p>Yes. I've been around.</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      start: 2015,
      end: "Present",
      name: "UNICORN INCUBATOR INC.",
      role: "Senior Interface Designer",
      address: "Portland, OR",
      description:
        "Consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti.",
    },
    {
      id: 2,
      start: 2013,
      end: 2015,
      name: "CALIFORNIA DESIGN BUREAU",
      role: "Creative Director",
      address: "Cupertino, CA",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis.",
    },
    {
      id: 3,
      start: 2010,
      end: 2013,
      name: "DREAMLAND CREATIVE",
      role: "Front-end Designer",
      address: "New York, NY",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh.",
    },
  ];

  return (
    <ul className={css.list}>
      {data.map((exp) => (
        <li key={exp.id} className={css.experience}>
          <div className={css.marker}></div>
          <div className={css.wrapper}>
            <div className={css.intro}>
              <p className={css.date}>
                {exp.start} - {exp.end}
              </p>
              <h3>{exp.name}</h3>
              <p className={css.role}>{exp.role}</p>
              <p className={css.address}>{exp.address}</p>
            </div>
            <p className={css.description}>{exp.description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const Experience = () => {
  return <Section header={<Header />} content={<Content />} />;
};
