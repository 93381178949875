import { gql } from "@apollo/client";

export const CREATE_COMPANY_ANSWER = gql `
mutation CreateCompanyAnswer($data: CompanyAnswerInput!) {
    createCompanyAnswer(data: $data) {
      data {
        id
      }
    }
  }
  `;
export const UPDATE_COMPANY_ANSWER = gql `
mutation UpdateCompanyAnswer($id: ID!, $data: CompanyAnswerInput!) {
  updateCompanyAnswer(id: $id, data: $data) {
    data {
      id
      attributes {
        name
        findDevs {
          data {
            id
            attributes {
              description
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}

  `;
  export const DELETE_COMPANY_ANSWER = gql `
  mutation DeleteCompanyAnswers($id: ID!) {
    deleteCompanyAnswer(id: $id) {
      data {
        id
        attributes {
          name
          findDevs {
            data {
              id
              attributes {
                description
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
  
   `;

   
export const UPDATE_COMPANY_QUESTION = gql `
mutation UpdateCompanyQuestion($id: ID!, $data: CompanyQuestionInput!) {
  updateCompanyQuestion(id: $id, data: $data) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}
 `;

export const CREATE_COMPANY_QUESTION = gql `
mutation CreateCompanyQuestion($data: CompanyQuestionInput!) {
  createCompanyQuestion(data: $data) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}

 `;

export const DELETE_COMPANY_QUESTION = gql `
mutation DeleteCompanyQuestion($id: ID!) {
  deleteCompanyQuestion(id: $id) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}


 `;

