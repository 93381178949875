import css from "./css/section.module.css";

export const Section = ({ header, hero = false }) => {

  return (
    <div className={css.section}>
      <div className={css.header} hero={hero ? "true" : null}>
        {header}
      </div>
    
    </div>
  );
};
