import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from 'react-router-dom';
import CollaboratorToCompany from "./CollaboratorToCompany";
import jwtDecode from "jwt-decode";
import ChoosenCollaborator from "./ChoosenCollaborator";
import { CREATE_NOTIFICATION } from "./mutation.gql";
import { toast } from "react-toastify";
import i18n from "../../../translate/i18n";

const CollaboratorDetails = () => {

  const { REACT_APP_API_URL } = process.env;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const { data, currentItems } = location.state || {};

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const [openAssociateDialog, setOpenAssociateDialog] = useState(false);
  const [associateId, setAssociateId] = useState(0);
  const [actionValor, setActionValor] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);

  const [open, setOpen] = useState(false);
  const [booleanStatus, setBooleanStatus] = useState(false);

  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  function handleClose() {
    setOpen(false);
  };

  useEffect(() => {
    const role = localStorage.getItem('userRole') ?? '';
    if (role == 'administrator') {
      setIsAdmin(!isAdmin);
    }
  }, []);

  const handleOpenAssociateDialog = (id) => {
    setAssociateId(id);
    setOpenAssociateDialog(true);
  };

  const handleCloseAssociateDialog = () => {
    setOpenAssociateDialog(false);
    setAssociateId(0);
  };

  const [collabPosition, setCollabPosition] = useState("N/A");

  const handleClickAssociate = (contractText, id) => {
    if (contractText == "Descontratar") {
      setActionValor(0);
    } else {
      setActionValor(1);
    }
    handleOpenAssociateDialog(id);
    setCollabPosition(data.postRoleDescription);
  };

  const handleClickSolicit = async (id) => {
    if (!isAdmin) {
      try {
        const userId = localStorage.getItem("userId");
        console.log("ID: ", userId, " COLLABID: ", id);
        if (userId && id) {
          const result = await createNotification({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            // fetchPolicy: "no-cache",
            variables: {
              action: 'hire', // ou: fire
              status: false,
              companyId: userId.toString(),
              collaboratorId: id.toString(),
            },
          });
          if (result?.data) {
            toast.success("Solicitação enviada com sucesso!");
          } else {
            console.warn("Associação concluída, mas a criação da avaliação falhou.");
            toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação.");
          }
        } else {
          alert(1);
        }
      } catch (error) {
        console.error("Erro ao processar a associação:", error);
        toast.error("Erro ao tentar contratar o colaborador.");
      } finally {
        // setLoadingCheck(false);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  if (!data) {
    return <p>Nenhum dado disponível</p>;
  }

  console.log("Curren data: ", data);



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              boxShadow: '0 0 10px rgba(0,0,0,0.05)',
              padding: '1.25rem 1rem',
              display: 'block',
              width: '100%'
            }}
          >
            Detalhes do Colaborador
          </span>
        </Grid>
      </Grid>

      <div style={{ alignItems: 'flex-start' }} className="flex flex-wrap items-start gap-4 w-full relative mt-8">
        <div className="w-[100%] lg:w-[70%]">
          <div className="bg-white rounded-[10px] p-8 shadow-md w-[100%]">
            <div style={{ alignItems: 'flex-start' }} className="flex flex-col jusity-center lg:flex-row lg:items-start w-full">
              <div className="w-[25%] aspect-square rounded-full">
                {
                  data.avatar ? (
                    <img
                      src={`${REACT_APP_API_URL}${data.avatar}`}
                      className="mx-auto w-[100px] h-[100px] aspect-square object-top rounded-full border"
                    />
                  ) : (<span className="mx-auto w-[100px] h-[100px] aspect-square object-top rounded-full border bg-[#724C68] text-white flex items-center justify-center">{data.name[0]}</span>)
                }
              </div>
              <div className="w-[80%] lg:ml-auto relative">
                <span className="block font-bold text-xl lg:text-[2.4rem_!important]">{data.name}</span>
                <span>{`${data.postRoleDescription} `}</span>
                <div className="text-[0.85rem]">
                  <span>{data.speciality ?? 'N/A'} </span>
                  <span>{` | Status: ${data.isActive ? 'Active' : 'Inactive'}`}</span>
                </div>
                <div className="flex items-center mt-4">
                  <div className="flex items-center mr-3">
                    {
                      data.evaluations.length > 0 ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <span
                            key={index}
                            className={`${index + 1 <= (parseInt(data.evaluations[0]?.attributes?.star, 10) || 0) ? 'text-[#F9AE00]' : 'text-black'}`}
                          >
                            {index + 1 <= (parseInt(data.evaluations[0]?.attributes?.star, 10) || 0) ? "★" : "☆"}
                          </span>
                        ))
                      ) : (
                        Array.from({ length: 5 }).map((_, index) => (
                          <span
                            key={index}
                            className="text-black"
                          >
                            ☆
                          </span>
                        ))
                      )
                    }
                  </div>
                  <span className="text-sm rounded-[50xp] py-[0.25rem] px-[0.5rem] bg-[#D79D33] text-white font-bold">{data.country}</span>
                </div>
                <div className="border-t mt-4 pt-4 mb-6">
                  <span className="font-bold">Skills</span>
                  <div className="flex items-center gap-2">
                    {
                      data?.skills.map((skill, index) => (
                        <small className="inline-block text-center rounded-[50px] bg-gray-300 text-[0.75rem] px-[0.65rem] py-[0.25rem]">{skill.name}</small>
                      ))
                    }
                  </div>
                </div>
                {
                  localStorage.getItem('userRole') == 'administrator' ? (

                    <div sytle={{ display: 'flex', alingItems: 'center', marginTop: '24px' }}>
                      <a
                        href={`${data.phone ? 'tel:' + data.phone : '#'}`}
                        style={{
                          display: 'inline-block',
                          textAlign: 'center',
                          backgroundColor: 'white',
                          fontSize: '0.75rem',
                          borderRadius: '50px',
                          color: '#000',
                          textDecoration: 'none',
                          padding: '0.5rem 1rem',
                          fontWeight: '600',
                          textTransform: 'uppercase',
                          border: '1px solid rgba(0,0,0,0.1)'
                        }}>Ligar</a>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClickAssociate(data.employmentCollab != null ? "Descontratar" : 'Contratar', data.id)}
                        sx={{
                          fontSize: '0.75rem',
                          borderRadius: '50px',
                          marginLeft: '8px'
                        }}
                      >
                        {
                          data.employmentCollab != null ? "Descontratar" : "Contratar"
                        }
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleClickSolicit(data.id)}
                      sx={{
                        fontSize: '0.75rem',
                        borderRadius: '50px',
                        marginLeft: '8px'
                      }}
                    >
                      Contratar
                    </Button>
                  )
                }
              </div>

            </div>

          </div>

        </div>

        <div className="w-[100%] lg:w-[28%] mt-4 lg:mt-0 lg:ml-auto">
          <div className="bg-white rounded-[10px] p-8 shadow-md w-[100%]">
            <div>
              <span className="block">Empresas trabalhado</span>
              {
                data?.companies?.map((comp, index) => (
                  <p>✔️ {comp?.attributes?.name} </p>
                ))
              }
            </div>
          </div>
          <div className="bg-white rounded-[10px] p-8 mt-4 shadow-md w-[100%]">
            <div>
              <span className="block">Exp. profissionais</span>
              {
                data?.experiences?.map((exp, index) => (
                  <div style={{ marginTop: '10px', borderBottom: '1px solid rgba(0,0,0,0.1)', paddingBottom: '8px' }}>
                    <small style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      ✔️ {exp?.postRoleDescription} {" "} {exp?.description} At:
                      <span style={{ color: '#F6921E', fontWeight: 'bold' }}>
                        {exp?.expEntity}
                      </span>
                    </small>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <small>{formatDate(exp.startDate)}</small>
                      <small>- {formatDate(exp.endDate)}</small>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

      </div>
      <div className="pb-16"></div>

      {
      /* <Grid container sx={{ marginTop: '16px' }}>
        <Grid item xs={12} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)'
        }}>
          <Grid sx={{
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F6921E',
            color: 'white',
            borderRadius: '50%',
            aspectRatio: '1/1'
          }}>
            {
              data.avatar ? (
                // <img src={data.avatar} />
                <span>
                  {data.name[0]}
                </span>
              ) : (
                <span>
                  {data.name[0]}
                </span>
              )
            }
          </Grid>
          <h4>{data.name}</h4>
          <small>{data.country} {" - "} {data.city}</small>
          <div>
            {`${data.postRoleDescription} `}
            <span>{data.speciality ?? 'N/A'}{` Com ${data.yearsofExperience ?? '-'} Anos de Experiência`}</span>
            <span>{` | Estado: ${data.isActive ? 'Activo' : 'Inactivo'}`}</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '18px' }}>
            {
              Array.from({ length: 5 }).map((_, index) => (
                <span style={{
                  color: index <= 3 ? '#F6921E' : '#000'
                }}>
                  {index <= 3 ? '★' : '☆'}
                </span>
              ))
            }
          </div>

          {
            isAdmin && (

              <div sytle={{ display: 'flex', alingItems: 'center', marginTop: '24px' }}>
                <a
                  href={`${data.phone ? 'tel:' + data.phone : '#'}`}
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    fontSize: '0.75rem',
                    borderRadius: '50px',
                    color: '#000',
                    textDecoration: 'none',
                    padding: '0.5rem 1rem',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    border: '1px solid rgba(0,0,0,0.1)'
                  }}>Ligar</a>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClickAssociate('Contratar', data.id)}
                  sx={{
                    fontSize: '0.75rem',
                    borderRadius: '50px',
                    marginLeft: '8px'
                  }}
                >
                  Contratar
                </Button>
              </div>
            )
          }


        </Grid>
      </Grid> */}

      {/* <Grid container sx={{ marginTop: '16px' }} justifyContent="space-between">
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)'
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Descrição</h4>
            <p>
              {data.name}, Desenvolvedor muito dedicado com mais experiencias no mercado, ele está disposto a dar o seu melhor.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)',
          mt: { xs: 4, md: 0 },
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Empresas trabalhado</h4>
            {
              data?.companies?.map((comp, index) => (
                <p>✔️ {comp?.attributes?.name} </p>
              ))
            }
          </div>
        </Grid>
        <Grid item xs={12} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)',
          mt: { xs: 4 },
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Experiencias Profissional</h4>
            {
              data?.experiences?.map((exp, index) => (
                <div style={{ marginTop: '10px', borderBottom: '1px solid rgba(0,0,0,0.1)', paddingBottom: '8px' }}>
                  <small style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>✔️ {exp?.postRoleDescription} {" "} {exp?.description} At: <span style={{ color: '#F6921E', fontWeight: 'bold' }}>{exp?.expEntity}</span> || Country: <span style={{ color: '#F6921E', fontWeight: 'bold' }}> {`${exp?.country} `}</span></small>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <small>Start Date: {formatDate(exp.startDate)}</small>
                    <small>| End Date: {formatDate(exp.endDate)}</small>
                  </div>
                </div>
              ))
            }
          </div>
        </Grid>
      </Grid> */}
      {
        localStorage.getItem('userRole') == 'administrator' ? (
          <CollaboratorToCompany
            open={openAssociateDialog}
            handleClose={handleCloseAssociateDialog}
            idCollaborador={associateId}
            action={actionValor}
            positionCollaborator={collabPosition}
            collabRole={collabPosition}
          />
        ) : (
          <ChoosenCollaborator
            open={openAssociateDialog}
            handleClose={handleCloseAssociateDialog}
            collaboratorID={associateId}
          />
        )
      }

    </>
  );
};


export default CollaboratorDetails;

