import { gql } from "@apollo/client";

export const GET_COMPANY_BY_ID = gql`
query getCompanybyUserId($userId: ID!) {
  companies(filters: { users_permissions_user: { id: { eq: $userId } } }) {
    data {
      id
      attributes {
        status
        name
        users_permissions_user {
          data {
            id
            attributes {
              username
            }
          }
        }
        city {
          data {
            id
            attributes {
              name
            }
          }
        }
        country {
          data {
            id
            attributes {
              name
            }
          }
        }
        email
        phone
      }
    }
  }
}


`;
 
export const GET_COMPANIES = gql`
query Companies($pagination: PaginationArg) {
  companies(pagination: $pagination  )  {
    data  {
      id
      attributes {
        status
        name
        users_permissions_user{data{id attributes{username}}} 
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        email
        phone
        branchOfActivity
        image{data{id attributes{url}}}
        findDevs {
          data {
            id
            attributes {
              description
             }}}}}}}
`;

export const QUERY_STATUS_ENUM= gql`
query ENUM_COMPANY_STATUS{
  __type(name:"ENUM_COMPANY_STATUS"  ){
    enumValues{name}
     }

}

`

