import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent, Grid,
  IconButton, Snackbar, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_USER, CREATE_COLLABORATOR, CREATE_PORTFOLIO, CREATE_AWARD, CREATE_PROFILE, CREATE_EDUCATION, CREATE_EXPERIENCE, CREATE_EXPERTISE_CATEGORY, CREATE_SKILLS, CREATE_MEDIA_FILE } from "./mutation.gql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { GET_CITIES, GET_COUNTRY_STATE_CITY } from "./query.gql";
import { GET_EMPLOYEER_BY_EMAIL } from "./query.gql";
import VerticalLinearStepper from "./create_collaborator_stepper";
import ClearIcon from '@mui/icons-material/Clear';
import { createFilterOptions } from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import CollaboratorStep from "./steps/step_0_Collaborator";
import ExperienceStep from "./steps/step_1_Experience";
import EducationStep from "./steps/step_2_Education";
import ExpertiseStep from "./steps/step_3_Expertise";
import SkillStep from "./steps/step_4_Skill";
import ProfileStep from "./steps/step_5_Profile";
import AwardStep from "./steps/step_6_Award";
import PortfolioStep from "./steps/step_7_Portfolio";
import Pathway from "../../../components/TopBar/pathway";
import LoadingCircle from "../../../components/Loading/loading_circle";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useContext } from "react";
import i18n from "../../../translate/i18n";

export default function CreateCollaborator() {
  const [cE, setCE] = useState([]);
  const filter = createFilterOptions();
  const validationEmailForm = yup
    .object({
      email: yup
        .string()
        .notOneOf(cE, "Email in use")
        .email("Insert a valid Email Address")
        .required("Email is Mandatory"),
      name: yup
        .string()
        .min(3, "The name need to have more than 3 characters")
        .required("The Name is Mandatory"),
      phone: yup
        .string()
        .min(7, "Phone must have more than 7 numbers")
        .required("Phone Number is required"),
      country: yup.string().required("Country is Mandatory"),
      city: yup.string().required("City is Mandatory"),
      postRole: yup.string().required("PostRole is Mandatory"),
      urlProfile: yup.string().required("Url Link is Mandatory"),
      portfolioName: yup.string().required("Title is Mandatory"),
      awardName: yup.string().required("Award Name is Mandatory"),

    })
    .required();

  let navigate = useNavigate();
  const jwt = localStorage.getItem("jwtToken");
  const { selectedLanguage } = useContext(LanguageContext);
  // >>>>>>>>>>>>>>>>>>>>>>Step Variables <<<<<<<<<<<<<<<<<<<<<<<<<
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [step, setStep] = useState(0);
  const handleFinishClick = () => {
    registerForm()
    //setStep(0);
  };


  // >>>>>>>>>>>>>>>>>>>>>>Create Collaborator Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [getEmployeerByEmail] = useLazyQuery(GET_EMPLOYEER_BY_EMAIL);
  const [register_login] = useMutation(CREATE_USER);
  const [register_collaborator] = useMutation(CREATE_COLLABORATOR);
  const [collaborator, setCollaborator] = useState([])
  const [collaboratorID, setCollaboratorID] = useState(null)
  const [collaboratorGrid, setCollaboratorGrid] = useState(true);

  // >>>>>>>>>>>>>>>>>>>>>>Create Experience Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_experience] = useMutation(CREATE_EXPERIENCE);
  const [experience, setExperience] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Education Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_education] = useMutation(CREATE_EDUCATION);
  const [education, setEducation] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Expertise Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_expertiseCategory] = useMutation(CREATE_EXPERTISE_CATEGORY);
  const [expertise, setExpertise] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Award Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_award] = useMutation(CREATE_AWARD);
  const [award, setAward] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Profile Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_profile] = useMutation(CREATE_PROFILE);
  const [profile, setProfile] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Portfolio Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_portfolio] = useMutation(CREATE_PORTFOLIO);
  const [portfolio, setPortfolio] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Create Skill Step<<<<<<<<<<<<<<<<<<<<<<<<<
  const [new_skills] = useMutation(CREATE_SKILLS);
  const [skill, setSkill] = useState([])

  // >>>>>>>>>>>>>>>>>>>>>>Country & City Variables <<<<<<<<<<<<<<<<<<<<<<<<<
  const [getCountries] = useLazyQuery((GET_COUNTRY_STATE_CITY));
  const [getCities] = useLazyQuery((GET_CITIES));
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);

  // >>>>>>>>>>>>>>>>>>>>>>Image Upload Files <<<<<<<<<<<<<<<<<<<<<<<<<

  const [uploadImage] = useMutation(CREATE_MEDIA_FILE);
  const [uploadAvatar] = useMutation(CREATE_MEDIA_FILE);
  const FILE_TYPE = "image/jpeg";


  // >>>>>>>>>>>>>>>>>>>>>>Delete Index Display <<<<<<<<<<<<<<<<<<<<<<<<<
  function handleDelete(index, items, setItems, setCollaboratorGrid) {
    const newItems = items.filter((item, i) => i !== index);
    setItems(newItems);

    if (newItems.length === 0) {
      setCollaboratorGrid(true);
    }
  }
  function handleColalboratorDelete(index, items, setItems, setCollaboratorGrid, setCollaboratorID) {
    if (Array.isArray(items)) {
      const newItems = items.filter((item, i) => i !== index);
      setItems(newItems);

      if (newItems.length === 0) {
        setCollaboratorGrid(true);
      }
      setCollaboratorID(null);
    }
  }



  const {
    setValue,
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });

  const toLogin = () => navigate("/login");
  const [isLoading, setIsLoading] = useState(false);
  const registerForm = async () => {
    setIsLoading(true);

    if (collaboratorID !== null) {
      try {
        if (education.length > 0) {
          for (let i = 0; i < education.length; i++) {
            try {
              await new_education({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    faculty: education[i].facultyID,
                    course: education[i].courseID,
                    description: education[i].description,
                    startDate: education[i].startDate,
                    collaborator: collaboratorID,
                    endDate: education[i].endDate,
                    country: education[i].countryID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_education:", error);
              toast.error("Error in Education!");
            }

          }
          toast.success("Education Done!!")
          setEducation([])
        }

        if (profile.length > 0) {
          for (let i = 0; i < profile.length; i++) {
            try {
              await new_profile({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    platform: profile[i].platformID,
                    urlProfile: profile[i].urlProfile,
                    description: profile[i].description,
                    collaborator: collaboratorID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_profile:", error);
              toast.error("Error in Profile!");
            }
          }
          toast.success("Profile Done!!");
          setProfile([]);
        }

        if (portfolio.length > 0) {
          for (let i = 0; i < portfolio.length; i++) {
            try {
              await new_portfolio({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                variables: {
                  data: {
                    name: portfolio[i].name,
                    description: portfolio[i].description,
                    collaborator: collaboratorID,
                  },
                },
              }).then(async (data) => {
                const newPort = data.data.createPortfolio.data;
                const fileBlob = portfolio[i].image;
                const fileName = `Portfolio of ${collaborator.name} - ${portfolio[i].name}`;
                const fileType = FILE_TYPE;
                const file = new File([fileBlob], fileName, { type: fileType });

                try {
                  const response = await uploadImage({
                    context: {
                      headers: {
                        authorization: `Bearer ${jwt}`,
                      },
                    },
                    variables: {
                      file: file,
                      info: { name: fileName },
                      refId: newPort.id,
                      field: 'image',
                      ref: 'api::portfolio.portfolio'
                    },
                  });
                } catch (error) {
                  console.error("Error uploading file:", error);
                  toast.error("Error uploading file in Portfolio!");
                }
              });
            } catch (error) {
              console.error("Error in new_portfolio:", error);
              toast.error("Error in Portfolio!");
            }
          }
          setPortfolio([]);
          toast.success("Portfolio Done!!");
        }

        if (award.length > 0) {
          for (let i = 0; i < award.length; i++) {
            try {
              await new_award({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    name: award[i].name,
                    description: award[i].description,
                    collaborator: collaboratorID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_award:", error);
              toast.error("Error in Award!");
            }
          }
          setAward([]);
          toast.success("Award Done!!");
        }


        if (experience.length > 0) {
          for (let i = 0; i < experience.length; i++) {
            try {
              await new_experience({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    expEntity: experience[i].id,
                    description: experience[i].description,
                    startDate: experience[i].startDate,
                    collaborator: collaboratorID,
                    endDate: experience[i].endDate,
                    country: experience[i].countryID,
                    postRole: experience[i].postRoleID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_experience:", error);
              toast.error("Error in Experience!");
            }
          }
          setExperience([]);
          toast.success("Experience Done!!");
        }

        if (skill.length > 0) {
          for (let i = 0; i < skill.length; i++) {
            try {
              await new_skills({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    itTool: skill[i].itToolID,
                    percentual: skill[i].percentual,
                    collaborator: collaboratorID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_skills:", error);
              toast.error("Error in Skill!");
            }
          }
          setSkill([]);
          toast.success("Skill Done!!");
        }

        if (expertise.length > 0) {
          for (let i = 0; i < expertise.length; i++) {
            try {
              await new_expertiseCategory({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                //fetchPolicy: "network-only",
                variables: {
                  data: {
                    expertise: expertise[i].expertiseID,
                    description: expertise[i].description,
                    collaborator: collaboratorID,
                  },
                },
              });
            } catch (error) {
              console.error("Error in new_expertiseCategory:", error);
              toast.error("Error in Expertise!");
            }
          }
          setExpertise([]);
          toast.success("Expertise Done!!");
        }
        window.location.href = "/collaborator";
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error Creating Curriculum!");
      }
    } else {
      toast.error("The email is already in use!!");
    }
    setIsLoading(false);
  };
  const CollaboratorCreator = async () => {
    try {
      const { data } = await getEmployeerByEmail({
        variables: {
          filters: {
            email: {
              eq: collaborator[0].email,
            },
            blocked: {
              eq: false,
            },
          },
        },
      });

      console.log(data.usersPermissionsUsers.data.length);

      if (data && data.usersPermissionsUsers && data.usersPermissionsUsers.data.length === 0) {
        const registerData = await register_login({
          variables: {
            data: {
              username: collaborator[0].name,
              name: collaborator[0].name,
              email: collaborator[0].email,
              password: collaborator[0].email,
              postRole: collaborator[0].postRoleID,
              confirmed: true,
              userRole: 3,
              role: 4,
            },
          },
        });
        console.log("USERID", registerData.data?.createUsersPermissionsUser?.data?.id)
        const registerCollaboratorData = await register_collaborator({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            data: {
              name: collaborator[0].name,
              phone: collaborator[0].phone,
              email: collaborator[0].email,
              users_permissions_user: registerData.data?.createUsersPermissionsUser?.data?.id,
              city: collaborator[0].cityID,
              country: collaborator[0].countryID,
              postRole: collaborator[0].postRoleID,
              address: collaborator[0].address,
              intro: collaborator[0].intro,
              web: collaborator[0].web,
              speciality: collaborator[0].speciality,
            },
          },
        });

        const collabID = registerCollaboratorData.data?.createCollaborator?.data?.id;
        setCollaboratorID(collabID);
        setStep(step + 1);

        const blob = collaborator[0].image;
        const fileName = `Avatar of ${collaborator[0].name}`; // set the filename and extension
        const fileType = "image/jpeg"; // set the file type
        const file = new File([blob], fileName, { type: fileType });

        await uploadAvatar({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            file: file,
            info: { name: fileName },
            refId: collabID,
            field: "image",
            ref: "api::collaborator.collaborator",
          },
        });
        setSuccessMessage(i18n.t('collaborator.alert.creationSuccess', { lng: selectedLanguage }));
        setSeverity("success")
        setOpenAlertOK(true)
      } else {
        setSuccessMessage(i18n.t('collaborator.alert.creationEmailWarning', { lng: selectedLanguage }));
        setSeverity("warning")
        setOpenAlertOK(true)
      }
    } catch (error) {
      console.log(error);
      setSuccessMessage(i18n.t('collaborator.alert.creationError', { lng: selectedLanguage }));
      setSeverity("error")
      setOpenAlertOK(true)
      handleColalboratorDelete();
    }
  };

  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [severity, setSeverity] = useState('info')



  console.log("Collaborator", collaborator)
  console.log("CollaboratorID", collaboratorID)


  return (<>
    <Pathway />

    <Grid container /* spacing={8} */ style={{ display: "flex", justifyContent: "space-evenly" }}>

      <Grid item xs={2} md={2.4} style={{ background: "#FFFFFF", borderRadius: "1rem", boxShadow: "0 0 10px rgba(76, 141, 235, 0.1)" }}>
        <VerticalLinearStepper
          receivedStep={step}
          receivedSetStep={setStep}
          collaborator={collaborator}
          isGridVisible={isGridVisible}
          setIsGridVisible={setIsGridVisible}
          collaboratorID={collaboratorID}
          CollaboratorCreator={CollaboratorCreator}
          openAlertOK={setOpenAlertOK}
          setSuccessMessage={setSuccessMessage}
          setSeverity={setSeverity}

        />
      </Grid>
      <Grid item xs={8} md={9} style={{ background: "#FFFFFF", borderRadius: "1rem", boxShadow: "0 0 10px rgba(76, 141, 235, 0.1)", paddingLeft: "2rem", paddingRight: "2rem" }}>
        <>
          <ToastContainer />
          <Grid container spacing={3}>

            { /* Collaborator Step */}
            <Grid item container spacing={3} sx={{ display: step !== 0 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.collaborator', { lng: selectedLanguage })}
                </Typography>
              </Grid>


              {collaboratorGrid &&
                <CollaboratorStep
                  step={"Create"}
                  setCollaboratorGrid={setCollaboratorGrid}
                  collaborator={collaborator}
                  setCollaborator={setCollaborator}
                />
              }

              <Grid item xs={12} >
                <Grid item md={12} container >

                  <Card>
                    {collaborator?.map((item, index) => (
                      <CardContent key={index}>
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container justifyContent="center">
                              <Avatar alt="Remy Sharp" src={collaborator[index]?.image instanceof Blob && URL.createObjectURL(collaborator[index]?.image)} sx={{ width: 80, height: 80 }} />

                            </Grid>
                          </Grid>

                        </Grid>

                        <Typography sx={{ marginTop: '16px' }} variant="h6" >

                          <Box marginBottom={1} ><Typography variant="h6" display={"inline"} color="primary">Nome:</Typography>  {item.name}</Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Telefone:</Typography>  {item.phone}</Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Email:</Typography>  {item.email}</Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Endereço: </Typography> {item.address} </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Cidade:</Typography>  {item.cityName} </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">País:</Typography>  {item.countryName} </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Intro:</Typography>  {" "}
                            {item.intro.split(" ").slice(0, 2).join(" ")}{" "}
                            {item.intro.split(" ").length > 2 && "..."} {/* Add "..." if there are more than 20 words */}
                          </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Web:</Typography>  {item.web} </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Cargo:</Typography>  {item.postRole} </Box>
                          <Box marginBottom={1}><Typography variant="h6" display={"inline"} color="primary">Especialidade:</Typography>  {item.speciality} </Box>
                          <Grid >
                            <Grid container justifyContent="center">
                              <Button
                                fullWidth
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                color="primary"
                                onClick={() => handleColalboratorDelete(index, collaborator, setCollaborator, setCollaboratorGrid, setCollaboratorID)}
                              >
                                Discard
                              </Button>

                            </Grid>
                          </Grid>
                        </Typography>
                      </CardContent>
                    ))}
                  </Card>

                </Grid>
              </Grid>



              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5} >
                  <Button type="button" color="primary"  fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Button
                    type="button"
                    size="medium"
                    variant="contained"
                    // disabled={collaborator.length === 0}
                    onClick={() => {
                      if (collaborator.length === 0) {
                        setSuccessMessage(i18n.t('collaboratorStep.error.collaborator', { lng: selectedLanguage }));
                        setSeverity("error")
                        setOpenAlertOK(true)
                        setIsGridVisible(true)
                        setStep(step + 1)
                      }
                      else if (collaborator.length === 1 && collaboratorID === null) {

                        CollaboratorCreator()

                      } else { setStep(step + 1) }
                    }}
                    fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Experience Step */}
            <Grid item container spacing={3} sx={{ display: step !== 1 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.experience', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.experience', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <ExperienceStep
                  step={"Create"}
                  setIsGridVisible={setIsGridVisible}
                  setExperience={setExperience}
                />
              }

              <Grid item xs={12} >
                <Grid justifyContent="space-between" item md={12} xs={12} container spacing={3}>

                  <Card >
                    {experience?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>

                          <Typography variant="h6" >
                            {item.postRole}, {item.name}
                          </Typography>

                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.startDate} - {item.endDate}
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, experience, setExperience, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} >
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Education Step */}
            <Grid item container spacing={3} sx={{ display: step !== 2 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.education', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.education', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <EducationStep
                  step={"Create"}
                  setEducation={setEducation}
                  setIsGridVisible={setIsGridVisible}
                />
              }

              <Grid item xs={12} >
                <Grid item md={12} container spacing={3}>

                  <Card >
                    {education?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>

                          <Typography variant="h6" >
                            {item.countryName}, {item.faculty}
                          </Typography>


                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.startDate} - {item.endDate}
                          </Typography></div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, education, setEducation, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Expertise Step */}
            <Grid item container spacing={3} sx={{ display: step !== 3 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.expertise', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.expertise', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <ExpertiseStep
                  step={"Create"}
                  setIsGridVisible={setIsGridVisible}
                  setExpertise={setExpertise}
                />
              }

              <Grid item xs={12} >
                <Grid item md={12} container spacing={3}>

                  <Card >
                    {expertise?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                          <Typography variant="h6" >
                            {item.expertiseName}
                          </Typography>


                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.description}
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, expertise, setExpertise, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} >
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Skill Step */}
            <Grid item container spacing={3} sx={{ display: step !== 4 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.skill', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.skill', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <SkillStep
                  step={"Create"}
                  setSkill={setSkill}
                  setIsGridVisible={setIsGridVisible}
                />
              }

              <Grid item xs={12} >
                <Grid item md={12} container spacing={3}>

                  <Card >
                    {skill?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>

                          <Typography variant="h6" >
                            {item.itToolName} - {item.percentual}%
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, skill, setSkill, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>


            { /* Profile Step */}
            <Grid item container spacing={3} sx={{ display: step !== 5 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.profile', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.profile', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <ProfileStep
                  step={"Create"}
                  setProfile={setProfile}
                  setIsGridVisible={setIsGridVisible}
                />
              }

              <Grid item xs={12} >
                <Grid item md={12} container spacing={3}>

                  <Card >
                    {profile?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                          <Typography variant="h6" >
                            {item.platformName}<br />
                            {item.urlProfile}
                          </Typography>


                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.description}
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, profile, setProfile, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} >
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Award Step */}
            <Grid item container spacing={3} sx={{ display: step !== 6 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.award', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.award', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <AwardStep
                  step={"Create"}
                  setAward={setAward}
                  setIsGridVisible={setIsGridVisible}
                />
              }

              <Grid item xs={12} >
                <Grid container item md={12} spacing={3}>

                  <Card >
                    {award?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                          <Typography variant="h6" >
                            {item.name}
                          </Typography>


                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.description}
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, award, setAward, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>

                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} >
                  <Button type="button" size="medium" variant="contained" onClick={() => setStep(step + 1)} fullWidth>
                    {i18n.t('collaborator.button.next', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>

            { /* Portfolio Step */}
            <Grid item container spacing={3} sx={{ display: step !== 7 ? "none" : "" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "10px", marginTop: "30px" }}
              >
                <Typography
                  variant="h3"
                >
                  {i18n.t('collaborator.title.portfolio', { lng: selectedLanguage })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setIsGridVisible(prevState => !prevState)}
                >
                  <b>+ </b> {i18n.t('collaborator.subtitle.portfolio', { lng: selectedLanguage })}
                </Button>
              </Grid>

              {isGridVisible &&
                <PortfolioStep
                  step={"Create"}
                  setPortfolio={setPortfolio}
                  setIsGridVisible={setIsGridVisible}
                />
              }

              <Grid item xs={12} >
                <Grid container item md={12} >

                  <Card>
                    {portfolio?.map((item, index) => (
                      <CardContent key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                          <Avatar alt="Remy Sharp" src={portfolio[index]?.image instanceof Blob && URL.createObjectURL(portfolio[index]?.image)} sx={{ width: 80, height: 80 }} />
                          <Typography variant="h6" >
                            {item.name}
                          </Typography>

                          <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            {item.description}
                          </Typography>
                        </div>

                        <IconButton aria-label="delete" onClick={() => handleDelete(index, portfolio, setPortfolio, setIsGridVisible)}>
                          <ClearIcon />
                        </IconButton>
                      </CardContent>
                    ))}
                  </Card>


                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ pt: "2rem", pb: "2rem" }} justifyContent="space-around">
                <Grid item xs={12} md={5}>
                  <Button type="button" onClick={() => setStep(step - 1)} color="primary" fullWidth>
                    {i18n.t('collaborator.button.prev', { lng: selectedLanguage })}
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} >
                  <Button type="submit" size="medium" variant="contained" fullWidth onClick={handleFinishClick}>
                    {i18n.t('collaborator.button.end', { lng: selectedLanguage })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>


          </Grid>
        </>
      </Grid>

    </Grid >
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={openAlertOK}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
    >
      <Alert severity={severity} onClose={handleCloseAlert}>
        <AlertTitle>{
          severity === 'success' ? i18n.t('alert.success', { lng: selectedLanguage }) :
            severity === 'warning' ? i18n.t('alert.warning', { lng: selectedLanguage }) :
              severity === 'info' ? i18n.t('alert.info', { lng: selectedLanguage }) :
                i18n.t('alert.error', { lng: selectedLanguage })}
        </AlertTitle>
        {successMessage}
      </Alert>
    </Snackbar>
    {isLoading && <LoadingCircle message={"Creating Curriculum"} />}
  </>);
};

