import { createContext, useState, useEffect } from "react";

export const UserRoleContext = createContext();

const initialState = '';

export function UserRoleProvider({ children }) {
  // Try to get the initial state from localStorage, if it exists
  const [uRoleContext, setURoleContext] = useState(() => {
    const storedUserRole = localStorage.getItem("userRole");
    return storedUserRole !== null ? storedUserRole : initialState;
  });

  // Update the localStorage whenever uRoleContext changes
  useEffect(() => {
    localStorage.setItem("userRole", uRoleContext);
  }, [uRoleContext]);

  return (
    <UserRoleContext.Provider value={{ uRoleContext, setURoleContext }}>
      {children}
    </UserRoleContext.Provider>
  );
}
