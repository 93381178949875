import MainCard from "../../components/MainCard/main-card.component";
import { Hero } from "./sections/hero";
import { Intro } from "./sections/intro";
import { Expertise } from "./sections/expertise";
import { Skills } from "./sections/skills";
import { Experience } from "./sections/experience";
import { Education } from "./sections/education";
import { Profiles } from "./sections/profiles";
import { Awards } from "./sections/awards";
import { Portfolio } from "./sections/portfolio";
import { Clients } from "./sections/clients";
import { Contact } from "./sections/contact";

const CurriculumDetailPage = () => {
  return (
    <MainCard title="Detalhes do Currículo">
      <Hero />
      <Intro />
      <Expertise />
      <Skills />
      <Experience />
      <Education />
      <Profiles />
      <Awards />
      <Portfolio />
      <Clients />
      <Contact />
    </MainCard>
  );
};

export default CurriculumDetailPage;
