import { gql } from "@apollo/client";

export const UPDATE_QUESTIONN = gql`
mutation UpdateQuestion($updateQuestionId: ID!, $data: QuestionInput!) {
  updateQuestion(id: $updateQuestionId, data: $data) {
    data {
      attributes {
        question
      }
    }
  }
}
`;

export const DELETE_QUESTION = gql`
mutation Mutation($deleteQuestionId: ID!) {
  deleteQuestion(id: $deleteQuestionId) {
    data {
      id
    }
  }
}
`;

export const CREATE_QUESTION = gql`
mutation Mutation($data: QuestionInput!) {
    createQuestion(data: $data) {
      data {
        id
        attributes {
          question
        }
      }
    }
  }
`;


export const CREATE_ANSWER = gql`
mutation EvaluationAnswerClosed($data:EvaluationAnswerClosedInput!) {
  createEvaluationAnswerClosed(data: $data) {
    data {
      id
      attributes {
        answer
        rate
        evaluation_question_closed{
          data{
            id 
            attributes{
              question
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_ANSWER = gql`
mutation UpdateAnswer($updateAnswerId: ID!, $data: AnswerInput!) {
  updateAnswer(id: $updateAnswerId, data: $data) {
    data {
      attributes {
        answer
      }
    }
  }
}
`;





export const CREATE_QUESTION_OPEN = gql`
mutation EvaluationQuestionOpened($data: EvaluationQuestionOpenInput!){
  createEvaluationQuestionOpen(data: $data){
    data{
      id 
      attributes{
        question
        isActive
        type
        indicator{
          data{
            id 
            attributes{
              indicator
        		}
        	}
        }
      }
    }
  }
}
`;

export const CREATE_QUESTION_CLOSE = gql`
mutation EvaluationQuestionClosed($data:EvaluationQuestionClosedInput!) {
  createEvaluationQuestionClosed(data: $data) {
    data {
      id
      attributes {
        question
        isActive
        type
        indicator {
          data {
            id
            attributes {
              indicator
            }
          }
        }
      }
    }
  }
}
`;

export const CREATE_ANSWER_CLOSE = gql`
mutation EvaluationAnswerClosed($data:EvaluationAnswerClosedInput!) {
  createEvaluationAnswerClosed(data: $data) {
    data {
      id
      attributes {
        answer
        rate
        evaluation_question_closed{
          data{
            id 
            attributes{
              question
            }
          }
        }
      }
    }
  }
}
`;

export const DELETE_QUESTION_OPEN = gql`
mutation Mutation($id: ID!) {
  deleteEvaluationQuestionOpen(id: $id) {
    data {
      id
    }
  }
}
`;

export const DELETE_QUESTION_CLOSE = gql`
mutation Mutation($id: ID!) {
  deleteEvaluationQuestionClosed(id: $id) {
    data {
      id
    }
  }
}
`;

export const DELETE_ANSWER_CLOSE = gql`
mutation Mutation($id: ID!) {
  deleteEvaluationAnswerClosed(id: $id) {
    data {
      id
    }
  }
}
`;

export const UPDATE_QUESTION_CLOSE = gql`
mutation Mutation($id: ID!, $data:EvaluationQuestionClosedInput!) {
  updateEvaluationQuestionClosed(id: $id, data: $data) {
    data {
      id
       attributes {
        question
        isActive
        indicator {
          data {
            id
            attributes {
              indicator
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_QUESTION_OPEN = gql`
mutation Mutation($id: ID!, $data:EvaluationQuestionOpenInput!) {
  updateEvaluationQuestionOpen(id: $id, data: $data) {
    data {
      id
       attributes {
        question
        isActive
        indicator {
          data {
            id
            attributes {
              indicator
            }
          }
        }
      }
    }
  }
}
`;


export const UPDATE_ANSWER_CLOSED = gql`
mutation Mutation($id: ID!, $data:EvaluationAnswerClosedInput!) {
  updateEvaluationAnswerClosed(id: $id, data: $data) {
    data {
      id
       attributes {
        answer
        rate
      }
    }
  }
}
`;