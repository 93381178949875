import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from "recharts";
import i18n from "../../translate/i18n";
  
  const data = [
    {
      name: i18n.t('month.jan'),
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: i18n.t('month.feb'),
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: i18n.t('month.mar'),
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: i18n.t('month.apr'),
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: i18n.t('month.may'),
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: i18n.t('month.jun'),
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: i18n.t('month.jul'),
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: i18n.t('month.aug'),
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: i18n.t('month.sep'),
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: i18n.t('month.oct'),
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: i18n.t('month.nov'),
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: i18n.t('month.dec'),
      uv: 4000,
      pv: 2400,
      amt: 2400,
    }
  ];
  
  const HistoryChart = () => {
    return (
      <div style={{ width: "50%", height: 200 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };
  
  export default HistoryChart;
  