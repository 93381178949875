import { Section } from "../section";
import css from "../css/experience.module.css";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";

const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>
        {colaboradorEdit === true && <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Add Experience");
            setStepID("ID");
          }}>
            <PlusOneIcon />
          </IconButton>
        </>}Experience</h2>
      <p>Yes. I've been around.</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Experience");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ handleOpen, setStepID, experience, colaboradorEdit }) => {

  return (
    <>{colaboradorEdit === true ? (
      <>
        <ul className={css.list}>
          {experience?.map((exp) => (
            <li key={exp.id} className={css.experience}>
              <div className={css.marker}></div>
              <div className={css.wrapper}>
                <div className={css.intro}>
                  <p className={css.date}>
                    {exp.startDate} - {exp.endDate}
                  </p>
                  <h3>{exp.expEntity}</h3>
                  <p className={css.role}>{exp.postRole}</p>
                  <p className={css.address}>{exp.country}</p>
                </div>
                <p className={css.description}>{exp.description}</p>
              </div>
              <IconButton color="primary" onClick={() => {
                handleOpen("Edit Experience");
                setStepID(exp.id);
              }}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => {
                handleOpen("Delete Experience");
                setStepID(exp.id);
              }}>
                <ClearOutlinedIcon />
              </IconButton>

            </li>
          ))}
        </ul>
      </>
    ) : (
      <>
        <ul className={css.list}>
          {experience?.map((exp) => (
            <li key={exp.id} className={css.experience}>
              <div className={css.marker}></div>
              <div className={css.wrapper}>
                <div className={css.intro}>
                  <p className={css.date}>
                    {exp.startDate} - {exp.endDate}
                  </p>
                  <h3>{exp.expEntity}</h3>
                  <p className={css.role}>{exp.postRole}</p>
                  <p className={css.address}>{exp.country}</p>
                </div>
                <p className={css.description}>{exp.description}</p>
              </div>

            </li>
          ))}
        </ul>
      </>
    )}</>

  );
};

export const Experience = ({ handleOpen, setStepID, experience, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} experience={experience} />} />;
};
