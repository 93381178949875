import { gql } from "@apollo/client";

export const GET_DIMENSION = gql`
query Dimensions($filters: DimensionFiltersInput, $indicatorsFilters2: IndicatorFiltersInput) {
  dimensions(filters: $filters) {
    data {
      id
      attributes {
        dimension
        indicators(filters: $indicatorsFilters2) {
          data {
            id
            attributes {
              indicator
            }
          }
        }
      }
    }
  }
}
`;

export const GET_QUESTION = gql`
query Questions($page:Int!, $pageSize:Int!, $filters: String!) {
  questions(pagination:{page:$page,pageSize:$pageSize}, filters: { question: { contains: $filters } }) {
    meta { pagination { page, pageSize, pageCount, total } }
    data {
      id
      attributes {
        question
        isActive
        indicator{
          data{
            id
            attributes{
              indicator
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ANSWER = gql`
query Answers {
  evaluationAnswerCloseds(pagination:{pageSize:100}) {
    data {
      id
      attributes {
        rate
        answer
      }
    }
  }
}
`;

export const GET_EvaluationQuestion_Closed = gql`
query EvaluationQuestion_Closed{
  evaluationQuestionCloseds(pagination:{pageSize:100}){
    data{
      id
      attributes{
        type
        question
        indicator{
          data{
            id
            attributes{
              indicator
            }
          }
        }
        evaluation_answer_closeds{
          data{
            id 
            attributes{
              answer 
              rate
            }
          }
        }
      }
    }
  }
}
`;

export const GET_EvaluationQuestion_Opened = gql`
query EvaluationQuestion_Opened{
  evaluationQuestionOpens(pagination:{pageSize:100}){
    data{
      id
      attributes{
        isActive
        type
        question
        indicator{
          data{
            id
            attributes{
              indicator
            }
          }
        }
      }
    }
  }
}
`;