import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_INDICATORS } from './query.gql';
import SearchAddBar from '../../components/TopBar/search-add-bar.component';
import IndicatorList from './indicator-list';
import Pathway from '../../components/TopBar/pathway';
import SnackbarComponent from '../../components/Cards/snackbarComponent';

const IndicatorPage = () => {

  const jwt = localStorage.getItem('jwtToken');
  const [indicators, setIndicators] = useState([]);
  const [filteredList, setFilteredList] = useState('');

  const [getIndicators, { loading, data }] = useLazyQuery(GET_INDICATORS, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getIndicators();
  }, []);

  useEffect(() => {
    if (data) {
      const { indicators } = data;
      setIndicators(indicators.data);
    }
  }, [data]);

  const filteredSearched = indicators.filter((item) => {
    return item.attributes.indicator.toLowerCase().includes(filteredList.toLowerCase())
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Pathway />
        <SearchAddBar
          searched={filteredList}
          setSearched={setFilteredList}
          data={indicators}
          setData={setIndicators}
        />
        <IndicatorList
          filteredSearched={filteredSearched}
          indicators={indicators}
          setIndicators={setIndicators}
        />
      </Grid>
      <SnackbarComponent />
    </Grid>
  );
};

export default IndicatorPage;
