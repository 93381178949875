import { Section } from "../section";
import css from "../css/hero.module.css";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Twitter from "@mui/icons-material/Twitter";

const Header = () => {
  return (
    <div className={css.hero}>
      <div className={css.avatar}>
        <img src="/avatar.jpg" alt="avatar" />
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <div className={css.content}>
      <div className={css.name}>
        <span className={css.firstName}>Desmond</span>
        <span className={css.lastName}>Rambowski</span>
      </div>
      <div className={css.socials}>
        <span className={css.subtitle}>Web Developer & Front-end Expert</span>
        <ul className={css.links}>
          <li>
            <a href="facebook.com">
              <Facebook fontSize="large" />
            </a>
          </li>
          <li>
            <a href="instagram.com">
              <Instagram fontSize="large" />
            </a>
          </li>
          <li>
            <a href="twitter.com">
              <Twitter fontSize="large" />
            </a>
          </li>
        </ul>
      </div>

      <ul className={css.contacts}>
        <li>
          <h4>Location</h4>
          <span>Portland, OR</span>
        </li>
        <li>
          <h4>Phone</h4>
          <span>534.456.886</span>
        </li>
        <li>
          <a href="ruventhemes.com">
            <h4>Web</h4>
            <span>ruventhemes.com</span>
          </a>
        </li>
        <li>
          <a href="mailto:hello@example.com">
            <h4>Email</h4>
            <span>hello@example.com</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Hero = () => {
  return <Section header={<Header />} content={<Content />} hero={true} />;
};
