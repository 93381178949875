import { Section } from "../section";
import css from "../css/hero.module.css";
 
const { REACT_APP_API_URL } = process.env;

const Header = ({ hero }) => {
 const imageUrl = hero?.image ? `${REACT_APP_API_URL}${hero?.image}`: '/avatar.jpg'
return (
    <div className={css.hero}>
      <div className={css.avatar}>
        <img className={css.img}src={imageUrl} alt="avatar"/>
      </div>
    </div>
  );
};


export const Hero = ({ company_ }) => {
  return <Section header={<Header hero={company_} />}  hero={true} />;
}