import { Alert, AlertTitle, Autocomplete, Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Snackbar, Switch, TextField, Typography } from '@mui/material'
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_DIMENSIONS, GET_INDICATORS } from './query.gql';
import { CREATE_INDICATOR, UPDATE_INDICATOR } from './mutation.gql';
import { React, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import { useSnackbar } from '../../contexts/snackbar-context';
import i18n from '../../translate/i18n';

const IndicatorForm = ({ indicador, titleAction, handleClose, setData, list, setList }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const { setOpenAlertOK, setSuccessMessage, setSeverity } = useSnackbar()

    const viewData = titleAction === i18n.t('title.viewIndicator', { lng: selectedLanguage });
    const editData = titleAction === i18n.t('title.editIndicator', { lng: selectedLanguage });
    const createData = titleAction === i18n.t('title.indicator', { lng: selectedLanguage });

    const jwt = localStorage.getItem("jwtToken");
    const [title] = useState(i18n.t('button.save', { lng: selectedLanguage }));
    const [getDimensions] = useLazyQuery(GET_DIMENSIONS)

    const [formData, setFormData] = useState({
        indicator: indicador.indicator,
        dimension: indicador.dimensionID,
        isActive: indicador.isActive,
    });

    const [dimensionError, setDimensionError] = useState("");
    const [postRoleError, setPostRoleError] = useState("");
    const [companyValueData, setCompanyValueData] = useState(null);
    const [dimensions, setDimensions] = useState([]);
    const [postRoles, setPostRoles] = useState([]);
    const [valueData, setValueData] = useState([]);

    useEffect(() => {
        getDimensions({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            fetchPolicy: 'network-only'
        }).then(data => {
            //setDimensions(data.data.dimensions.data)
            setDimensions(data.data?.dimensions?.data?.map(pr => ({
                id: pr.id,
                name: pr.attributes.dimension,
            })));
        }).catch(error => {
            console.log(error)
        })

        if (!createData) {
            setCompanyValueData({
                id: indicador.dimensionID,
                name: indicador.dimensionName
            });
        }
    }, [])



    const [checked, setChecked] = useState(formData.isActive);
    const handleChangeState = (e) => {
        setChecked(e.target.checked)
        setFormData({ ...formData, isActive: e.target.checked })
    }

    const [createIndicator, { loading, error }] = useMutation(CREATE_INDICATOR);
    const [updateIndicator, { loading2, error2 }] = useMutation(UPDATE_INDICATOR);
    const indicatorToCheck = formData?.indicator;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (indicador.id === undefined) {
            let sameIndicator = false;
            for (const item of list) {
                if (item.attributes.indicator === indicatorToCheck) {
                    sameIndicator = true;
                    break;
                }
            }
            if (sameIndicator === true) {
                setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${indicatorToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
                setSeverity("warning")
            } else if (companyValueData === null) {
                setDimensionError(i18n.t('form.mandatory', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${i18n.t('pathway.dimension', { lng: selectedLanguage })} ${i18n.t('form.mandatory', { lng: selectedLanguage })}`);
                setSeverity("warning")
            }
            else {
                createIndicator({
                    variables: {
                        data: {
                            indicator: formData.indicator,
                            dimension: formData.dimension
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then((response) => {
                    const newIndicator = response.data.createIndicator.data;
                    setData((prevIndicators) => [newIndicator, ...prevIndicators]);
                    setOpenAlertOK(true)
                    setSuccessMessage(`${i18n.t('title.indicator', { lng: selectedLanguage })} ${i18n.t('success.create', { lng: selectedLanguage })}`);
                    setSeverity("success")
                    handleClose()
                }).catch(error => {
                    //toast.error("Ocorreu um erro ao adicionar indicador")
                    console.log(error, "Error Create")
                    setOpenAlertOK(true)
                    setSuccessMessage(i18n.t('error.createPeriod', { lng: selectedLanguage }));
                    setSeverity("error")
                })
            }

        } else {
            let sameIndicator = false;
            for (const item of list) {
                if (item.attributes.indicator === indicatorToCheck) {
                    sameIndicator = true;
                    break;
                }
            }
            if (indicatorToCheck !== indicador.indicator && sameIndicator === true) {
                setPostRoleError(i18n.t('form.duplicatedError', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${indicatorToCheck} ${i18n.t('form.duplicated', { lng: selectedLanguage })}`);
                setSeverity("warning")
            } else if (companyValueData === null) {
                setDimensionError(i18n.t('form.mandatory', { lng: selectedLanguage }))
                setOpenAlertOK(true)
                setSuccessMessage(`${i18n.t('pathway.dimension', { lng: selectedLanguage })} ${i18n.t('form.mandatory', { lng: selectedLanguage })}`);
                setSeverity("warning")
            }
            else {
                if (formData.indicator === indicador.indicator &&
                    formData.dimension === indicador.dimensionID &&
                    formData.isActive === indicador.isActive) { handleClose() }
                else {
                    updateIndicator({
                        variables: {
                            updateIndicatorId: indicador.id,
                            data: {
                                indicator: formData.indicator,
                                dimension: formData.dimension,
                                isActive: formData.isActive
                            }
                        },
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                    }).then(data => {
                        if (formData.indicator !== indicador.indicator ||
                            formData.dimension !== indicador.dimension ||
                            formData.isActive !== indicador.isActive) {
                            const updatedList = list.map(item => {
                                if (item.id === indicador.id) {
                                    return data.data.updateIndicator.data;
                                } else {
                                    return item;
                                }
                            });
                            setList(updatedList)
                            setOpenAlertOK(true)
                            setSuccessMessage(`${i18n.t('pathway.indicator', { lng: selectedLanguage })} ${i18n.t('success.update', { lng: selectedLanguage })}`);
                            setSeverity("success")
                            handleClose()
                        }
                    }).catch(error2 => {
                        //toast.error("Ocorreu um erro ao atualizar indicador!!")
                        console.log(error2, "Error Update")
                        setOpenAlertOK(true)
                        setSuccessMessage(i18n.t('error.updatePeriod', { lng: selectedLanguage }));
                        setSeverity("error")
                    })
                }
            }

        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} padding={3}>
                <Grid container spacing={2} margin={1}>
                    <Grid item xs={6}>
                        <InputLabel>{i18n.t('table.name', { lng: selectedLanguage })}</InputLabel>
                        <TextField
                            InputProps={{
                                readOnly: viewData ? true : false,
                            }}
                            variant="outlined"
                            fullWidth required
                            rows={1}
                            defaultValue={viewData ? indicador.indicator :
                                editData ? indicador.indicator : formData.indicator}
                            onChange={(e) =>
                                setFormData({ ...formData, indicator: e.target.value })
                            }
                        />
                        {postRoleError && <Typography
                            variant="body2"
                            color="red"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {postRoleError}
                        </Typography>}
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel  >{i18n.t('table.dimension', { lng: selectedLanguage })}</InputLabel>
                        <FormControl fullWidth size='small'>
                            <Autocomplete
                                readOnly={viewData ? true : false}
                                value={companyValueData}
                                onChange={(e, value) => {
                                    setCompanyValueData(value);
                                    setFormData({ ...formData, dimension: value.id });
                                }}
                                options={dimensions}
                                getOptionLabel={(option) => (option ? option.name : '')}
                                isOptionEqualToValue={(option, value) => option && option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </FormControl>
                        {dimensionError && <Typography
                            variant="body2"
                            color="red"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {dimensionError}
                        </Typography>}
                    </Grid>
                    {!createData && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                disabled={!editData ? true : false}
                                sx={{ marginLeft: 0 }}
                                value="start"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={checked}
                                        onChange={handleChangeState}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={i18n.t('table.state', { lng: selectedLanguage })}
                                labelPlacement="start"
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="center" alignItems="center" marginTop={15}>
                    <Grid item xs={3}>
                        <Button variant="outlined" size="large" fullWidth onClick={handleClose}>{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            disabled={viewData ? true : false}
                            type="submit"
                            variant="contained"
                            size="large"
                            fullWidth

                        >
                            {title}
                        </Button>

                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default IndicatorForm;
