import { Section } from "../section";
import css from "../css/education.module.css";

const Header = () => {
  return (
    <>
      <h2>Education</h2>
      <p>Lazy isn't in my vocabulary.</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      start: 2008,
      end: 2010,
      name: "WEBSTER TECH UNIVERSITY",
      major: "Master of Computer Science",
      address: "Miami, FL",
      description:
        "Consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh.",
    },
    {
      id: 2,
      start: 2003,
      end: 2008,
      name: "NINSEI UNIVERSITY",
      major: "Bachelor of Computer Science",
      address: "Tokyo, Japan",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero.",
    },
    {
      id: 3,
      start: 2000,
      end: 2003,
      name: "ACADEMY OF FINE ARTS",
      major: "Master of Arts",
      address: "Berlin, Germany",
      description:
        "Fusce lacinia arcu et nulla. Nulla vitae mauris non felis mollis faucibus. Phasellus hendrerit. Pellentesque aaliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales.",
    },
  ];

  return (
    <ul className={css.list}>
      {data.map((edu) => (
        <li key={edu.id} className={css.education}>
          <div className={css.marker}></div>
          <div className={css.wrapper}>
            <div className={css.intro}>
              <p className={css.date}>
                {edu.start} - {edu.end}
              </p>
              <h3>{edu.name}</h3>
              <p className={css.major}>{edu.major}</p>
              <p className={css.address}>{edu.address}</p>
            </div>
            <p className={css.description}>{edu.description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const Education = () => {
  return <Section header={<Header />} content={<Content />} />;
};
