
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Alert, AlertTitle, Box, Collapse, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Pagination, Snackbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Fragment, useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EvaluationPeriodDelete from './period-delete.component';
import PeriodFormTest from './period-formtest.component';
import CollaboratorView from '../Collaborator/Collaborator_View_Page/collaborator_detail_main';
import EvaluationCollaborator from './period-page-collaborator';
import CustomPagination from '../../components/TableComponents/CustomPagination';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';
import { useSnackbar } from '../../contexts/snackbar-context';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

const BorderlessCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    borderBottom: 'none', // Remove the bottom border

}))
export default function PeriodListTest({ evaluationPeriods, setEvaluationPeriods, collaborators }) {
    const { openAlertOK, successMessage, severity, handleCloseAlert } = useSnackbar()
    const { selectedLanguage } = useContext(LanguageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    // Calculate the indexes of the first and last items to display on the current page
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    // Extract the items to display on the current page
    const currentItems = evaluationPeriods.slice(firstIndex, lastIndex);

    // Calculate the total number of pages
    const totalPages = Math.ceil(evaluationPeriods.length / itemsPerPage);

    // Handle the page change event
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        // Set the current page to 1 whenever the evaluation prop changes
        setCurrentPage(1);
    }, [evaluationPeriods]);

    const [open, setOpen] = useState(false);

    const [evaluationPeriod, setEvaluationPeriod] = useState({
        id: '',
        dimension: '',
        postRole: '',
        isActive: '',
    });

    const [collaborator, setCollaborator] = useState({
        id: ""
    });

    const currentLocation = useLocation()



    const [title, setTitle] = useState("Novo Periodo")
    function LongMenu({ actionID }) {
        console.log("actionID", actionID);
        
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        function handleClickOpenView(postRoleID) {
            setOpen(true);
            setTitle(i18n.t('title.viewPeriod', { lng: selectedLanguage }))
            let data = evaluationPeriods.find(d => d.id === postRoleID)
            setEvaluationPeriod(i => {
                return {
                    id: data?.id,
                    start: data?.attributes?.start,
                    end: data?.attributes?.end,
                    description: data?.attributes?.description,
                    isActive: data?.attributes?.isActive,
                    companyID: data?.attributes?.company?.data?.id,
                    companyName: data?.attributes?.company?.data?.attributes?.name,
                    collaborator: data?.attributes?.collaborators?.data?.map(pr => ({
                        id: pr?.id,
                        name: pr?.attributes?.name
                    })),
                }
            })

        };

        
        function handleClickOpenEdit(postRoleID) {

            setOpen(true);
            setTitle(i18n.t('title.editPeriod', { lng: selectedLanguage }))
            setEvaluationPeriod(i => {
                return {
                    id: data?.id,
                    start: data?.attributes?.start,
                    end: data?.attributes?.end,
                    description: data?.attributes?.description,
                    isActive: data?.attributes?.isActive,
                    companyID: data?.attributes?.company?.data?.id,
                    companyName: data?.attributes?.company?.data?.attributes?.name,
                    collaborator: data?.attributes?.collaborators?.data?.map(pr => ({
                        id: pr?.id,
                        name: pr?.attributes?.name
                    })),
                }
            })
            let data = evaluationPeriods.find(d => d.id === postRoleID)

        };

        function handleClickDelete(evaluationPeridID) {

            setOpen(true)
            setTitle(i18n.t('title.confirm', { lng: selectedLanguage }))
            let data = evaluationPeriods.find(d => d.id === evaluationPeridID)
            setEvaluationPeriod(evaluationPeridID)

        };

        return (
            <>
                <IconButton aria-label="more" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickOpenView(actionID)}>
                        <VisibilityOutlinedIcon color='primary' style={{ marginRight: '0.4rem' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickOpenEdit(actionID)}>
                        <EditOutlinedIcon color='primary' style={{ marginRight: '0.4rem' }} /> {i18n.t('options.edit', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickDelete(actionID)}>
                        <ClearOutlinedIcon color='primary' style={{ marginRight: '0.4rem' }} /> {i18n.t('options.delete', { lng: selectedLanguage })}
                    </MenuItem>

                </Menu>
            </>
        );
    }

    function handleClose() {
        setOpen(false);
    };
    
    const [openActions, setOpenActions] = useState([]);

    const handleClickOpenAction = (index) => {
        setOpenActions((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    function handleCollaboratorView(collaboratorID) {
        const collaborator = collaborators.find((item) => item.id === collaboratorID);

        if (collaborator) {
            setOpen(true);
            setTitle("Collaborator")
            setCollaborator({
                id: collaborator.id,
                userID: collaborator.attributes?.users_permissions_user?.data?.id,
                name: collaborator.attributes?.name,
                email: collaborator.attributes?.email,
                phone: collaborator.attributes?.phone,
                address: collaborator.attributes?.address,
                web: collaborator.attributes?.web,
                intro: collaborator.attributes?.intro,
                country: collaborator.attributes?.country?.data?.attributes?.name,
                countryID: collaborator.attributes?.country?.data?.id,
                city: collaborator.attributes?.city?.data?.attributes?.name,
                cityID: collaborator.attributes?.city?.data?.id,
                postRole: collaborator.attributes?.postRole?.data?.attributes?.postRole,
                postRoleID: collaborator.attributes?.postRole?.data?.id,
                postRoleDescription: collaborator.attributes?.postRole?.data?.attributes?.description,
                company: collaborator.attributes?.company?.data?.attributes?.name,
                companyID: collaborator.attributes?.company?.data?.id,
                avatar: collaborator.attributes?.image?.data?.attributes?.url,
                avatarID: collaborator.attributes?.image?.data?.id,
                score: collaborator.attributes?.evaluation_scores?.data?.map((score) => {
                    return {
                        id: score.id,
                        rateLength: score.attributes?.evaluation_answer_closeds?.data.length,
                        rateTotal: score.attributes?.evaluation_answer_closeds?.data.reduce(
                            (accumulator, currentScore) => accumulator + currentScore.attributes.rate,
                            0
                        ),
                        company: score.attributes?.company?.data?.attributes?.name,
                        companyID: score.attributes?.company?.data?.id
                    };
                }),
                profiles: collaborator.attributes?.profiles?.data?.map((profile) => {
                    return {
                        id: profile.id,
                        urlProfile: profile.attributes?.urlProfile,
                        description: profile.attributes?.description,
                        platform: profile.attributes?.platform?.data?.attributes?.name,
                        platformID: profile.attributes?.platform?.data?.id,
                        icon: profile.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url
                    };
                }),
            });
        }
    }

    return (
        <Paper component={Paper} sx={{ padding: 1, marginBottom: 10 }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell><IconButton style={{ visibility: "hidden", marginLeft: "0.8rem" }} /> {i18n.t('table.description', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.startDate', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.endDate', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.company', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell align="right">
                                <div style={{ marginRight: "1.5rem" }}>{i18n.t('table.action', { lng: selectedLanguage })}</div>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems && currentItems?.map((row, index) => (
                            <Fragment key={row.id}>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleClickOpenAction(index)}
                                        >
                                            {openActions[index] ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </IconButton>
                                        {row?.attributes?.description || 'N/A'}
                                    </StyledTableCell>
                                    <StyledTableCell>{row?.attributes?.start || 'N/A'}</StyledTableCell>
                                    <StyledTableCell>{row?.attributes?.end || 'N/A'}</StyledTableCell>
                                    <StyledTableCell>{row?.attributes?.company?.data?.attributes?.name || 'N/A'}</StyledTableCell>
                                    <StyledTableCell>{row?.attributes?.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || 'N/A'}</StyledTableCell>

                                    <StyledTableCell align="right">
                                        <div style={{ marginRight: "1.5rem" }}>
                                            <LongMenu actionID={row.id} />
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {openActions[index] && <StyledTableRow key={row.id}>
                                    <StyledTableCell colSpan={6}>
                                        <Collapse in={openActions[index]} timeout="auto" unmountOnExit>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><IconButton style={{ visibility: "hidden" }} /> Name</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell>Phone</TableCell>
                                                        <TableCell>Role</TableCell>
                                                        <TableCell></TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row?.attributes?.collaborators?.data?.map((collaborator) => (
                                                        <TableRow key={collaborator.id}>
                                                            <BorderlessCell component="th" scope="row">
                                                                <IconButton style={{ visibility: "hidden", marginLeft: "0.2rem" }} />
                                                                {collaborator.attributes.name || 'N/A'}
                                                            </BorderlessCell>
                                                            <BorderlessCell>{collaborator.attributes.email || 'N/A'}</BorderlessCell>
                                                            <BorderlessCell>{collaborator.attributes.phone || 'N/A'}</BorderlessCell>
                                                            <BorderlessCell>{collaborator.attributes.postRole.data.attributes.postRole || 'N/A'} - {collaborator.attributes.postRole.data.attributes.description || 'N/A'}</BorderlessCell>
                                                            <BorderlessCell align="right">
                                                                <div style={{ marginRight: "0.7rem" }}>
                                                                    <IconButton aria-label="delete" size="small" onClick={() => handleCollaboratorView(collaborator.id)}>
                                                                        <VisibilityOutlinedIcon color="primary" />
                                                                    </IconButton>
                                                                </div>

                                                            </BorderlessCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Collapse>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='md'
                sx={{ border: 2, borderColor: "#F7931E" }}
            >
                <DialogTitle display="flex" justifyContent="center" alignItems="center">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {title === i18n.t('title.confirm', { lng: selectedLanguage }) ? <EvaluationPeriodDelete
                        handleClose={handleClose}
                        evaluationPeriod={evaluationPeriod}
                        setList={setEvaluationPeriods}
                        list={evaluationPeriods}
                    /> :
                        title === "Collaborator" ? <EvaluationCollaborator data={collaborator} handleClose={handleClose} /> :
                            currentLocation.pathname === '/period' ? <PeriodFormTest
                                evaluationPeriod={evaluationPeriod}
                                titleAction={title}
                                handleClose={handleClose}
                            /> : ""}
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openAlertOK}
                autoHideDuration={5000}
                onClose={handleCloseAlert}
            >
                <Alert severity={severity} onClose={handleCloseAlert}>
                    <AlertTitle>{
                        severity === 'success' ? i18n.t('alert.success', { lng: selectedLanguage }) :
                            severity === 'warning' ? i18n.t('alert.warning', { lng: selectedLanguage }) :
                                severity === 'info' ? i18n.t('alert.info', { lng: selectedLanguage }) :
                                    i18n.t('alert.error', { lng: selectedLanguage })}
                    </AlertTitle>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Paper>

    );
}
