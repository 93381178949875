import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import './index.css';
import App from "./App";
import { createUploadLink } from "apollo-upload-client"; 
// import "./i18next";
 
const { REACT_APP_API_URL } = process.env;
const customMerge = (existing, incoming) => {
  const mergedData = {
    ...existing,
    ...incoming,
  };
  return mergedData; // or any other merge logic you want to apply
};

export const client = new ApolloClient({
  link: createUploadLink({
    uri: `${REACT_APP_API_URL}/graphql`
  }),
  uri: `${REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache(
    {
    typePolicies: {
      ...createTypePolicies('CountryEntity'),
      ...createTypePolicies('CompanyEntity'),
      ...createTypePolicies('CollaboratorEntity'),
      ...createTypePolicies('PortfolioEntity'),
      ...createTypePolicies('ProfilePlatformEntity'),
      ...createTypePolicies('UsersPermissionsUserEntity'),
      ...createTypePolicies('ExpertiseCategoryEntity'),
      ...createTypePolicies('UploadFileEntity'),
      ...createTypePolicies('PostRoleEntity'),
      ...createTypePolicies('AwardEntity'),
      ...createTypePolicies('EducationEntity'),
      ...createTypePolicies('ProfileEntity'),
      ...createTypePolicies('DimensionEntity'),
      ...createTypePolicies('UserRoleEntity'),
      ...createTypePolicies('EvaluationPeriodEntity'),
      ...createTypePolicies('EvaluationQuestionClosedEntity'),
      ...createTypePolicies('EvaluationAnswerClosedEntity'),
      ...createTypePolicies('EvaluationAnswerOpenEntity'),
      ...createTypePolicies('EvaluationQuestionOpenEntity'),
      ...createTypePolicies('EvaluationScoreEntity'),
      ...createTypePolicies('IndicatorEntity'),
    },
  }
  ),
});

function createTypePolicies(entityName) {
  return {
    [entityName]: {
      fields: {
        attributes: {
          merge: customMerge,
        },
      },
    },
  };
}
ReactDOM.render(

  <ApolloProvider client={client}>
    <Suspense fallback={<div>Loading ...</div>}> 
    <App />
    </Suspense>
  </ApolloProvider>
  ,
  document.getElementById("root")
);
