import { gql } from "@apollo/client";
export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        users_permissions_user{data{id attributes{username confirmed}}} 
        name
        email
          evaluations { 
          data {
            id
            attributes {
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }
            }
          }
  }
        
        



        phone
        web
        intro
        address
        rating
        speciality
        evaluations { 
          data {
            id
            attributes {
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }
            }
          }
  }
        postRole { 
          data {
            id
            attributes {
              postRole
              description
            }
          }
        }
        city { 
          data {id  attributes { name } }
        }
        country { 
          data { id attributes { name } }
        }
        image { 
          data { attributes { url } }
        }
        experiences(pagination:{pageSize:20}) { 
          data { 
            attributes { 
              description
              startDate
              endDate
              postRole { 
                data { attributes { postRole description } }
              }
              expEntity { 
                data { attributes { name } }
              }
              country { 
                data { attributes { name } }
              }
 }
          }
        }
        educations(pagination:{pageSize:20}) { 
          data { 
            attributes { 
              description
              startDate
              endDate
              course { 
                data { attributes { name } }
              }
              faculty { 
                data { attributes { name } }
              }
              country { 
                data { attributes { name } }
              }
            }
          }
        }
        expertise { 
          data { 
            attributes { 
              description
              expertise { 
                data { attributes { name } }
              }
            }
          }
        }
  skills { 
          data { 
            attributes { 
              percentual
              itTool { 
                data { attributes { name } }
              }
            }
          }
        }
        profiles { 
          data { 
            attributes { 
              urlProfile
              description
              platform { 
                data { 
                  attributes { 
                    name
                    icon { 
                      data { attributes { url } }
                    }
                  }
                }
              }
            }
          }
        }
        awards { 
          data { attributes { name description } }
        }
  portfolios { 
          data { attributes { name description image { data { attributes { url previewUrl } } } } }
        }
        client { 
          data { attributes { companies { data { id attributes { name image { data { attributes { url } } } } } } } }
        }
        users_permissions_user { 
          data { id attributes { username } }
        }
        companies { 
          data { id attributes { name email   users_permissions_user { 
          data { id attributes { username } }
        }} }
        }
        isActive 
      }
}
}
}
`;

export const GET_INDICATORS = gql`
  query Indicators {
    indicators(pagination: {pageSize: 1000 }) {
      data {
        id
        attributes {
          indicator 
          evaluation_question_opens{data{id attributes{question isActive type
            evaluation_answer_opens{data{id attributes{answer }}}}}}         
          evaluation_question_closeds{data{id attributes{question isActive type evaluation_answer_closeds{data{id attributes{answer rate}}}}}}
          
        }
      }
    }
  }
`;
export const GET_COMPANIES = gql`
query Companies($pagination: PaginationArg) {
  companies(pagination: $pagination  )  {
    data  {
      id
      attributes {
        evaluation_periods{data{id attributes{start end isActive collaborators{data{id attributes{name}}}}}}   
        collaborators{data{attributes{ name intro}}}
        status
        name     
        users_permissions_user{data{id attributes{username confirmed}}} 
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        email
        phone
        branchOfActivity
        image{data{id attributes{url}}}
        findDevs {
          data {
            id
            attributes {
              description
              }}}}}}}
`;
export const EVAL_PERIODS=gql`
query EvaluationPeriods {
  evaluationPeriods{
  
    data {
      id
      attributes {
        description
        start
        end
        isActive
        companies{data{id attributes{name users_permissions_user{data{id }}}}}
        collaborators{data{id attributes{name users_permissions_user{data{id }}}}}
      }
    }
  }
}

`
export const EVAL_PERIODS_BY_ID=gql`
query evaluationPeriodsByCompany($id:ID!) {
  evaluationPeriods(filters:{isActive:{eq:true},companies:{id:{eq:$id}}}) {
   meta{pagination{total}}
    data {
      id
      attributes {
        companies{data{id attributes{
          evaluation_periods {
          data {
            id
            attributes {collaborators{data{id }}
          
          }
          }
        }}}}
        description
        start
        end
      collaborators{data{id attributes{     
          name
          email
          phone
          web
          intro
          address        
          postRole{data{id attributes{postRole description}}}
          companies{data{id attributes{name }}}
          image{data{id attributes{url}}} }}}
      }
    }
  }
}
`
export const EVAL_SCORES_COLLABORATOR=gql`
query evaluationScore($id:ID!) {
  evaluationScores(filters:{collaborator:{id:{eq:$id}}}) {
    data {
      id 
      attributes { 
        evaluation_answer_opens{data{id attributes{answer evaluation_question_open{data{id attributes{question}}}}}}      
        evaluation_period {
          data {
            id
            attributes {
              description
            }
          }
        }
      }
    }
  }
}
`
export const EVAL_PERIODS_COLLABORATOR=gql`
query evaluationPeriod($id:ID!) {
  evaluationPeriod(filters:{collaborator:{id:{eq:$id}}}) {
    data {
      id
      attributes { 
       description
      }
       
     }}  
}
` 
export const EVAL_OPEN_ANSWERS=gql`
query evaluationAnswerOpens{
  evaluationAnswerOpens {
    data {
      id
      attributes {
        answer
      }
    }
  }
}
`
 export const EVAL_SCORES_COMPANY=gql`
query evaluationScore($id:ID!) {
  evaluationScores(filters:{company:{id:{eq:$id}}}) {
    data {
      id 
      attributes { 
        evaluation_answer_opens{data{id attributes{answer evaluation_question_open{data{id attributes{question}}}}}} 
        collaborator{data{id}}
        evaluation_period {
          data {
            id
            attributes {
              description
            }
          }
        }
      }
    }
  }
}
`