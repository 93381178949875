import { useMutation } from "@apollo/client";
import { DELETE_PERIOD, DELETE_POST_ROLE } from "./mutation.gql";
import { Button, Grid, Typography } from "@mui/material";
import { Hero } from "../Collaborator/Collaborator_View_Page/sections/hero";


const EvaluationCollaborator = ({ data, handleClose }) => {

    const collaborator = data
    const profiles = data.profiles


    return (
        <>
            <Grid container style={{ marginTop: '-10%' }}>
                <Hero collaborator={collaborator} profiles={profiles} />
                <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" >
                    <Grid item xs={2} sm={2} md={1} >
                        <Button variant="contained" size="small" onClick={handleClose}>Close</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
};
export default EvaluationCollaborator;