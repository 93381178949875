import { gql } from "@apollo/client";

export const GET_EMPLOYEER_DATA = gql`
query UsersPermissionsUser($usersPermissionsUserId: ID) {
  usersPermissionsUser(id: $usersPermissionsUserId) {
    data {
      id
      attributes {
        email
        name
        postRole {data {id attributes {postRole description}}}
        userRole {data {id attributes {role description}}}
        image{data{id attributes{url}}}
      }
    }
  }
}
`;