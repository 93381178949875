import { useMutation } from "@apollo/client";
import { DELETE_PERIOD, DELETE_POST_ROLE } from "./mutation.gql";
import { Alert, AlertTitle, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import i18n from "../../translate/i18n";
import { useSnackbar } from "../../contexts/snackbar-context";

const EvaluationPeriodDelete = ({
    evaluationPeriod,
    handleClose,
    setList,
    list }) => {
    const { setOpenAlertOK, setSuccessMessage, setSeverity} = useSnackbar()
    const { selectedLanguage } = useContext(LanguageContext);
    const [deletePeriod] = useMutation(DELETE_PERIOD);
    const jwt = localStorage.getItem('jwtToken')

    const handleDelete = (event) => {
        event.preventDefault()
        deletePeriod({
            variables: { id: evaluationPeriod },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
            .then((response) => {
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('success.deletePeriod', { lng: selectedLanguage }));
                setSeverity("success")
                const updatedData = list.filter(obj => obj.id !== evaluationPeriod);
                setList(updatedData);
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('error.deletePeriod', { lng: selectedLanguage }));
                setSeverity("error")
            });
    };


    return (
        <form onSubmit={handleDelete} >
            <Grid container width={400}>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography>{i18n.t('title.delete', { lng: selectedLanguage })}</Typography>
                </Grid>
                <Grid container spacing={2} paddingTop={3} display="flex" justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Button onClick={handleClose} variant="outlined" size="small">{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" size="small" onClick={handleDelete}>{i18n.t('button.confirm', { lng: selectedLanguage })}</Button>
                    </Grid>
                </Grid>
            </Grid>

        </form>
    )
};
export default EvaluationPeriodDelete;