import { useMutation } from '@apollo/client';
import { DELETE_INDICATOR } from './mutation.gql';
import { Button, Grid, Typography } from '@mui/material';

const IndicatorDelete = ({ indicator, handleClose, setList, list }) => {
    const [deleteIndicator] = useMutation(DELETE_INDICATOR);
    const jwt = localStorage.getItem("jwtToken")

    const handleDelete = (event) => {
        event.preventDefault()
        deleteIndicator({
            variables: { id: indicator.id },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
            .then((response) => {
                console.log(response.data.deleteIndicator.data);
                const updatedData = list.filter(obj => obj.id !== indicator.id );
                setList(updatedData);
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <form onSubmit={handleDelete}  >
            <Grid container  width={400}>
                <Grid  item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography>Tem certeza que pretende eliminar?</Typography>
                </Grid>
                <Grid container spacing={2} paddingTop={3} display="flex" justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Button variant="outlined" size="small" onClick={handleClose}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" size="small" onClick={handleDelete}>Confirmar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default IndicatorDelete;
