import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import i18n from '../../translate/i18n';
import { LanguageContext } from '../../translate/languages/LanguageContext';

const Pathway = ({setPathView,pathView}) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
   
   
  const pathwayContainerStyle = {
    paddingBottom: "1.1rem" // Adjust the padding value according to your needs
  };

  const getTranslatedSegment = (segment) => {
    return i18n.t(`pathway.${segment}`, { lng: selectedLanguage });
  };
useEffect(()=>{
  pathSegments.map((segment, index) => {
    const capitalizedSegment = getTranslatedSegment(segment);
    if(pathView)
    setPathView(capitalizedSegment)

  })
 
 
},[])
 
  return (
    <div style={pathwayContainerStyle}>
      <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
        {i18n.t('tittles.dashboard', { lng: selectedLanguage })}
      </Link>
      
      {pathSegments.map((segment, index) => {
        const isLastSegment = index === pathSegments.length - 1;
        const capitalizedSegment = getTranslatedSegment(segment);

        if (isLastSegment) {
          return (
            <React.Fragment key={segment}>
              <span> / </span>
              <span style={{ color: 'var(--primary)' }}>{capitalizedSegment}</span>
            </React.Fragment>
          );
        }
        
   
        return (
          <React.Fragment key={segment}>
            <span> / </span>
            <Link style={{ color: 'inherit', textDecoration: 'none' }} to={`/${segment}`}>
              {capitalizedSegment}
            </Link>
          </React.Fragment>
        );
      })}


 {pathView?.includes('/')? <span style={{ color: 'var(--primary)' }}>{pathView}</span>:''}
      
    </div>
  );
};

export default Pathway;
