import { Alert, AlertTitle, Button, FormControlLabel, Grid, InputLabel, Snackbar, Switch, TextField } from '@mui/material'
import React from 'react'
import { CREATE_COMPANY_QUESTION, EDIT_COMPANY_QUESTIONS } from './mutation.gql';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const QuizFormTeste = ({ quiz, titleAction, handleClose, setData }) => {

    const jwt = localStorage.getItem("jwtToken")
    const [quizzes, setQuizzes] = useState([])
    const [title, setTitle] = useState("Guardar")
    const [openAlertOK, setOpenAlertOK] = useState(false)

    const [formData, setFormData] = useState({
        name: quiz.name,
        isActive: quiz.isActive,
    });

    const [createCompanyQuestion, { loading, error }] = useMutation(CREATE_COMPANY_QUESTION);
    const [updateCompanyQuestion, { loading2, error2 }] = useMutation(EDIT_COMPANY_QUESTIONS);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (quiz.id === undefined) {
            createCompanyQuestion({
                variables: {
                    data: {
                        name: formData.name
                    },
                },
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
            }).then((response) => {
                console.log(response.data.createCompanyQuestion.data);
                setOpenAlertOK(true)
                const newQuizz = response.data.createCompanyQuestion.data;
                setData((prevQuizzes) => [newQuizz, ...prevQuizzes]);
                handleClose();
            }).catch(error => {
                toast.error("Ocorreu um erro ao adicionar pergunta")
            })
        } else {
            updateCompanyQuestion({
                variables: {
                    id: quiz.id,
                    data: {
                        name: formData.name,
                        isActive: formData.isActive
                    }
                },
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
            }).then(data => {

                if (formData.name !== quiz.name ||
                    formData.isActive !== quiz.isActive) {
                    let obj = quizzes.filter(i => i.id === quiz.id)
                    obj.push(data.data.updateCompanyQuestion.data)
                    setQuizzes(obj);
                    setOpenAlertOK(true)
                    handleClose();
                }
            }).catch(error2 => {
                toast.error("Ocorreu um erro ao atualizar pergunta!!")
            })
        }

    };

    const handleCloseAlert = () => {
        setOpenAlertOK(false);
    };

    const [checked, setChecked] = useState(true);
    const handleChangeState = (e) => {
        setChecked(e.target.checked)
        setFormData({ ...formData, isActive: e.target.checked })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={2} margin={1} marginLeft={3}>
                    <InputLabel required id="demo-simple-select-label">Perguntas</InputLabel>
                    <TextField required id="filled-basic" variant="outlined" fullWidth
                        disabled={titleAction == "Ver Pergunta" ? true : false}
                        defaultValue={titleAction === "Ver Pergunta" ? quiz.quiz :
                            titleAction === "Editar Pergunta" ? quiz.quiz : formData.name}
                        onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                        }
                    />
                </Grid>
                {titleAction !== "Nova Pergunta" && <Grid item xs={12} marginBottom={15}>
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Estado"
                        labelPlacement="start"
                        checked={titleAction === "Nova Pergunta" ? true :
                            titleAction === "Ver Pergunta" ? quiz.isActive : formData.isActive}
                        onChange={handleChangeState}
                        sx={{ marginLeft: 1 }}
                        disabled={titleAction !== "Editar Pergunta" ? true : false}
                    />
                </Grid>}
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="right" alignItems="center" marginTop={10} marginRight={3} marginBottom={5}>
                    <Grid item xs={2} marginRight={2}>
                        <Button variant="outlined" size="medium" onClick={handleClose}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            disabled={titleAction === "Ver Pergunta"}
                            type="submit"
                            variant="contained"
                            size="medium"
                            onClick={handleCloseAlert}
                        >
                            {title}
                        </Button>

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={openAlertOK}
                            autoHideDuration={5000}
                            onClose={handleCloseAlert}
                        >
                            <Alert severity="success">
                                <AlertTitle>Successo</AlertTitle>
                                {titleAction === "Nova Pergunta" ? "Pergunta adicionado" : "Pergunta atualizado"}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default QuizFormTeste