import React, { useContext, useDebugValue, useEffect, useState } from "react";
import PortugalFlag from './portugal-flag.png';
import EnglandFlag from './england-flag.png';
import i18n from "../i18n";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";


const I18N_STORAGE_KEY = 'i18nextLng';

const LanguageSelector = ({ location }) => {
    const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
    const loc = useLocation(location)

    const handleLanguageChange = (event) => {
        event.preventDefault();
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        localStorage.setItem(I18N_STORAGE_KEY, newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem(I18N_STORAGE_KEY);
        if (savedLanguage) {
            const primaryLanguage = savedLanguage.includes('-') ? savedLanguage.split('-')[0] : savedLanguage;
            setSelectedLanguage(primaryLanguage);
            i18n.changeLanguage(primaryLanguage);
        }
    }, []);
    

    return (
        <>
            <FormControl sx={{ minWidth: 7, minHeight: 2, backgroundColor: '#F5F4F7' }}>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    sx={{ maxWidth: 88, maxHeight: 40 }}
                >
                    <MenuItem value="pt">
                        <img src={PortugalFlag} alt="Portugal Flag" style={{ width: 34, height: 36, marginTop: 5, marginRight: 5 }} />
                    </MenuItem>
                    <MenuItem value="en">
                        <img src={EnglandFlag} alt="England Flag" style={{ width: 34, height: 50, marginTop: 5, marginRight: 5 }} />
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default LanguageSelector;