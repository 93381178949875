import { useRef } from "react";
import css from "./css/popup.gallery.module.css";

export const PopupGallery = ({
  photoList,
  index = 0,
  setIndex = () => {},
  visible = false,
  setVisibility = () => {},
}) => {
  const { src, caption } = photoList[index];
  const ref = useRef();

  const close = (e) => {
    if (e.target.name === ref.current) {
      setVisibility(false);
    }
  };

  return (
    visible && (
      <div name="overlay" className={css.overlay} role="button" onClick={close}>
        <div className={css.popup}>
          <button className={css.left} onClick={() => setIndex(index - 1)}>
            &lt;
          </button>
          <div className={css.photo}>
            <img src={src} alt={caption} />
          </div>
          <button className={css.right} onClick={() => setIndex(index + 1)}>
            &gt;
          </button>
          <p className={css.caption}>{caption}</p>
        </div>
      </div>
    )
  );
};
