import { gql } from "@apollo/client";

export const CREATE_COMPANY_QUESTION = gql `
mutation CreateCompanyQuestion($data: CompanyQuestionInput!) {
  createCompanyQuestion(data: $data) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}
 `;

export const EDIT_COMPANY_QUESTIONS = gql `
mutation UpdateCompanyQuestions($id:ID!,$data:CompanyQuestionInput!) {
  updateCompanyQuestion(id:$id,data:$data) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}
`;

export const DELETE_COMPANY_QUESTIONS = gql `
mutation DeleteCompanyQuestions($id:ID!) {
    deleteCompanyQuestion(id:$id) {
      data {
        id
        attributes {
          name
          isActive
        }
      }
    }
}
`;