import React, { createContext, useState } from "react";

export const LanguageContext = createContext();
const I18N_STORAGE_KEY = 'i18nextLng';

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem(I18N_STORAGE_KEY)
  );

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
