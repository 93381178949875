import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../contexts/auth-context";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Modal, Box, Grid, Typography, TextField, Button } from "@mui/material";
import { GET_COMPANY_QUESTIONS, GET_COUNTRY, GET_EMPLOYEER_DATA_FOR_QUESTIONS } from "./query.gql";
import { GET_COMPANIES, GET_COMPANY_ANSWER } from "./query.gql";
import { CREATE_COMPANY_ANSWER } from "./mutation.gql";
import { useNavigate } from "react-router-dom";
import { client } from "../..";
import LoadingCircle from "../Loading/loading_circle";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import i18n from "../../translate/i18n";
import { UserRoleContext } from "../../contexts/userRole-context";
import CustomPagination from "../TableComponents/CustomPagination";

const validationEmailForm = yup.object({}).required();

export default function CompanyQuestion({ children, user, logout }) {
  const navigate = useNavigate();

  const { selectedLanguage } = useContext(LanguageContext);
  const { uRoleContext } = useContext(UserRoleContext);

  const companyQuestions = useQuery(GET_COMPANY_QUESTIONS);
/*   const companyAnswers = useQuery(GET_COMPANY_ANSWER, {
    variables: {
      filters: {
        company: {
          users_permissions_user: {
            id: {
              containsi: user.id,
            },
          },
        },
      },
    },
  }); */
  const companyAnswers = useQuery(GET_COMPANY_ANSWER, {
    variables: {
      filters: {
        company: user.companyId, // A variável `companyId` do usuário
      },
    },
  });
  
  const company = useQuery(GET_COMPANIES, {
    variables: {
      filters: {
        users_permissions_user: {
          id: {
            eq: user.id,
          },
        },
      },
    },
  });
  const [createCompanyAnswer] = useMutation(CREATE_COMPANY_ANSWER);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });
  const update = async (q) => {
    await client.refetchQueries({
      include: [q],
    });
  };

  const normalScreenStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const smallScreenStyle = {
    "@media (max-width: 600px)": {
      width: 300,
    },
  };

  // Merge the styles
  const style = { ...normalScreenStyle, ...smallScreenStyle };

  const questionsForm = (dataForm) => {
    console.log(dataForm)
    companyQuestions?.data?.companyQuestions?.data.map((q, i) => {
      createCompanyAnswer({
        variables: {
          data: {
            name: dataForm[`${q.id}`],
            company_question: q.id,
            company: company?.data?.companies?.data[0]?.id,
            findDevs: company?.data?.companies?.data[0]?.attributes?.findDevs?.data[0]?.id
          },
        },
      })
        .then((data) => {
          if (companyQuestions?.data?.companyQuestions?.data?.length === i + 1)
            update(GET_COMPANY_ANSWER);
        })
        .catch((error) => {
          alert(` The Question "${q.attributes.name}" was not uploaded!!`);
        });
    });
  };

  /*           Close the modal            */

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /****************************************/

  const pageSize = 5;
  const totalPages = Math.ceil(
    companyQuestions?.data?.companyQuestions?.data?.length / pageSize
  );

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
}
  const paginatedQuestions = companyQuestions?.data?.companyQuestions?.data?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
console.log(currentPage)
console.log(totalPages)

  if (companyQuestions?.loading)
    return (
      <LoadingCircle message={i18n.t('loading.loading', { lng: selectedLanguage })} />
    );

  if (companyQuestions?.data)
    if (companyAnswers?.data?.companyAnswers?.data) {
      if (companyAnswers?.data?.companyAnswers?.data?.length === 0 && uRoleContext === "company")
        return (
          <>
            <Modal
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  sx={{ mb: 5 }}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {i18n.t('dashboard.questions', { lng: selectedLanguage })}
                </Typography>

                <form onSubmit={handleSubmit(questionsForm)}>
                  <Grid container spacing={2}>
                    {paginatedQuestions.map(
                      (q, i) => {
                        return (
                          <Grid key={q.id} item xs={12}>
                            <Typography
                              children={q.attributes?.name}
                              variant="body2"
                            />
                            <TextField
                              name={q.id}
                              id={q.id}
                              {...register(q.id)}
                              required
                              fullWidth
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                  <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                <Grid container /* xs={12} */ sx={{ pt: 2 }} justifyContent="space-between">
                  <Grid item>
                    <Button onClick={logout} color="primary">
                      {i18n.t('options.sair', { lng: selectedLanguage })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button 
                    disabled={currentPage !=totalPages}
                    type="submit" size="small" variant="contained" onClick={handleOpen}>
                      {i18n.t('options.submit', { lng: selectedLanguage })}
                    </Button>
                  </Grid>
                </Grid>
                </form>


              </Box>
            </Modal>
            <div /* style={{ filter: "blur(5px)" }} */>{children}</div>
          </>
        );
      else
        return (
          <>
            {" "}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} textAlign="center">
                <img src="check.svg" />
                <Typography
                  sx={{ mb: 5 }}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  children="Success"
                />
                <Typography
                  sx={{ mb: 5 }}
                  id="modal-modal-description"
                  variant="h6"
                  component="h6"
                >
                  {i18n.t('dashboard.firstWelcome', { lng: selectedLanguage })}
                </Typography>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  children="Close"
                />
              </Box>
            </Modal>
            <div /* style={{ filter: "blur(5px)" }} */>{children}</div>
          </>
        );
    } else
      return (
        <LoadingCircle message={i18n.t('loading.loading', { lng: selectedLanguage })} />
      );
  if (companyQuestions?.error)
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ mb: 5 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {i18n.t('dashboard.error', { lng: selectedLanguage })}
          </Typography>
        </Box>
      </Modal>
    );
}
