import { Grid } from "@mui/material";
import SearchAddBar from "../../components/TopBar/search-add-bar.component";
import DimensionListTest from "./dimension-listtest";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_DIMENSIONS } from "./query.gql";
import Pathway from "../../components/TopBar/pathway";
import SnackbarComponent from "../../components/Cards/snackbarComponent";

const DimensionPage = () => {
  const jwt = localStorage.getItem("jwtToken");
  const [dimensions, setDimensions] = useState([])
  const [filteredList, setFilteredList] = useState('');

  const [getDimensions, { loading, data }] = useLazyQuery(GET_DIMENSIONS, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getDimensions();
  }, []);

  useEffect(() => {
    if (data) {
      const { dimensions } = data;
      setDimensions(dimensions.data);

    }
  }, [data]);


  const filteredSearched = dimensions.filter((item) => {
    return item.attributes.dimension.toLowerCase().includes(filteredList.toLowerCase())
  });

  return (
    <Grid container maxWidth="lg" style={{ margin: "0 auto" }}  >
      <Grid item xs={12}>
        <Pathway />
        <SearchAddBar
          searched={filteredList}
          setSearched={setFilteredList}
          data={dimensions}
          setData={setDimensions}
        />
      </Grid>
      <Grid container maxWidth="lg" style={{ margin: "0 auto" }}>
        <Grid item xs={12} >
          <DimensionListTest
            filteredSearched={filteredSearched}
            dimensions={dimensions}
            setDimensions={setDimensions}
          />
        </Grid>
      </Grid>
      <SnackbarComponent />
    </Grid>
  );
}

export default DimensionPage;