import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material";


const ChoosenCollaborator = ({ open, handleClose, collaboratorID }) => {
  const [loadingCheck, setLoadingCheck] = useState(false);

  const enviarSolicitacao = () => {
    alert('Enviando a solicitacao');
    setLoadingCheck(!loadingCheck);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="associate-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
          {loadingCheck ? 'Enviando solicitação...' : 'Enviar solicitação'}
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {loadingCheck ? (
            <CircularProgress />
          ) : (
            <>
              <button
                type="button"
                onClick={enviarSolicitacao}
                className="outline-none bg-[#F9AE00] hover:opacity-70 cursor-pointer rounded-[0.25rem] text-white py-[0.5rem] px-[0.75rem] text-center inline-block w-[60%] mx-auto"
              >
                Solicitar contratação
              </button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChoosenCollaborator;