import MainCard from "../../components/MainCard/main-card.component";
import { ListItem } from "./item";
import css from "./list.module.css";

const CurriculumListPage = () => {
  const data = [
    {
      picture: "/avatar.jpg",
      name: "Desmond Rambowski",
      title: "Web Developer & Front-end Expert",
      address: "Portland, OR",
    },
    {
      picture: "/avatar.jpg",
      name: "Desmond Rambowski",
      title: "Web Developer & Front-end Expert",
      address: "Portland, OR",
    },
    {
      picture: "/avatar.jpg",
      name: "Desmond Rambowski",
      title: "Web Developer & Front-end Expert",
      address: "Portland, OR",
    },
    {
      picture: "/avatar.jpg",
      name: "Desmond Rambowski",
      title: "Web Developer & Front-end Expert",
      address: "Portland, OR",
    },
    {
      picture: "/avatar.jpg",
      name: "Desmond Rambowski",
      title: "Web Developer & Front-end Expert",
      address: "Portland, OR",
    },
  ];

  return (
    <MainCard title="Lista de Currículos">
      <ul className={css.list}>
        {data.map((cur, i) => (
          <ListItem item={cur} key={i} />
        ))}
      </ul>
    </MainCard>
  );
};

export default CurriculumListPage;
