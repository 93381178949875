import { Section } from "../section";

import css from "../css/expertise.module.css";

const Header = () => {
  return (
    <>
      <h2>EXPERTISE</h2>
      <p>Batman would be jealous.</p>
    </>
  );
};

const Content = () => {
  return (
    <div className={css.wrapper}>
      <ol className={css.expertise}>
        <li>
          <h3>Advanced CSS</h3>
          <p>
            Cras ornare tristique elit lorem ipsum dolor sit amet, consectetuer
            adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec
            urna. In nisi neque, aliquet vel, dapibus id.
          </p>
        </li>
        <li>
          <h3>Front-End Design</h3>
          <p>
            Sed adipiscing ornare risus. Morbi est est, blandit sit amet,
            sagittis vel, euismod vel, velit. Pellentesque egestas sem.
            Suspendisse commodo ullamcorper magna. Ut aliquam sollicitudin leo.
          </p>
        </li>
        <li>
          <h3>RUBY ON RAILS</h3>
          <p>
            Donec nec justo eget felis facilisis fermentum. Aliquam porttitor
            mauris sit amet orci. Aenean dignissim pellentesque felis. Vivamus
            vestibulum nulla cras ornare tristique elit nec ante.
          </p>
        </li>
        <li>
          <h3>OBJECT-ORIENTED PHP</h3>
          <p>
            Morbi in sem quis dui placerat ornare. Ut aliquam sollicitudin leo.
            Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam.
            Sed arcu. Cras ante. Vivamus vestibulum nulla nec ante.
          </p>
        </li>
        <li>
          <h3>WORDPRESS</h3>
          <p>
            Phasellus ultrices nulla quis nibh. Fusce lobortis lorem. Quisque a
            lectus. Donec consectetuer ligula vulputate sem tristique cursus.
            Nam nulla quam, gravida non, sodales sit amet, nisi.
          </p>
        </li>
        <li>
          <h3>JAVASCRIPT / JQUERY</h3>
          <p>
            Cras ornare tristique elit lorem ipsum dolor sit amet, consectetuer
            adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec
            urna. In nisi neque, aliquet vel, dapibus id.
          </p>
        </li>
      </ol>
    </div>
  );
};

export const Expertise = () => {
  return <Section header={<Header />} content={<Content />} />;
};
