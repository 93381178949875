import { useLazyQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { GET_POST_ROLE } from "./query.gql";
import SearchAddBar from "../../components/TopBar/search-add-bar.component";
import PostRoleList from "./post-role-list";
import Pathway from "../../components/TopBar/pathway";
import SnackbarComponent from "../../components/Cards/snackbarComponent";

const PostRolePage = () => {
  const jwt = localStorage.getItem("jwtToken");
  const [postRoles, setPostRoles] = useState([]);
  const [filteredList, setFilteredList] = useState("");

  const [getPostRoles, { loading, data }] = useLazyQuery(GET_POST_ROLE, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getPostRoles();
  }, []);

  useEffect(() => {
    if (data) {
      const { postRoles } = data;
      setPostRoles(postRoles.data);
    }
  }, [data]);

  const filteredSearched = postRoles.filter((item) => {
    return item.attributes.postRole.toLowerCase().includes(filteredList.toLowerCase());
  });



  return (
    <Grid container maxWidth="lg" style={{ margin: "0 auto" }}>
      <Grid item xs={12}>
        <Pathway />
        <SearchAddBar
          searched={filteredList}
          setSearched={setFilteredList}
          data={postRoles}
          setData={setPostRoles}
        />
      </Grid>
      <Grid container maxWidth="lg" style={{ margin: "0 auto" }}>
        <Grid item xs={12} md={12}>
          <PostRoleList
            filteredSearched={filteredSearched}
            postRoles={postRoles}
            setPostRoles={setPostRoles}
          />
        </Grid>
      </Grid>
      <SnackbarComponent />
    </Grid>

  );
};

export default PostRolePage;
