import { gql } from "@apollo/client";

export const GET_COLLABORATOR_BY_ID = gql`
query getCollaboratorbyid($id:ID!) {
  collaborator(id:$id) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        postRole{data{id attributes{postRole}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
        experiences{data{id attributes{ description startDate endDate postRole{data{id attributes{postRole}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations{data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise{data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills{data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles{data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards{data{id attributes{name description}}} 
        portfolios{data{id attributes{name description image{data{id attributes{url}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
     }
    }
  }
}


`;

export const GET_COLLABORATOR_BY_ID_TO_COMPANY = gql`
query getCollaboratorByCompany($id:ID!) {
  collaborator(id:$id) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        postRole{data{id attributes{postRole}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
        experiences{data{id attributes{ description startDate endDate postRole{data{id attributes{postRole}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations{data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise{data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills{data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles{data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards{data{id attributes{name description}}} 
        portfolios{data{id attributes{name description image{data{id attributes{url}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
          companies{data{id attributes{name}}}
     }
    }
  }
}


`;
export const GET_COLLABORATORS_BY_COMPANY_ID = gql`
query getCollaboratorsByCompanyId($companyId: ID!) {
  collaborators(filters: { companies: { id: { eq: $companyId } } }) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        evaluations { 
          data {
            id
            attributes {
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }
            }
          }
  }
        }
        
       
        
    }
  }
}
`;
