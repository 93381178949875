import {
    Button, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CREATE_AWARD, UPDATE_AWARD } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";

const AwardStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setAward,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [new_award] = useMutation(CREATE_AWARD)
    const [updadated_award] = useMutation(UPDATE_AWARD)
    const [awardError, setAwardError] = useState(null);
    const [description, setDescription] = useState('');
    const [awardName, setAwardName] = useState('');
    useEffect(() => {
        if (step?.includes("Edit")) {
            const foundProfile = ObjectList.find((object) => object.id === stepID);
            setDescription(foundProfile.description)
            setAwardName(foundProfile.name)
        }
    }, []);
    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleAdd = () => {
        if (awardName === "") {
            setAwardError(i18n.t('collaboratorStep.error.awardSelect', { lng: selectedLanguage }));
        }
        else {
            new_award({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    data: {
                        name: awardName,
                        description: description,
                        collaborator: collaboratorID,
                    },

                },
            }).then((data) => {
                const newAward = data.data.createAward.data
                const updatedData = {
                    id: newAward.id,
                    name: newAward.attributes?.name,
                    description: newAward.attributes?.description,
                }
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, awards: [...obj.awards, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData)
                handleClose()
                toast.success("Award Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (awardName === "") {
            setAwardError(i18n.t('collaboratorStep.error.awardSelect', { lng: selectedLanguage }));
        }
        else {
            updadated_award({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                        name: awardName,
                        description: description,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {
                const foundExpertise =
                {
                    id: stepID,
                    name: awardName,
                    description: description
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

                if (foundExpertiseIndex !== -1) {
                    ObjectList[foundExpertiseIndex] = {
                        ...foundExpertise,
                    };
                }
                handleClose()
                toast.success("Award Updated!!")
            })


        }
    };
    const handleCreate = () => {
        if (awardName === "") {
            setAwardError(i18n.t('collaboratorStep.error.awardSelect', { lng: selectedLanguage }));
        }
        else {
            setAward(prevState => [...prevState, {
                name: awardName,
                description: description
            }])
            setIsGridVisible(false);
        }

    };


    return (


        <>

            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={12}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.name', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={awardName}
                        onChange={(event) => handleInputChange(event, setAwardName)}
                        fullWidth
                        name="awardName"
                        id="awardName"
                        placeholder="Ex: Best Website of 2022"
                    />
                    {awardError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {awardError}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        multiline
                        type="text"
                        value={description}
                        onChange={(event) => handleInputChange(event, setDescription)}
                        fullWidth
                        name="description"
                        id="description"
                        placeholder={i18n.t('collaboratorStep.collaborator.descriptionAward', { lng: selectedLanguage })}
                    />
                </Grid>


            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                               {i18n.t('collaboratorStep.button.award', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleAdd}
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default AwardStep;