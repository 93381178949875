
import { Hero } from "./sections/hero";
import { Intro } from "./sections/intro";
import { Expertise } from "./sections/expertise";
import { Skills } from "./sections/skills";
import { Experience } from "./sections/experience";
import { Education } from "./sections/education";
import { Profiles } from "./sections/profiles";
import { Awards } from "./sections/awards";
import { Portfolio } from "./sections/portfolio";
import { Clients } from "./sections/clients";
import { Contact } from "./sections/contact";
import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import DeleteStep from "../Collaborator_Creation_Page/steps/Delete_Step";
import CreationStep from "../Collaborator_Creation_Page/steps/Create_Step";
import MainCard from "../../../components/MainCard/main-card.component";

const CollaboratorView = ({ colaborador, setColaborador, colaboradorEdit, setColaboradorEdit, setCollaborators, collaborators }) => {
  const jwt = localStorage.getItem("jwtToken");
  const [collaboratorID, setCollaboratorID] = useState(colaborador.id);
  const [collaborator, setCollaborator] = useState(colaborador);
  const [expertises, setExpertises] = useState(colaborador.expertise)
  const [skills, setSkills] = useState(colaborador.skills)
  const [experiences, setExperiences] = useState(colaborador.experiences)
  const [educations, setEducations] = useState(colaborador.educations)
  const [profiles, setProfiles] = useState(colaborador.profiles)
  const [awards, setAwards] = useState(colaborador.awards)
  const [portfolios, setPortfolios] = useState(colaborador.portfolios)
  const [intro, setIntro] = useState({ id: colaborador.id, intro: colaborador.intro })
  const [clients, setClients] = useState(colaborador.client)
  const [collaboratorGrid, setCollaboratorGrid] = useState(false);



  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(null);
  const removeStep = step?.includes("Delete") || step?.includes("Clear");
  const addStep = step?.includes("Add") || step?.includes("Edit");
  const editFalse = colaboradorEdit === false
  const editTrue = colaboradorEdit === true

  const [stepID, setStepID] = useState(null);
  function handleClose() {
    setOpen(false);
  };
  function handleOpen(step) {
    setOpen(true);
    setStep(step)
  };


  return (
    <>



     
      <MainCard title="Detalhes do Colaborador">
        <Hero setStepID={setStepID} handleOpen={handleOpen} collaborator={collaborator} profiles={profiles} colaboradorEdit={colaboradorEdit} />
        <Intro setStepID={setStepID} handleOpen={handleOpen} intro={intro} colaboradorEdit={colaboradorEdit} />
        {editFalse && expertises?.length > 0 && <Expertise expertise={expertises} colaboradorEdit={colaboradorEdit} />}
        {editFalse && skills?.length > 0 && <Skills skills={skills} colaboradorEdit={colaboradorEdit} />}
        {editFalse && experiences?.length > 0 && <Experience experience={experiences} colaboradorEdit={colaboradorEdit} />}
        {editFalse && educations?.length > 0 && <Education education={educations} colaboradorEdit={colaboradorEdit} />}
        {editFalse && profiles?.length > 0 && <Profiles profiles={profiles} colaboradorEdit={colaboradorEdit} />}
        {editFalse && awards?.length > 0 && <Awards awards={awards} colaboradorEdit={colaboradorEdit} />}
        {editFalse && portfolios?.length > 0 && <Portfolio portfolio={portfolios} colaboradorEdit={colaboradorEdit} />}
        {editFalse && clients?.length > 0 && <Clients collaborator={collaborator}  clients={clients} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Expertise setStepID={setStepID} handleOpen={handleOpen} expertise={expertises} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Skills setStepID={setStepID} handleOpen={handleOpen} skills={skills} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Experience setStepID={setStepID} handleOpen={handleOpen} experience={experiences} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Education setStepID={setStepID} handleOpen={handleOpen} education={educations} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Profiles setStepID={setStepID} handleOpen={handleOpen} profiles={profiles} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Awards setStepID={setStepID} handleOpen={handleOpen} awards={awards} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Portfolio setStepID={setStepID} handleOpen={handleOpen} portfolio={portfolios} colaboradorEdit={colaboradorEdit} />}
        {editTrue && <Clients setStepID={setStepID} handleOpen={handleOpen} profiles={profiles} clients={clients} colaboradorEdit={colaboradorEdit} />}
        <Contact setStepID={setStepID} handleOpen={handleOpen} collaborator={collaborator} profiles={profiles} colaboradorEdit={colaboradorEdit} />
      </MainCard>
      

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {step}
        </DialogTitle>
        <DialogContent>
          {addStep && <CreationStep
            handleClose={handleClose}
            step={step} stepID={stepID}
            portfolio={portfolios} setPortfolio={setPortfolios}
            profiles={profiles} setProfiles={setProfiles}
            awards={awards} setAwards={setAwards}
            clients={clients} setClients={setClients}
            educations={educations} setEducations={setEducations}
            experiences={experiences} setExperiences={setExperiences}
            expertises={expertises} setExpertises={setExpertises}
            setSkills={setSkills} skills={skills}
            collaborator={collaborator} setCollaborator={setCollaborator}
            collaborators={collaborators} setCollaborators={setCollaborators}
            collaboratorID={collaboratorID} intro={intro} setIntro={setIntro} 
          />}

          {removeStep && <DeleteStep
            handleClose={handleClose}
            step={step} stepID={stepID}
            portfolio={portfolios} setPortfolio={setPortfolios}
            profiles={profiles} setProfiles={setProfiles}
            awards={awards} setAwards={setAwards}
            clients={clients} setClients={setClients}
            experiences={experiences} setExperiences={setExperiences}
            educations={educations} setEducations={setEducations}
            skills={skills} setSkills={setSkills}
            expertises={expertises} setExpertises={setExpertises}
            collaborator={collaborator} setCollaborator={setCollaborator}
            collaborators={collaborators} setCollaborators={setCollaborators}
          />}
        </DialogContent>


      </Dialog>

    </>
  );
};

export default CollaboratorView;
