import { Section } from "../section";

import css from "../css/skills.module.css";

const Header = () => {
  return (
    <>
      <h2>Skills</h2>
      <p>Progress bars, anyone?</p>
    </>
  );
};

const Content = () => {
  const data = [
    { id: 1, name: "Photoshop", value: 80 },
    { id: 2, name: "Illustrator", value: 75 },
    { id: 3, name: "Git", value: 70 },
    { id: 4, name: "After Effect", value: 65 },
    { id: 5, name: "Sketch", value: 90 },
    { id: 6, name: "CSS3 Animation", value: 50 },
    { id: 7, name: "Pagespeed Insight", value: 85 },
    { id: 8, name: "SEO", value: 95 },
  ];

  return (
    <ul className={css.skills}>
      {data.map((skill) => (
        <li key={skill.id}>
          <div className={css.describe}>
            <span className={css.percentage}>
              {skill.value}
              <small>%</small>
            </span>
            <span className={css.label}>{skill.name}</span>
          </div>
          <div
            className={css.bar}
            style={{ "--percent": `${skill.value}%` }}
          ></div>
        </li>
      ))}
    </ul>
  );
};

export const Skills = () => {
  return <Section header={<Header />} content={<Content />} />;
};
