import Img from "./contrato.svg";

const SvgIcon = () => {
  return (
    <>
      <img src={Img} style={{ width: '40%', objectFit: 'contain' }} />
    </>
  );
};

export default SvgIcon;