import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockResetIcon from "@mui/icons-material/LockReset";
import ConstructionIcon from "@mui/icons-material/Construction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PushPinIcon from "@mui/icons-material/PushPin";
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import QuizIcon from "@mui/icons-material/Quiz";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import i18n from "../../translate/i18n";
import LanguageSelector from "../../translate/languages/languageSelector";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { UserRoleContext } from "../../contexts/userRole-context";

const SideBar = () => {
  const { uRoleContext } = useContext(UserRoleContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const getMenuForRole = (uRoleContext, i18n, selectedLanguage) => {
    switch (uRoleContext) {
      case "administrator":
        return [
          { Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },
          { Icon: CorporateFareRoundedIcon, title: i18n.t('tittles.company', { lng: selectedLanguage }), url: "/companies" },
          { Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },
          { Icon: StarOutlineIcon, title: i18n.t('tittles.evaluation', { lng: selectedLanguage }), url: "/evaluation" },
          {
            Icon: SettingsOutlinedIcon,
            title: i18n.t('tittles.parameterization', { lng: selectedLanguage }),
            submenu: [
              {
                Icon: GroupWorkIcon,
                title: i18n.t('tittles.role', { lng: selectedLanguage }),
                url: "/collaborator/role",
              },
              { Icon: AccountTreeIcon, title: i18n.t('tittles.dimension', { lng: selectedLanguage }), url: "/dimension" },
              { Icon: CalendarMonthIcon, title: i18n.t('tittles.priod', { lng: selectedLanguage }), url: "/period" },
              { Icon: PushPinIcon, title: i18n.t('tittles.indicator', { lng: selectedLanguage }), url: "/indicator" },
              { Icon: QuizIcon, title: i18n.t('tittles.questao', { lng: selectedLanguage }), url: "/question" },
              { Icon: QuizIcon, title: i18n.t('tittles.quiz', { lng: selectedLanguage }), url: "/quiz" },
            ],
          },
        ];
      case "collaborator":
        return [
          { Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },
          { Icon: CorporateFareRoundedIcon, title: i18n.t('tittles.company', { lng: selectedLanguage }), url: "/companies" },
          { Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },
          { Icon: StarOutlineIcon, title: i18n.t('tittles.evaluation', { lng: selectedLanguage }), url: "/evaluation" },
        ];
      case "company":
        return [
          { Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },
          // { Icon: CorporateFareRoundedIcon, title: i18n.t('tittles.company', { lng: selectedLanguage }), url: "/companies" },
          { Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },
          { Icon: StarOutlineIcon, title: i18n.t('tittles.evaluationManager', { lng: selectedLanguage }), url: "/evaluation" },
          
        ];
      default:
        return [];
    }
  };

  const Item = ({ Icon, title, url, submenu }) => {
    const [openSubmenu, setOpenSubmenu] = useState(false);
    const location = useLocation();
    const isSelected = location.pathname === url;
    return (
      <div>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              if (submenu) {
                setOpenSubmenu(!openSubmenu);
              } else {
                setOpen(false);
                navigate(url);
              }
            }}
            sx={{ backgroundColor: isSelected ? 'rgba(247, 147, 30, 0.2)' : 'transparent', marginLeft: '0.5rem', marginRight: '0.5rem', borderRadius: 1 }}
          >
            <ListItemIcon>
              <Icon fontSize="small" sx={
                title === i18n.t('tittles.role', { lng: selectedLanguage }) ||
                  title === i18n.t('tittles.dimension', { lng: selectedLanguage }) ||
                  title === i18n.t('tittles.priod', { lng: selectedLanguage }) ||
                  title === i18n.t('tittles.indicator', { lng: selectedLanguage }) ||
                  title === i18n.t('tittles.questao', { lng: selectedLanguage }) ||
                  title === i18n.t('tittles.quiz', { lng: selectedLanguage }) ? { display: "none" } : { color: "white" }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "body1", fontSize: "0.875rem" }} primary={title} sx={{ mr: 1, ml: -3.5 }} />
            {submenu && (openSubmenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {submenu && (
          <List sx={{ display: openSubmenu ? "block" : "none" }}>
            {submenu.map((m, i) => (
              <Item
                key={i}
                title={m.title}
                Icon={m.Icon}
                url={m.url}
                submenu={m.submenu}
              />
            ))}
          </List>
        )}
      </div>
    );
  };
  const menu = getMenuForRole(uRoleContext, i18n, selectedLanguage);
  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        aria-label="open drawer"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        variant="permanent"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => { }}
      >
        <div style={{ background: "#2F272D", color: "white", height: "200%", width: "14.938rem" }}>
          <Box sx={{ paddingTop: 4, paddingLeft: 2.5, paddingBottom: 4 }} textAlign="left">
            <img src="./logo2.svg" />
          </Box>
          
          <List>
            {menu.map((m, i) => (
              <Item
                key={i}
                title={m.title}
                Icon={m.Icon}
                url={m.url}
                submenu={m.submenu}
              />
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default SideBar;