import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  Autocomplete,
  Typography,
  IconButton, Menu, MenuItem, Pagination,
  Box,
  TextField,
  Grid,
  Rating
} from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* import { FaEllipsisV } from 'react-icons/fa'; */
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useState } from 'react'; import { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { toast } from 'react-toastify';
import ColaboradorDelete from './Collaborator_delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import i18n from '../../../translate/i18n';
import { useLazyQuery, useMutation } from "@apollo/client";
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import CustomPagination from '../../../components/TableComponents/CustomPagination';
import { CloudCircleOutlined, FaxSharp } from '@mui/icons-material';
import CollaboratorToCompany from "./CollaboratorToCompany";
import { ASSOC_CTOC, CREATE_NOTIFICATION, TESTE_2, UPDATE_COMPANY_C } from './mutation.gql';
import { GET_COLLABORATORS, GET_COMPANIES, GET_NOTIFICATIONS, GET_EMPLOYMENT_COLLAB, GET_COMP_GET } from './query.gql';
import jwtDecode from "jwt-decode";

import "./style.css";
import { useNavigate } from 'react-router-dom';
import ChoosenCollaborator from './ChoosenCollaborator';
import FireCollaborator from './FireCollaborator';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));


export default function CollaboratorList({ collaborators, setCollaborators, setColaborador, colaborador, setColaboradorEdit }) {

  const navigate = useNavigate();
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const { selectedLanguage } = React.useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedPage, setSortedPage] = useState(collaborators);

  const [openDialog, setOpenDialog] = useState(false);
  const [collaboratorId_, setCollaboratorId_] = useState(0);

  const [getContracted] = useLazyQuery(GET_EMPLOYMENT_COLLAB);
  const [retrieveNotification] = useLazyQuery(GET_NOTIFICATIONS);
  const [notifiactions, setNotifications] = useState([]);
  const [collabContracted, setCollabContracted] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const [allNotifications, setAllNotifications] = useState([]);


  const sendNotification = (collaboratorId) => {
    try {
      const userId = localStorage.getItem("userId");
      console.log("ID: ", userId, " COLLABID: ", collaboratorId);
      if (userId && collaboratorId) {
        createNotification({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          // fetchPolicy: "no-cache",
          variables: {
            action: 'hire', // ou: fire
            status: false,
            companyId: userId.toString(),
            collaboratorId: collaboratorId.toString(),
          },
        }).then((response) => {
          // console.log("RESUSLT NOT: ", result);
          if (response?.data) {
            toast.success(i18n.t("Solicitação enviada com sucesso!", { lng: selectedLanguage }));
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            console.warn("Associação concluída, mas a criação da avaliação falhou.");
            toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação.");
          }
        })
      } else {
        alert(1);
      }
    } catch (error) {
      console.error("Erro ao processar a associação:", error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      // setLoadingCheck(false);
    }
  };

  useEffect(() => {
    getContracted({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    }).then((result) => {
      console.log("Resultado bruto da query:", result?.data?.employments.data);
      setCollabContracted(result?.data?.employments.data);
      /*  const contract = result?.data?.employments?.data?.map(({ id, attributes }) => {
         const companyName = attributes?.companies?.data?.[0]?.attributes?.name || "Unknown Company";
 
         return {
           id,
           company: companyName,
         };
       }); */



      //console.log("CONTRATOS: ", contract);
    });

    const role = localStorage.getItem('userRole') ?? '';
    if (role == 'administrator') {
      setIsAdmin(!isAdmin);
    }

   let notificationss = localStorage.getItem("notifications") ?? [];
   notificationss = JSON.parse(notificationss);
    if (notificationss.length > 0) {
      setAllNotifications(notificationss);
    }

    retrieveNotification({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    }).then((response) => {
      //console.log("NNNNNN: ", response);
      if (response?.data) {
        //console.log("NNNNNN: ", response?.data);
        // const value = response.data?.notifications?.map((id, attributes) => {
        //   const companyId = attributes?.Company?.data;
        //   return {
        //     companyId
        //   };
        // });
      }
      //console.log("R NOTIFICATIONS: ", response?.data);
    });

  }, []);

  const { REACT_APP_API_URL } = process.env;


  const handleOpenDialog = (id) => {
    setOpenDialog(!openDialog);
    setCollaboratorId_(id);
  };

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);
  const [get_companies] = useLazyQuery(GET_COMP_GET);


  const [openFireDialog, setOpenFireDialog] = useState(false);
  const [openAssociateDialog, setOpenAssociateDialog] = useState(false);
  const [associateId, setAssociateId] = useState(0);

  const handleOpenAssociateDialog = (id) => {
    setAssociateId(id);
    setOpenAssociateDialog(true);
  };

  const handleOpenFireDialog = () => {
    setOpenFireDialog(true);
  };

  const handleCloseAssociateDialog = () => {
    setOpenAssociateDialog(false);
    setAssociateId(0);
  };

  const itemsPerPage = 6;


  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [textToShow, setTextToShow] = useState("Contratar");

  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  

  // Extract the items to display on the current page
  const collabItems = isAdmin ? collaborators : collaborators?.filter((item, index) => item.employmentCollab == null);
  const sollicitados = allNotifications.filter((item) => item.attributes?.collaborator?.data?.id == item.id);
  const currentItems = collabItems?.slice(firstIndex, lastIndex);
  // console.log("SOOOOLL: ", sollicitados);
  const loadCollabData = () => {
    get_companies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        userId: employeerID.toString()
      },
    }).then((data) => {
      const lastId = data?.data?.companies?.data?.map(companyItem => {
        // console.log("NO MUSEKE: ", companyItem.id)
        return companyItem.id;
      });
      //alert(lastId);
      setCurrentCompanyId(lastId?.toString());

    });
  };

  //console.log("ALL LIST COLABORATORS: ", currentItems);

  const handlePreviewCollab = (id, currentItem) => {
    const currentData = currentItems.find((item) => item.id = id);

    navigate('/collaborator/preview?id=' + id, {
      state: {
        data: currentItem,
        currentItems: currentItems
      }
    });
  };

  const [collabPosition, setCollabPosition] = useState("N/A");

  const handleClickAssociate = (contractText, id) => {
    if (contractText == "Descontratar") {
      // alert('Deseja Mesmo Descontratar Este collaborador?');
      setActionValor(0);
    } else {
      setActionValor(1);
    }
    handleOpenAssociateDialog(id);
    currentItems.map((item, i) => {
      if (item.id == id) {
        handleClose();
        setCollabPosition(item.speciality);
      }
    });
  };

  const [solicitId, setSolicitId] = useState(-1);

  const handleClickSolicit = (id) => {
    handleOpenAssociateDialog(id);
  };

  useEffect(() => {
    loadCollabData();
    //alert(currentCompanyId);
  }, []);



  // Calculate the total number of pages
  const totalPages = Math.ceil(collabItems?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  /*  useEffect(() => {
     // Set the current page to 1 whenever the evaluation prop changes
     setSortedPage(collaborators)
     setCurrentPage(1);
   }, [collaborators]); */


  const [collaboratorID, setCollaboratorID] = useState(false);

  const [collaboratorUser, setCollaboradorUser] = useState("");
  const [collaboratorStep, setCollaboratorStep] = useState(true);
  const [actionValor, setActionValor] = useState(1);

  const [open, setOpen] = useState(false);
  const [booleanStatus, setBooleanStatus] = useState(false);
  function handleClose() {
    setOpen(false);
  };


  function LongMenu({ collaboratorID, collaboratorData, contractText, collaboratorStep, rowId, row }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const booleanStatus = collaboratorData.isActive

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenView = () => {
      //console.log(collaboratorID);
      setColaborador(collaboratorData);
      handleClose();
    };

    const handleEdit = () => {
      setColaborador(collaboratorData);
      setColaboradorEdit(true);
      handleClose();
    };

    const handleClickDelete = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
    };
    const handleClickDisable = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
      setBooleanStatus(collaboratorData.isActive)
    };




    return (
      <>
        <IconButton aria-label="more" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handlePreviewCollab(rowId, row)}>
            <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
          </MenuItem>
          {
            isAdmin ? (
              [
                <MenuItem key="edit" onClick={handleEdit}>
                  <EditOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.edit', { lng: selectedLanguage })}
                </MenuItem>,
                <MenuItem key="toggle" onClick={() => handleClickDisable(true)}>
                  {booleanStatus ? (
                    <ClearOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  ) : (
                    <CheckIcon color="primary" style={{ marginRight: '5px' }} />
                  )}
                  {booleanStatus
                    ? i18n.t('options.disable', { lng: selectedLanguage })
                    : i18n.t('options.enable', { lng: selectedLanguage })}
                </MenuItem>,
                <MenuItem key="delete" onClick={() => handleClickDelete(false)}>
                  <DeleteIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.delete', { lng: selectedLanguage })}
                </MenuItem>,
              ]
            ) : null
          }


          {/* <MenuItem onClick={() => handleClickAssociate(contractText)}>
            <CloudCircleOutlined color='primary' style={{ marginRight: '5px' }} />
            {contractText}
          </MenuItem> */}
        </Menu>
      </>
    );
  }


  const [userRole, setUserRole] = useState('Company');

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role) {
      setUserRole(role.toLowerCase())
    }
  }, [userRole])

  const calculateExperienceYears = (experiences) => {


    let totalYears = 0;

    experiences.forEach((experience) => {
      const startDate = new Date(experience.startDate);
      const endDate = new Date(experience.endDate);
      const years = endDate.getFullYear() - startDate.getFullYear();

      // Ajusta se o mês e dia do endDate ainda não ocorreram este ano
      if (
        endDate.getMonth() < startDate.getMonth() ||
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
      ) {
        totalYears -= 1;
      }

      totalYears += years;
    });

    return totalYears;
  };
  const [collaboratorRole, setCollaboratorRole] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [ratingValue, setRatingValue] = useState(3);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const btnActionClick = (id, role) => {
    setCollaboratorRole(role ?? null);
    if (id) {
      isAdmin ?
        handleClickAssociate('Contratar', id) :
        sendNotification(id);
    } else {
      toast.error('ID indefinido');
    }
  };

  const fireCollaborator = (id) => {
    if (id) {
      const employmentId = collaborators.find(item => item?.id == id)?.employmentCollab?.id;
      handleClickAssociate('Descontratar', employmentId);
    } else {
      toast.error('ID indefinido');
    }
  };

  // const handleActionClick = (row) => {
  //   setCollaboratorUser(row); // Passa os dados do colaborador
  //   setCollaboratorID(row.id);
  //   setCollaboratorStep(false); // Passa para a etapa de "descontratar"
  //   setOpen(true); // Abre o diálogo
  // };

  // const handleContractClick = (row) => {
  //   setCollaboratorUser(row); // Passa os dados do colaborador
  //   setCollaboratorID(row.id);
  //   setCollaboratorStep(true); // Passa para a etapa de "contratar"
  //   setOpenAssociateDialog(true); // Abre o diálogo de contratação
  // };

  return (
    <>
      <div className="w-full relative grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {
          currentItems?.map((row, index) =>
            <div key={index} className="bg-white shadow-md border rounded-[8px] relative flex flex-col items-center justify-center py-6">
              <div className="absolute top-4 right-4">
                <LongMenu
                  collaboratorID={row.id}
                  collaboratorData={row}
                  rowId={row.id}
                  row={row}
                />
              </div>
              <div className='flex items-center jusitfy-center'>
                {
                  row?.avatar ? (
                    <img
                      src={`${REACT_APP_API_URL}${row?.avatar}`}
                      className='w-[90x] h-[90px] aspect-square rounded-full border object-top'
                    />
                  ) : (

                    <span className="flex items-center justify-center w-[90x] h-[90px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-2xl">{row?.name[0]}</span>
                  )
                }
              </div>
              <h4 className="font-bold text-xl">
                {row?.name?.split(" ")[0]} {row?.name && row?.name?.split(" ").pop()}
              </h4>
              <small className="text-[rgba(0,0,0,0.65)]">{row.speciality ?? 'N/A'}</small>
              <div className="flex items-center gap-1 mb-2">
                {
                  row.evaluations.length > 0 ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <span
                        key={index}
                        className={`${index + 1 <= (parseInt(row.evaluations[0]?.attributes?.star, 10) || 0) ? 'text-[#F9AE00]' : 'text-black'}`}
                      >
                        {index + 1 <= (parseInt(row.evaluations[0]?.attributes?.star, 10) || 0) ? "★" : "☆"}
                      </span>
                    ))
                  ) : (
                    Array.from({ length: 5 }).map((_, index) => (
                      <span
                        key={index}
                        className="text-black"
                      >
                        ☆
                      </span>
                    ))
                  )
                }
              </div>
              <span className="mb-6">{row.postRoleDescription ?? '-'}</span>

              <button
                type="button"
                onClick={() => isAdmin && row.employmentCollab != null ? fireCollaborator(row.id) : btnActionClick(row.id, row.postRoleDescription)}
                // onClick={() => btnActionClick(row.id)}
                className="inline-block w-[80%] text-center py-[0.45rem] px-[0.75rem] rounded-full border border-[#F9AE00] text-[#F9AE00] font-bold uppercase"
              >

                {
                  isAdmin && row.employmentCollab != null ? (
                    i18n.t('Descontratar', { lng: selectedLanguage })
                  ) : i18n.t('Contratar', { lng: selectedLanguage })
                }
                {/* {i18n.t('Contratar', { lng: selectedLanguage })} */}
              </button>
            </div>
          )
        }

      </div>

      <div className="mt-8 flex space-x-3 items-center justify-center">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px]"
        >
          <KeyboardArrowLeftIcon />
        </button>
        <div className="flex items-center gap-3">
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] flex items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-white text-black' : 'bg-[#F9AE00] text-white'}`}
          >
            {currentPage}
          </span>
          <span>
            {i18n.t('de', { lng: selectedLanguage })}
          </span>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-[#F9AE00] text-white' : 'bg-white text-black'}`}
          >
            {totalPages}
          </span>
        </div>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px] `}
        >
          <KeyboardArrowRightIcon />
        </button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {
            collaboratorStep === false ? (
              <p>Delete {collaboratorUser.name}?</p>
            ) : (
              <p>{booleanStatus ? 'Disable' : 'Enable'} {collaboratorUser.name}?</p>
            )
          }
        </DialogTitle>
        <DialogContent>
          <ColaboradorDelete
            collaboratorID={collaboratorID}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            collaboratorUserID={collaboratorUser.userID}
            collaboratorUser={collaboratorUser}
            handleClose={handleClose}
            collaboratorStep={collaboratorStep}
          />
        </DialogContent>
      </Dialog>
      {
        isAdmin ? (
          <>
            <CollaboratorToCompany
              open={openAssociateDialog}
              handleClose={handleCloseAssociateDialog}
              idCollaborador={associateId}
              action={actionValor}
              positionCollaborator={collabPosition}
              collabRole={collaboratorRole}
            />

          </>

        ) : (
          <ChoosenCollaborator
            open={openAssociateDialog}
            handleClose={handleCloseAssociateDialog}
            collaboratorID={associateId}
          />
        )
      }

      {/* <Grid container sx={{ display: 'none' }} spacing={2}>
        {
          currentItems?.map((row) => (
            <Grid item xs={12} md={6} lg={4}>
              <Card sx={{ width: '100%' }} key={row.id}>
                <CardContent>
                  <div style={{
                    width: '40px',
                    marginLeft: 'auto'
                  }}>
                    <LongMenu
                      collaboratorID={row.id}
                      collaboratorData={row}
                      contractText={
                        row.companies.some(itemc => itemc.id.toString() === currentCompanyId.toString()) ? 'Descontratar'
                          : 'Contratar'
                      }
                      rowId={row.id}
                      row={row}
                    // onContractClick={() => handleContractClick(row)}
                    />
                  </div>
                  <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ marginTop: '-18px', marginBottom: '4px', paddingTop: '16px' }} >
                    <Grid container flexDirection="column" justifyContent="center" alignItems="center" spacing={1} sx={{ marginBottom: '4px' }} >
                      <span style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        aspectRatio: '1/1',
                        backgroundColor: '#EA1967',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '5px'
                      }}>
                        {row?.name[0]}
                      </span>
                      <Typography>
                        {row?.name?.split(" ")[0]} {row?.name && row?.name?.split(" ").pop()}
                      </Typography>
                      <small style={{ color: 'rgba(0,0,0,0.65)' }}>{row.speciality ?? 'N/A'}</small>

                    </Grid>

                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ marginBottom: '15px', marginTop: '15px' }} >
                    <Rating
                      name="rating-control"
                      value={ratingValue}
                      onChange={(event, newValue) => {
                        setRatingValue(newValue);
                      }}
                      precision={0.5}
                      sx={{
                        fontSize: '1rem',
                      }}
                    />
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ marginBottom: '18px' }}>
                    <small>{row.postRoleDescription ?? '-'}</small>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={1}>

                    {
                      isAdmin && (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickAssociate('Contratar', row.id)}
                            sx={{
                              width: '80%',
                              margin: '0 auto',
                              fontSize: '0.75rem',
                              borderRadius: '50px'
                            }}
                          >
                            Contratar
                          </Button>
                        </Grid>
                      )
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid> */}
    </>
    // <>
    // <form>
    //   <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
    //     <TableContainer>
    //       <Table sx={{ minWidth: 700 }} aria-label="customized table">
    //         <TableHead>

    //           <TableRow>
    //             <StyledTableCell>{i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
    //             <StyledTableCell>{i18n.t('table.location', { lng: selectedLanguage })}</StyledTableCell>
    //             {!userRole.includes("company") && <StyledTableCell>{i18n.t('table.email', { lng: selectedLanguage })}</StyledTableCell>}
    //             {!userRole.includes("company") && <StyledTableCell>{i18n.t('table.phone', { lng: selectedLanguage })}</StyledTableCell>}

    //             <StyledTableCell>{i18n.t('table.yearsofExperience', { lng: selectedLanguage })}</StyledTableCell>
    //             <StyledTableCell>{i18n.t('table.speciality', { lng: selectedLanguage })}</StyledTableCell>
    //             {!userRole.includes("company") && <StyledTableCell>{i18n.t('table.company', { lng: selectedLanguage })}</StyledTableCell>}
    //             <StyledTableCell>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
    //             <StyledTableCell>{i18n.t('table.action', { lng: selectedLanguage })}</StyledTableCell>
    //           </TableRow>
    //         </TableHead>

    //         <TableBody>
    //           {currentItems?.map((row) => (
    //             <StyledTableRow key={row.id}>
    //               <StyledTableCell component="th" scope="row">
    //                 {row?.name?.split(" ")[0] || "N/A"}
    //                 {` ${row?.name && row?.name?.split(" ").pop()}` || "N/A"}
    //               </StyledTableCell>
    //               <StyledTableCell component="th" scope="row">
    //                 {row.city || "N/A"}<br />{row.country || "N/A"}
    //               </StyledTableCell>
    //               {!userRole.includes("company") && <StyledTableCell component="th" scope="row">
    //                 {row.email || "N/A"}
    //               </StyledTableCell>}
    //               {!userRole.includes("company") && <StyledTableCell component="th" scope="row">
    //                 {row.phone || "N/A"}
    //               </StyledTableCell>}
    //               <StyledTableCell component="th" scope="row">
    //                 {calculateExperienceYears(row.experiences) > 0 ?
    //                   `${calculateExperienceYears(row.experiences)} anos` :
    //                   "N/A"}
    //               </StyledTableCell>
    //               <StyledTableCell>{row.speciality || "N/A"}</StyledTableCell>
    //               {!userRole.includes("company") && <StyledTableCell>
    //                 {row.companies?.map((historyRow, index) => (
    //                   <div key={historyRow.id} style={{ display: 'flex', alignItems: 'center' }}>
    //                     <span>
    //                       {historyRow?.attributes.name || 'N/A'}
    //                     </span>
    //                   </div>
    //                 ))}
    //               </StyledTableCell>}
    //               <StyledTableCell>
    //                 {row.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || "N/A"}
    //               </StyledTableCell>
    //               <StyledTableCell>
    //                 <LongMenu
    //                   collaboratorID={row.id}
    //                   collaboratorData={row}
    //                   contractText={
    //                     row.companies.some(itemc => itemc.id.toString() === currentCompanyId.toString()) ? 'Descontratar'
    //                     : 'Contratar'
    //                   }
    //                 />
    //               </StyledTableCell>
    //             </StyledTableRow>
    //           ))}
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //     <CustomPagination
    //       totalPages={totalPages}
    //       currentPage={currentPage}
    //       handlePageChange={handlePageChange}
    //     />
    //   </Paper>
    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //     maxWidth='md'
    //   >
    //     <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
    //       {
    //         collaboratorStep === false ? (
    //           <p>Delete {collaboratorUser.name}?</p>
    //         ) : (
    //           <p>{booleanStatus ? 'Disable' : 'Enable'} {collaboratorUser.name}?</p>
    //         )
    //       }
    //     </DialogTitle>
    //     <DialogContent>
    //       <ColaboradorDelete
    //         collaboratorID={collaboratorID}
    //         collaborators={collaborators}
    //         setCollaborators={setCollaborators}
    //         collaboratorUserID={collaboratorUser.userID}
    //         collaboratorUser={collaboratorUser}
    //         handleClose={handleClose}
    //         collaboratorStep={collaboratorStep}
    //       />
    //     </DialogContent>
    //   </Dialog>
    //   {/* Diálogo para Associação */}
    //   <CollaboratorToCompany
    //     open={openAssociateDialog}
    //     handleClose={handleCloseAssociateDialog}
    //     idCollaborador={associateId}
    //     action={actionValor}
    //   />
    // </form>
    // </>
  );
}
