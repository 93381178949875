import {
    Autocomplete, Button, FormControl, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_COMPANIES } from "../query.gql";
import { UPDATE_CLIENTS } from "../mutation.gql";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import i18n from "../../../../translate/i18n";

const ClientStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getCompany] = useLazyQuery(GET_COMPANIES);
    const [updateClients] = useMutation(UPDATE_CLIENTS)
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [companyError, setCompanyError] = useState(null);

    const filteredCompany = companies.filter(company => !ObjectList?.some(obj => obj.id === company.id));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const companiesData = await getCompany({
                    variables: {
                        pagination: {
                            limit: 300,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: "cache-first",
                })
                setCompanies(companiesData.data.companies.data);
            } catch (error) {
                toast.error("Ocorreu um erro ao carregar a lista de Empresas");
            }
        };

        fetchData();

    }, []);



    const handleIntroEdit = () => {
        if (company === null) {
            setCompanyError("Company is Mandatory");
        } else {
            const newClient = {
                id: company.id,
                name: company.attributes?.name,
                image: company.attributes.image?.data?.attributes?.url
            }
            setCompany(null)
            let updatedData = [...ObjectList]
            updatedData.push(newClient);
            const updatedDataIDS = updatedData.map(obj => obj.id);
            console.log(ObjectList)
            console.log("updatedDataIDS", updatedDataIDS)
            updateClients({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                variables: {
                    id: collaborator.clientID, data: { collaborator: collaborator.id, companies: updatedDataIDS }
                },
            })
            const updatedMainData = collaborators.map(obj => {
                if (obj.id === collaborator.id) {
                    return { ...obj, client: [...obj.client, newClient] };
                }
                return obj; // Keep the original object
            });
            const newList = [...ObjectList, newClient]
            setObjectList(newList)
            setCollaborators(updatedMainData)
            handleClose()
            toast.success("Clients Updated!!")
        }



    };

    console.log(filteredCompany.length)

    return (


        <form >
            {filteredCompany.length === 0 &&
                <Grid item xs={12} md={12} id="expGrid">

                    <TextField
                        value={"No Company Available"}
                        variant="outlined"
                        placeholder="Ex: Sintaxy"
                        required
                        disabled
                        InputProps={{
                            style: {
                                fontWeight: 'bold',
                            },
                        }}
                    />

                </Grid>}
            {filteredCompany.length > 0 && <Grid container item xs={12} md={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={12}>
                    <InputLabel id="select-postRoles">{i18n.t('collaboratorStep.collaborator.company', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            style={{ width: '300px' }}
                            options={filteredCompany}
                            value={company}
                            getOptionLabel={(company) => company.attributes.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCompany(newValue);
                                setCompanyError(null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Ex: Sintaxy"
                                    required


                                />
                            )}
                        />

                    </FormControl>
                    {companyError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {companyError}
                    </Typography>}
                </Grid>
            </Grid>}

            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item>

                        <Button
                            disabled={filteredCompany.length === 0}
                            variant="contained"
                            size="small"
                            onClick={handleIntroEdit}
                        >{i18n.t('collaboratorStep.button.add', { lng: selectedLanguage })}</Button>


                    </Grid>
                </Grid>
            </Grid>

        </form>
    );

};

export default ClientStep;