import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import CompanyList from "./Company_list";
import SearchAddBar from "../../../components/TopBar/search-add-bar.component";
import { GET_COMPANIES, GET_USER_DATA } from "./query.gql";
import { ToastContainer } from "react-toastify";
import CompanyView from "../Company_View_Page/company_detail_main";
import jwtDecode from 'jwt-decode';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Pathway from "../../../components/TopBar/pathway";
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';



const CreateCompanyMain = () => {
  const jwt = localStorage.getItem("jwtToken");
  const [companies, setCompanies] = useState([])
  const [company_, setCompany_] = useState(null);
  const [company_Edit, setCompany_Edit] = useState(false);
  const [currentID, setCurrentID] = useState(null)
  const [filteredList, setFilteredList] = useState('');
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [updater, setUpdater] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(null);
  const theme = useTheme();

  const [getCompanys_, { loading, data }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });







  useEffect(() => {
    if (data) {
      const { companies } = data;


      setCompanies(companies.data);

    }



  }, [data]);
  
  useEffect(() => {
    getCompanys_();

  }, []);


  const validationEmailForm = yup
    .object({

      country: yup.string().required("Country is Mandatory"),
      city: yup.string().required("City is Mandatory"),


    })
    .required();
  // console.log("CompanyASAS", company_)


  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });
  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };


console.log("companies", companies);


  const filteredAdvanced = companies?.filter(item => {
     if (companyFilter) {
      const matches = Object.entries(companyFilter).every(([key, value]) => {
        if (!value) return true; // Ignora se não houver valor para o filtro
  
  
        // Verifica se o campo é 'city' ou 'country' (que são objetos dentro de 'data')
        if (key === 'city' || key === 'country') {
          // Verificando se city/country está dentro de item.attributes ou item.data
          const itemData = item.attributes?.[key]?.data || item[key]?.data;
          
  
          if (itemData && itemData.attributes) {
            const itemName = itemData.attributes.name?.toLowerCase(); // Acessa o nome da cidade ou país
           
            return itemName && itemName.includes(value.toLowerCase()); // Compara com o valor do filtro
          }
          return false; // Se não tiver dados necessários, retorna false
        }
  
        // Para o campo 'name', acessamos diretamente item.attributes.name
        if (key === 'name') {
          const itemValue = item.attributes?.name?.toLowerCase(); // Acessa item.attributes.name
         
          return itemValue && itemValue.includes(value.toLowerCase());
        }
  
        // Para o campo 'phone', agora verificamos diretamente item.attributes.phone
        if (key === 'phone') {
          const itemPhone = item.attributes?.phone?.toString().toLowerCase(); // Acessa item.attributes.phone
         
          return itemPhone && itemPhone.includes(value.toLowerCase());
        }

        if (key === 'status') {

          const itemStatus = item.attributes?.status?.toString().toLowerCase(); // Acessa item.attributes.phone
        
         
          return itemStatus && itemStatus.includes(value.toLowerCase());
        }
  
  
        // Para outros campos, realiza a comparação padrão
        const itemValue = item[key]?.toString().toLowerCase();
       
        return itemValue && itemValue.includes(value.toLowerCase());
      });
  
      return matches; // Retorna verdadeiro se todas as condições forem atendidas
    }
    return false; // Se não houver filtro, retorna todas as empresas
  });
  
  
  
  
  

  
  




  const filteredSearch = companies?.filter((item) =>
    Object.values(item.attributes).some((value) => {
      // Se o valor for um objeto e for 'city' ou 'country', tratamos de maneira diferente
      if (value?.data && value?.data.attributes) {
        // Acessamos o nome de city ou country de forma segura
        const name = value.data.attributes.name?.toLowerCase(); // Usando optional chaining para evitar erro se não houver 'name'
        return name && name.includes(filteredList.toLowerCase()); // Verifica se o nome existe e se ele inclui a string de busca
      }
  
      // Para outros campos, realiza a comparação padrão (como nome e telefone)
      const stringValue = value?.toString().toLowerCase();
      return stringValue && stringValue.includes(filteredList.toLowerCase()); // Verifica se o valor existe e se inclui a string de busca
    })
  );
 
  
 

  return (


    <Grid container >

      <ToastContainer />
      <Grid item xs={12}>

        {company_ === null ?
          <>
            <Pathway />
            {<SearchAddBar
              updater={updater}
              setUpdater={setUpdater}
              setData={setCompanies}
              searched={filteredList}
              setCompanyFilter={setCompanyFilter}
              setSearched={setFilteredList} />}

            <CompanyList
              updater={updater}
              setUpdater={setUpdater}
              /*   companies_={filteredSearch} */
              companies_={filteredAdvanced !== undefined ? (filteredAdvanced.length > 0 ? filteredAdvanced : filteredSearch) : filteredSearch}
              currentID={currentID}
              setCurrentID={setCurrentID}
              companies={companies}
              setOpenAlertOK={setOpenAlertOK}
              setSuccessMessage={setSuccessMessage}
              company_={company_}
              setCompany_={setCompany_}
              setCompanies={setCompanies}
              company_Edit={company_Edit}
              setCompany_Edit={setCompany_Edit}
            />

          </>
          : company_Edit === true ? (
            <>
              <Pathway />
              <SearchAddBar
                updater={updater}
                setCompanyFilter={setCompanyFilter}
                setUpdater={setUpdater}
                setData={setCompanies}
                searched={filteredList}
                setSearched={setFilteredList} />
              <CompanyList
                updater={updater}
                setUpdater={setUpdater}
                setCompanies={setCompanies}
                currentID={currentID}
                setCurrentID={setCurrentID}
                companies_={filteredAdvanced !== undefined ? (filteredAdvanced.length > 0 ? filteredAdvanced : filteredSearch) : filteredSearch}

                company_={company_}
                setCompany_={setCompany_}
                setCompany_Edit={setCompany_Edit}
                company_Edit={company_Edit}
                setOpenAlertOK={setOpenAlertOK}
                setSuccessMessage={setSuccessMessage}
               /*  companies={companies} */
              />

            </>
          )

            : (
              <CompanyView
                setCompanies={setCompanies}
                companies={companies}
                currentID={currentID}
                setCurrentID={setCurrentID}
                setOpenAlertOK={setOpenAlertOK}
                setSuccessMessage={setSuccessMessage}
                company_={company_}
                setCompany_={setCompany_}
                setCompany_Edit={setCompany_Edit}
                company_Edit={company_Edit}
              />
            )}

      </Grid>
      <Snackbar
        style={{ backgroundColor: 'teal', color: 'coral' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlertOK}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert severity="success" sx={{ border: 1 }}>
          <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}> Successo <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} /> </AlertTitle>
          {successMessage}

        </Alert></Snackbar>
    </Grid>
  )

}



export default CreateCompanyMain;