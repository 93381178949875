import EnergySavingsLeaf from "@mui/icons-material/EnergySavingsLeaf";
import GitHub from "@mui/icons-material/GitHub";
import DensityMedium from "@mui/icons-material/DensityMedium";
import DesignServices from "@mui/icons-material/DesignServices";
import Photo from "@mui/icons-material/Photo";
import ArtTrack from "@mui/icons-material/ArtTrack";
import css from "../css/profiles.module.css";
import { Section } from "../section";

const Header = () => {
  return (
    <>
      <h2>PROFILES</h2>
      <p>Busy as usual.</p>
    </>
  );
};

const Content = () => {
  const data = [
    {
      id: 1,
      icon: EnergySavingsLeaf,
      url: "http://themeforest.net/user/ruventhemes/portfolio",
      name: "Themeforest",
      description: "An overview of the themes and templates that I sell.",
    },
    {
      id: 2,
      icon: GitHub,
      url: "https://github.com/hatra-e/",
      name: "Github",
      description: "All my open source projects for you analyze.",
    },
    {
      id: 3,
      icon: DensityMedium,
      url: "http://wordpress.com/",
      name: "Medium",
      description: "Yes. I'm also a blogger and here you find my writings.",
    },
    {
      id: 4,
      icon: DesignServices,
      url: "https://dribbble.com/",
      name: "Dribbble",
      description: "Whenever a design is finalized, it lands right here.",
    },
    {
      id: 5,
      icon: Photo,
      url: "https://www.flickr.com/",
      name: "Flikr",
      description: "A selection of photos I shot throughout the years.",
    },
    {
      id: 6,
      icon: ArtTrack,
      url: "http://www.deviantart.com/",
      name: "Deviantart",
      description: "A place for my sketches and drawings",
    },
  ];

  return (
    <ul className={css.list}>
      {data.map((profile) => (
        <li key={profile.id}>
          <a href={profile.url} className={css.profile}>
            <span className={css.icon}>
              <profile.icon />
            </span>
            <h3 className={css.name}>{profile.name}</h3>
            <p className={css.description}>{profile.description}</p>
          </a>
        </li>
      ))}
    </ul>
  );
};

export const Profiles = () => {
  return <Section header={<Header />} content={<Content />} />;
};
