import { Grid } from "@mui/material";
import { useState } from "react";
import { useLazyQuery } from '@apollo/client';
import { GET_COMPANY_QUESTIONS } from './query.gql';
import SearchAddBar from "../../components/TopBar/search-add-bar.component";
import QuizList from "./quiz-list.component";
import { useEffect } from "react";
import Pathway from "../../components/TopBar/pathway";

const QuizPage = () => {

    const jwt = localStorage.getItem('jwtToken');
    const [quizzes, setQuizzes] = useState([])
    const [filteredList, setFilteredList] = useState('');

    const [getQuizzes, { loading, data }] = useLazyQuery(GET_COMPANY_QUESTIONS, {
        context: {
            headers: {
                authorization: `Bearer ${jwt}`,
            },
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        getQuizzes();
    }, []);

    useEffect(() => {
        if (data) {
            const { companyQuestions } = data;
            setQuizzes(companyQuestions.data);
        }
    }, [data]);

    const filteredSearched = quizzes.filter((item) => {
        return item.attributes.name.toLowerCase().includes(filteredList.toLowerCase())
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Pathway />
                <SearchAddBar
                    searched={filteredList}
                    setSearched={setFilteredList}
                    setData={setQuizzes} />
                <QuizList
                    quizzes={filteredSearched}
                    setQuizzes={setQuizzes} />
            </Grid>
        </Grid>
    );
}

export default QuizPage;