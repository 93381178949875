import { gql } from "@apollo/client";


export const GET_COMPANY_QUESTIONS = gql `
query CompanyQuestions {
  companyQuestions(filters:{isActive:{eq:true}}) {
    data {
      id
      attributes {
        name
        isActive
      }
    }
  }
}
`;

export const GET_COMPANIES = gql `
query Companies($filters: CompanyFiltersInput) {
  companies(filters: $filters) {
    data {
      attributes {
        name
        findDevs{data{id attributes{description}}}
      
      }
      id
    }
  }
}

`

export const GET_COMPANY_ANSWER = gql `query CompanyAnswers($filters: CompanyAnswerFiltersInput) {
  companyAnswers(filters: $filters) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;
export const GET_FIND_DEV = gql `
query FindDev($filters: FindDevFiltersInput) {
  findDevs(filters: $filters) {
    data {
      id
      attributes {
        description
      }
    }
  }
}
`;

export const GET_PROSPECT = gql `query Prospect($prospectId: ID) {
    prospect(id: $prospectId) {
      data {
        id
        attributes {
          company_answers {
            data {
              id
            }
          }
          name
          email
        }
      }
    }
  }`;

  export const GET_COUNTRY = gql `query Data {
    countries {
      data {
        id
        attributes {
          name
          states {
            data {
              id
              attributes {
                name
                cities {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
  export const GET_EMPLOYEER_DATA = gql `
  query UsersPermissionsUser($usersPermissionsUserId: ID) {
    usersPermissionsUser(id: $usersPermissionsUserId) {
      data {
        id
        attributes {
          email
          contact
          start_date
          name
          postRole {
            data {
              id
              attributes {
                postRole
                description
              }
            }
          }
          userRole {
            data {
              id
              attributes {
                role
                description
              }
            }
          }
        }
      }
    }
  } 
  `;
  export const GET_EMPLOYEER_DATA_FOR_QUESTIONS = gql `
  query UsersPermissionsUser($usersPermissionsUserId: ID) {
    usersPermissionsUser(id: $usersPermissionsUserId) {
      data {
        id
        attributes {
          userRole{data{id attributes{role}}}
        }
      }
    }
  }
  `;