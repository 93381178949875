import {
  Box,
  Button, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CREATE_MEDIA_FILE, CREATE_PORTFOLIO, DELETE_MEDIA_FILE, UPDATE_PORTFOLIO } from "../mutation.gql";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";
const { REACT_APP_API_URL } = process.env;

const PortfolioStep = ({
  handleClose,
  collaboratorID,
  collaborator,
  setCollaborator,
  collaborators,
  setCollaborators,
  ObjectList,
  setObjectList,
  step,
  stepID,
  setPortfolio,
  setIsGridVisible

}) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const addStep = step?.includes("Add");
  const editStep = step?.includes("Edit");
  const createStep = step?.includes("Create");
  const filter = createFilterOptions();
  const jwt = localStorage.getItem("jwtToken");
  const [new_portfolio] = useMutation(CREATE_PORTFOLIO);
  const [updadated_Portfolio] = useMutation(UPDATE_PORTFOLIO);
  const [deleteImage] = useMutation(DELETE_MEDIA_FILE);
  const [uploadImage] = useMutation(CREATE_MEDIA_FILE);
  const [portfolioImg, setPortfolioImg] = useState([])
  const [portfolioImgUp, setPortfolioImgUp] = useState("")
  const [portfolioError, setPortfolioError] = useState(null);
  const [description, setDescription] = useState('');
  const [portfolioName, setPortfolioName] = useState('');
  const [fileImg, setFileImg] = useState(null);
  const fileBlob = fileImg?.slice()
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const FILE_TYPE = "image/jpeg";

  const handleButtonClick = () => {
    //  Open the file upload dialog
    fileInputRef.current.click();
  };

  const handleUploadChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFileImg(selectedFile);
      setError(i18n.t('collaboratorStep.error.uploadReady', { lng: selectedLanguage }));
    } else {
      setFileImg(null);
      setError(i18n.t('collaboratorStep.error.uploadFile', { lng: selectedLanguage }));
    }
  };

  useEffect(() => {
    if (step?.includes("Edit")) {
      const foundProfile = ObjectList.find((object) => object.id === stepID);

      setPortfolioImg({ imageID: foundProfile.imageID, image: foundProfile.image })
      setDescription(foundProfile.description)
      setPortfolioName(foundProfile.name)
    }
  }, []);

  const handleInputChange = (event, setVariable) => {
    setVariable(event.target.value);
    setPortfolioError(null)
  };




  const handleAdd = () => {

    if (portfolioName === "") {
      setPortfolioError(i18n.t('collaboratorStep.error.portfolioSelect', { lng: selectedLanguage }));
    } else if (fileImg === null) {
      setError(i18n.t('collaboratorStep.error.uploadFile', { lng: selectedLanguage }));
    } else {
      new_portfolio({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        //fetchPolicy: "network-only",
        variables: {
          data: {
            name: portfolioName,
            description: description,
            collaborator: collaboratorID,
          },

        },
      }).then(async (data) => {
        const newPort = data.data.createPortfolio.data;
        const fileName = `Portfolio of ${collaborator.name}${portfolioName}`; // The filename and extension
        const fileType = FILE_TYPE; // Set the file type
        const file = new File([fileBlob], fileName, { type: fileType });

        try {
          const response = await uploadImage({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {

              file: file,
              info: { name: fileName },
              refId: newPort.id,
              field: 'image',
              ref: 'api::portfolio.portfolio'
            },
          })

          const uploadedFile = response.data.upload.data
          console.log("uploadedFile", uploadedFile)
          setPortfolioImgUp(uploadedFile);

          const updatedData = {
            id: newPort.id,
            name: newPort.attributes.name,
            description: newPort.attributes.description,
            image: uploadedFile.attributes.url,
            imageID: uploadedFile.id
          };

          const updatedMainData = collaborators.map(obj => {
            if (obj.id === collaborator.id) {
              return { ...obj, portfolios: [...obj.portfolios, updatedData] };
            }
            return obj;
          });

          const newList = [...ObjectList, updatedData];
          setObjectList(newList);
          setCollaborators(updatedMainData);
          handleClose();

          toast.success("Portfolios Done!!");
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      });
    }


  };
  const handleEdit = () => {
    if (portfolioName === "") {
      setPortfolioError(i18n.t('collaboratorStep.error.portfolioSelect', { lng: selectedLanguage }));
    }
    else {
      updadated_Portfolio({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        //fetchPolicy: "network-only",
        variables: {
          id: stepID,
          data: {
            name: portfolioName,
            description: description,
            collaborator: collaboratorID,
          },
        },
      }).then(async (data) => {
        if (fileImg === null) {
          const foundExpertise =
          {
            id: stepID,
            name: portfolioName,
            description: description,
            image: portfolioImg.image,
            imageID: portfolioImg.imageID
          }
          const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

          if (foundExpertiseIndex !== -1) {
            ObjectList[foundExpertiseIndex] = {
              ...foundExpertise,
            };
          }
        }
        else {
          const fileName = `Portfolio of ${collaborator.name}${portfolioName}`;
          const fileType = FILE_TYPE;
          const file = new File([fileBlob], fileName, { type: fileType });

          /* const formData = new FormData();
          formData.append("files", file);
          formData.append('ref', "api::portfolio.portfolio");
          formData.append('refId', stepID);
          formData.append('field', 'image'); */
          const foundExpertise = ObjectList.find((expertise) => expertise.id === stepID);
          deleteImage({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            //fetchPolicy: "network-only",
            variables: {
              id: foundExpertise.imageID,

            },
          })
          try {
            const response = await uploadImage({
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
              variables: {

                file: file,
                info: { name: fileName },
                refId: stepID,
                field: 'image',
                ref: 'api::portfolio.portfolio'
              },
            })

            const uploadedFile = response.data.upload.data
            console.log("uploadedFile", uploadedFile)
            setPortfolioImgUp(uploadedFile);
            const foundExpertise = {
              id: stepID,
              name: portfolioName,
              description: description,
              image: uploadedFile.attributes.url,
              imageID: uploadedFile.id
            };

            const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

            if (foundExpertiseIndex !== -1) {
              ObjectList[foundExpertiseIndex] = {
                ...foundExpertise,
              };
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        }

        handleClose()
        toast.success("Portfolio Updated!!")
      })


    }
  };
  const handleCreate = () => {

    if (portfolioName === "") {
      setPortfolioError(i18n.t('collaboratorStep.error.portfolioSelect', { lng: selectedLanguage }));
    } else if (fileImg === null) {
      setError(i18n.t('collaboratorStep.error.uploadFile', { lng: selectedLanguage }));
    } else {
      setPortfolio(prevState => [...prevState, {
        name: portfolioName,
        description: description,
        image: fileImg?.slice()
      }]);
      setIsGridVisible(false);
    }


  };
  return (


    <>

      <Grid container item xs={12} spacing={3} id="expGrid">
        <Grid item xs={12} md={6}>
          <InputLabel >{i18n.t('collaboratorStep.collaborator.name', { lng: selectedLanguage })}</InputLabel>
          <TextField size="small"
            required
            autoFocus
            type="text"
            value={portfolioName}
            onChange={(event) => handleInputChange(event, setPortfolioName)}
            fullWidth
            name="awardName"
            id="awardName"
            placeholder="Ex: Afrikan Coders Landing"
          />
          {portfolioError && <Typography
            variant="body2"
            color="gray"
            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
            {portfolioError}
          </Typography>}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel>
            {addStep|| createStep ? i18n.t('collaboratorStep.collaborator.uploadPortfolio', { lng: selectedLanguage }) : editStep ? i18n.t('collaboratorStep.collaborator.newUpload', { lng: selectedLanguage }) : ''}
          </InputLabel>
          <Box >
            <input type="file" id="file" onChange={handleUploadChange} ref={fileInputRef} style={{ display: 'none' }} />
            <Button fullWidth startIcon={<DriveFolderUploadIcon />} variant="contained" color="primary" onClick={handleButtonClick}>
            {i18n.t('collaboratorStep.button.upload', { lng: selectedLanguage })}
            </Button>
            {error && <Typography variant="body2" color="error">{fileImg?.name}{error}</Typography>}
          </Box>


        </Grid>


        <Grid item xs={12} md={12}>
          <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
          <TextField size="small"
            required
            autoFocus
            multiline
            type="text"
            value={description}
            onChange={(event) => handleInputChange(event, setDescription)}
            fullWidth
            name="description"
            id="description"
            placeholder={i18n.t('collaboratorStep.collaborator.descriptionPortfolio', { lng: selectedLanguage })}
          />
        </Grid>


      </Grid>
      <Grid container spacing={2} marginTop={1}>
      <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                                {i18n.t('collaboratorStep.button.portfolio', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
          <Grid item>

            {editStep && <Button
              variant="contained"
              size="small"
              onClick={handleEdit}
            >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
            {addStep && <Button
              variant="contained"
              size="small"
              onClick={handleAdd}
            >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
          </Grid>
        </Grid>
      </Grid>

    </>
  );

};

export default PortfolioStep;