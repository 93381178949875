import React from 'react';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';

const CustomPagination = ({ totalPages, currentPage, handlePageChange }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Pagination
        shape="rounded"
        size="small"
        color="primary"
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{
          paddingTop: "1.1rem",
          paddingBottom: "0.5rem",
          position: "relative",
          float: "right"
        }}
      />
    </Box>
  );
};

export default CustomPagination;
