import React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingCircle = ({message}) => {
  return (
    <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <StyledBox>
        <CircularProgress />
        <Typography sx={{ mt: 2 }} id="modal-modal-title" variant="h6" component="h2">
          {message}
        </Typography>
      </StyledBox>
    </Modal>
  );
};

export default LoadingCircle;
