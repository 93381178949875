import css from "../css/contact.module.css";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Twitter from "@mui/icons-material/Twitter";
import { Section } from "../section";
import { IconButton } from "@mui/material";
import PlusOneIcon from '@mui/icons-material/PlusOne';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
const iconAdd = <IconButton color="primary"><PlusOneIcon /></IconButton>
const iconDel = <IconButton color="primary"><ClearOutlinedIcon /></IconButton>
const iconEdit = <IconButton color="primary"><EditOutlinedIcon /></IconButton>

const Header = ({ collaborator, handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <IconButton color="primary" onClick={() => {
        handleOpen("Edit Collaborator");
        setStepID(collaborator.id);
      }}>
        <EditOutlinedIcon />
      </IconButton>}Contact</h2>
      <p>Call me, maybe.</p>
    </>
  );
};

const Content = ({ collaborator, profiles }) => {
  const theHero = collaborator
  const heroName = collaborator.name;
  let firstName = heroName?.split(" ")[0]
  let lastName = heroName?.split(" ")[1]
  const profile = profiles
  const selectedWords = ['Facebook', 'Instagram', 'Twitter'];

  var contactInfo = profile.filter(function (obj) {
    return selectedWords.includes(obj.platform);
  });


  return (
    <div className={css.content}>
      <ul className={css.contacts}>
        <li>
          <h4>Location</h4>
          <span> {collaborator.address} <br /> {collaborator.city} , {collaborator.country}</span>
        </li>
        <li>
          <h4>Phone</h4>
          <span>{collaborator.phone || "N/A"}</span>
        </li>
        <li>
          <a href={collaborator.web}>
            <h4>Web</h4>
            <span>{collaborator.web || "N/A"}</span>
          </a>
        </li>
        <li>
          <a href={`mailto:${collaborator.email}`}>
            <h4>Email</h4>
            <span>{collaborator.email}</span>
          </a>
        </li>
      </ul>
      <div className={css.socials}>
        <span className={css.subtitle}>
          <div className={css.name}>
            <span className={css.firstName}>{firstName}</span>
            <span className={css.lastName}>{lastName}</span>
          </div>
        </span>
        <ul className={css.links}>
          {selectedWords.map((word, index) => {
            const matchingProfile = contactInfo.find((info) => info.platform === word);

            if (matchingProfile) {
              return (
                <li key={index}>
                  <a href={matchingProfile.urlProfile}>
                    {word === 'Facebook' && <Facebook fontSize="large" />}
                    {word === 'Instagram' && <Instagram fontSize="large" />}
                    {word === 'Twitter' && <Twitter fontSize="large" />}
                  </a>
                </li>
              );
            }

            return (
              <li key={index}>
                <a href={`https://www.${word.toLowerCase()}.com`}>
                  {word === 'Facebook' && <Facebook fontSize="large" />}
                  {word === 'Instagram' && <Instagram fontSize="large" />}
                  {word === 'Twitter' && <Twitter fontSize="large" />}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const Contact = ({ handleOpen, setStepID, collaborator, profiles, colaboradorEdit }) => {
  return <Section header={<Header collaborator={collaborator} handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content profiles={profiles} collaborator={collaborator} />} />;
};
