import { useMutation } from "@apollo/client";
import { DELETE_POST_ROLE } from "./mutation.gql";
import { Button, Grid, Typography } from "@mui/material";
import i18n from "../../translate/i18n";
import { useContext } from "react";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { useSnackbar } from "../../contexts/snackbar-context";

const PostRoleDelete = ({ postRole,handleClose,setList,list }) => {
    const { setOpenAlertOK, setSuccessMessage, setSeverity} = useSnackbar()
    const { selectedLanguage } = useContext(LanguageContext);
    const [deletePostRole] = useMutation(DELETE_POST_ROLE);
    const jwt = localStorage.getItem('jwtToken')

    const handleDelete = (event) => {
        event.preventDefault()
        deletePostRole({
            variables: { id: postRole.id },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
            .then((response) => {
                const updatedData = list.filter(obj => obj.id !== postRole.id );
                setList(updatedData);
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('success.deleteRole', { lng: selectedLanguage }));
                setSeverity("success")
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <form onSubmit={handleDelete} >
            <Grid container width={400}>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography>{i18n.t('title.delete', { lng: selectedLanguage })}</Typography>
                </Grid>
                <Grid container spacing={2} paddingTop={3} display="flex" justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Button onClick={handleClose} variant="outlined" size="small">{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" size="small" onClick={handleDelete}>{i18n.t('button.confirm', { lng: selectedLanguage })}</Button>
                    </Grid>
                </Grid>
            </Grid>


        </form>
    )
};
export default PostRoleDelete;