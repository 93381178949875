import { gql } from "@apollo/client";

export const GET_DIMENSIONS = gql`
query Dimensions {
    dimensions {
      data {
        id
        attributes {
          dimension
        }
      }
    }
  }
`;

export const GET_INDICATORS = gql`
  query Indicators {
    indicators(pagination: {pageSize: 1000 },sort:"indicator:asc") {
      data {
        id
        attributes {
          indicator 
          isActive
          dimension {
            data {
              id
              attributes {
                dimension
              }
            }
          }
        }
      }
    }
  }
`;
